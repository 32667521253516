import styled from "styled-components";
import { Input } from "./input/Input";
import { useTranslation } from "react-i18next";
import { AddressAutofill } from "@mapbox/search-js-react";
import { UseFormRegister, FormState, Path } from "react-hook-form";
import { Spacer } from "./Spacer";

export interface GenericAddressProps {
  addressLine1: string;
  addressLine2: string | null;
  city: string;
  postalCode: string;
  country: string;
}

export interface AddressProps<T extends GenericAddressProps> {
  label: string;
  register: UseFormRegister<T>;
  formState: FormState<T>;
}

export const Address = <T extends GenericAddressProps>(
  props: AddressProps<T>,
) => {
  const { t } = useTranslation();
  const accessToken = import.meta.env.VITE_MAPBOX_TOKEN as string | undefined;

  return (
    <StyledAddress>
      <AddressAutofill accessToken={accessToken || ""}>
        <Input
          label={props.label}
          placeholder={t("address.placeholder.addressLine1") || ""}
          {...props.register("addressLine1" as Path<T>, {
            required: `${t("address.error.input")}`,
          })}
          error={props.formState.errors.addressLine1?.message as string}
          autoComplete="address-line1"
          noneBottomSpacer
        />
      </AddressAutofill>
      <Input
        placeholder={t("address.placeholder.addressLine2") || ""}
        {...props.register("addressLine2" as Path<T>)}
        autoComplete="address-line2"
        noneBottomSpacer
      />
      <StyledCityAndPostalCode>
        <StyledInput
          placeholder={t("address.placeholder.postalCode") || ""}
          {...props.register("postalCode" as Path<T>, {
            required: `${t("address.error.input")}`,
          })}
          error={props.formState.errors.postalCode?.message as string}
          autoComplete="postal-code"
          noneBottomSpacer
        />
        <Spacer x={0.5} />
        <StyledInput
          placeholder={t("address.placeholder.city") || ""}
          {...props.register("city" as Path<T>, {
            required: `${t("address.error.input")}`,
          })}
          error={props.formState.errors.city?.message as string}
          autoComplete="address-level2"
          noneBottomSpacer
        />
      </StyledCityAndPostalCode>
      <StyledCountry>
        <Input
          {...props.register("country" as Path<T>)}
          value={t("address.country.france") + " 🇫🇷"}
          //autoComplete="country"
          disabled={true}
        />
        <Spacer x={0.25} />
      </StyledCountry>
    </StyledAddress>
  );
};
const StyledAddress = styled.div`
  width: 100%;
`;
const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledCityAndPostalCode = styled.div`
  display: flex;
`;
const StyledCountry = styled.div`
  display: flex;
  width: 50%;
`;
