import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  PublicRuleType,
  RuleDataCreation,
  RuleFormData,
  UpdateRulesAPI,
} from "./rulesAPI";
export interface RulesState {
  createRuleStatus: QueryStatus;
  updateRuleStatus: QueryStatus;
  rulesListStatus: QueryStatus;
  ruleDataStatus: QueryStatus;
  rulesList: PublicRuleType[];
  ruleData: RuleFormData | null;
}

const initialState: RulesState = {
  createRuleStatus: "idle",
  updateRuleStatus: "idle",
  rulesListStatus: "idle",
  ruleDataStatus: "idle",
  rulesList: [],
  ruleData: null,
};

export const rulesAsync = createAsyncThunk(
  "rules/call",
  async (payload: RuleDataCreation, thunkApi) => {
    const axios = authAxios();
    await axios.post(`rule`, payload);
    thunkApi.dispatch(rulesSlice.actions.resetRulesList());
  },
);

export const updateRuleAsync = createAsyncThunk(
  "updateRules/call",
  async (payload: UpdateRulesAPI, thunkApi) => {
    const axios = authAxios();
    await axios.post(`update_rule/${payload.uuid}`, payload);
    thunkApi.dispatch(rulesSlice.actions.resetRulesList());
  },
);

export const rulesListAsync = createAsyncThunk(
  "rulesList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<PublicRuleType>>(
      `rules/company/${companyUuid}`,
    );
    return response.data;
  },
);

export const ruleDataAsync = createAsyncThunk(
  "ruleData/call",
  async (ruleUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<RuleFormData>(`rule/${ruleUuid}`);
    return {
      ...response.data,
      uuid: ruleUuid,
      amountLimit: Number(response.data.amountLimit) / 100,
      categories: response.data.categories.map((category) => {
        return {
          ...category,
          amountLimit: Number(category.amountLimit) / 100,
        };
      }),
    };
  },
);

export const rulesSlice = createSlice({
  name: "rules",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetRulesList(state) {
      state.rulesListStatus = "idle";
    },
    resetRuleCreationStatus(state) {
      state.createRuleStatus = "idle";
      state.ruleData = null;
    },
    resetRuleUpdateStatus(state) {
      state.updateRuleStatus = "idle";
      state.ruleData = null;
    },
    resetRuleData(state) {
      state.ruleData = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(rulesAsync.pending, (state) => {
        state.createRuleStatus = "processing";
      })
      .addCase(rulesAsync.fulfilled, (state) => {
        state.createRuleStatus = "success";
      })
      .addCase(rulesAsync.rejected, (state) => {
        state.createRuleStatus = "failed";
      })
      .addCase(updateRuleAsync.pending, (state) => {
        state.updateRuleStatus = "processing";
      })
      .addCase(updateRuleAsync.fulfilled, (state) => {
        state.updateRuleStatus = "success";
      })
      .addCase(updateRuleAsync.rejected, (state) => {
        state.updateRuleStatus = "failed";
      })
      .addCase(rulesListAsync.pending, (state) => {
        state.rulesListStatus = "processing";
      })
      .addCase(rulesListAsync.fulfilled, (state, action) => {
        state.rulesList = action.payload;
        state.rulesListStatus = "success";
        state.ruleDataStatus = "idle";
      })
      .addCase(rulesListAsync.rejected, (state) => {
        state.rulesListStatus = "failed";
      })
      .addCase(ruleDataAsync.pending, (state) => {
        state.ruleDataStatus = "processing";
      })
      .addCase(ruleDataAsync.fulfilled, (state, action) => {
        state.ruleData = action.payload;
        state.ruleDataStatus = "success";
      })
      .addCase(ruleDataAsync.rejected, (state) => {
        state.ruleDataStatus = "failed";
      });
  },
});

export const selectRuleStatus = (state: RootState) =>
  state.rules.createRuleStatus;
export const selectUpdatedRuleStatus = (state: RootState) =>
  state.rules.updateRuleStatus;

export const selectRulesList = (state: RootState) => state.rules.rulesList;
export const selectRulesListStatus = (state: RootState) =>
  state.rules.rulesListStatus;
export const selectRuleData = (state: RootState) => state.rules.ruleData;
export const selectRuleDataStatus = (state: RootState) =>
  state.rules.ruleDataStatus;

export default rulesSlice.reducer;
