import {
  Spacer,
  ToastContainer,
  triggerToast,
  SortingDirectionType,
  sortByAlphabeticalOrder,
  SortByAlphabeticalOrderType,
  LaunchPage,
  sortByDateTime,
  PageDS,
  TableDS,
  TableTagCard,
  AddOutlined,
  AddCardOutlined,
  EmptyTableComponent,
  Badge,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectCompany } from "./../homeSlice";
import {
  cardsListAsync,
  selectCardsList,
  selectCardCreationStatus,
  selectCardsListStatus,
  cardsSlice,
  selectCardUpdatedStatus,
  selectOrderMultipleCardsStatus,
  selectNewCardsList,
  selectRuleUpdatedStatus,
} from "./cardsSlice";
import { unreachable } from "../../../utils";
import {
  CardsListDisplayedRow,
  CardsListDisplayedType,
  CardsListType,
} from "./cardsAPI";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { LittleCardAsset } from "@qivia/ui/src/assets/assets";
import { useNavigate, useParams } from "react-router-dom";
import { CardSidePanel } from "./SidePanel";
import { AddMultipeCardsModal } from "./addMulitpleCardsModal";
import { AddCardModal } from "./addCardModal";
import { listAllVehiclesInDbAsync } from "../vehicles/vehiclesSlice";
import { rulesListAsync } from "../rules/rulesSlice";

export type AddressInput = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  country: string;
};

export const Cards = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();

  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");
  const [listDisplayed, setListDisplayed] = useState<
    CardsListDisplayedType[] | null
  >(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMultipleCardsModalVisible, setIsMultipleCardsModalVisible] =
    useState(false);

  const newCardsList = useAppSelector(selectNewCardsList);
  const cardsListStatus = useAppSelector(selectCardsListStatus);
  const createCardStatus = useAppSelector(selectCardCreationStatus);
  const company = useAppSelector(selectCompany);
  const cardsListInit = useAppSelector(selectCardsList);
  const cardUpdatedStatus = useAppSelector(selectCardUpdatedStatus);
  const ruleUpdatedStatus = useAppSelector(selectRuleUpdatedStatus);
  const orderMultipleCardStatus = useAppSelector(
    selectOrderMultipleCardsStatus,
  );
  const addCardIsDisabled = useMemo(
    () =>
      company === null ||
      company.iban === "" ||
      company.iban === null ||
      company.bic === "" ||
      company.bic === null,
    [company],
  );
  const cardsList: CardsListType[] = useMemo(() => {
    return sortByDateTime(cardsListInit, "desc");
  }, [cardsListInit]);

  const findKeysToTranslate = (key: keyof CardsListType, value: string) => {
    switch (key) {
      case "status":
        return `cards.status.${value}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    if (company) {
      void dispatch(cardsListAsync(company.uuid));
      void dispatch(listAllVehiclesInDbAsync({ companyUuid: company.uuid }));
      void dispatch(rulesListAsync(company.uuid));
    }
  }, [
    dispatch,
    createCardStatus,
    cardUpdatedStatus,
    orderMultipleCardStatus,
    ruleUpdatedStatus,
    company,
  ]);

  useEffect(() => {
    if (cardsList && cardsListStatus === "success") {
      const cardsListUpdated = cardsList.map((card) => {
        if (newCardsList.vehicles.includes(card.vehicleUuid)) {
          return { ...card, card: "(new)" };
        } else {
          return card;
        }
      });
      const cardsDateTimeSorted = sortByDateTime(cardsListUpdated, "desc");
      setListDisplayed(cardsDateTimeSorted);
      dispatch(cardsSlice.actions.resetCardsListStatus());
    }
  }, [cardsList, cardsListStatus, dispatch, newCardsList]);

  useEffect(() => {
    if (orderMultipleCardStatus === "success") {
      triggerToast(t("cards.multipleCards.creation.success") || "", "valid");
    } else if (orderMultipleCardStatus === "failed") {
      triggerToast(t("cards.multipleCards.creation.failure") || "", "error");
    }
  }, [orderMultipleCardStatus, t]);

  const sidePanelAction = useCallback(
    (props: Partial<CardsListType>) => {
      navigate(`/home/cards/${props.uuid}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (params.id) {
      const cardSelected = cardsList.find((c) => c.uuid === params.id);
      if (cardSelected) {
        navigate(`/home/cards/${cardSelected.uuid}`);
      }
    }
  }, [cardsList, navigate, params.id]);

  const sortCardsByAlphabeticalOrder = useCallback(
    (column: string) => {
      if (!listDisplayed) return;
      const cardsAlphabeticalSorted = sortByAlphabeticalOrder(
        listDisplayed,
        sortingDirection,
        column as keyof SortByAlphabeticalOrderType,
      );
      setListDisplayed(cardsAlphabeticalSorted);
      setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
    },
    [listDisplayed, sortingDirection],
  );

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  const searchBarProps = {
    values: cardsList,
    setFilterValues: setListDisplayed,
    keysToTranslate: ["status" as const],
    findKeysToTranslate,
    keysToIgnore: [
      "uuid" as const,
      "date" as const,
      "driverUuid" as const,
      "vehicleUuid" as const,
      "ruleUuid" as const,
      "driverFirstName" as const,
      "driverLastName" as const,
    ],
  };

  const cta = {
    title: t("cards.cta"),
    action: () => {
      if (!addCardIsDisabled) setIsModalVisible(true);
    },
    width: 12,
    isDisabled: addCardIsDisabled,
    leftIcon: <AddOutlined />,
  };

  const cta2 = {
    title: t("cards.cta2"),
    action: () => {
      if (!addCardIsDisabled) setIsMultipleCardsModalVisible(true);
    },
    width: 12,
    isDisabled: addCardIsDisabled,
    leftIcon: <AddCardOutlined />,
  };

  const render =
    (row: CardsListDisplayedRow) => (key: keyof CardsListDisplayedType) => {
      switch (key) {
        case "last4Digits":
          return (
            <StyledRow>
              <LittleCardAsset />
              <Spacer x={1} />
              {row["last4Digits"]?.endsWith("(new)") ? (
                <Badge
                  text={t("cards.status.badge.new")}
                  backgroundColor={
                    colors["colors/system/informative/informative_ultraLight"]
                  }
                  textColor={
                    colors["colors/system/informative/informative_normal"]
                  }
                />
              ) : (
                row["last4Digits"] && `** ${row["last4Digits"].toString()}`
              )}
            </StyledRow>
          );
        case "status":
          return <TableTagCard status={row[key]} />;
        case "registrationNumber":
          return [row[key], row["vehicleReference"]]
            .filter((d) => d)
            .join(" / ");
        case "ruleName":
          return row[key];
      }
      unreachable(key);
    };

  const headers = {
    last4Digits: {
      text: t("cards.cards"),
    },
    status: {
      text: t("cards.status"),
    },
    registrationNumber: {
      text: t("cards.vehicles"),
      sortAction: () => sortCardsByAlphabeticalOrder("vehicle"),
    },
    ruleName: {
      text: t("cards.rules"),
      sortAction: () => sortCardsByAlphabeticalOrder("rule"),
    },
  };

  return (
    <>
      {isMultipleCardsModalVisible ? (
        <PageDS
          title={t("cards.multipleCards.title")}
          titleElement={{
            closeButtonAction: () => setIsMultipleCardsModalVisible(false),
          }}
        >
          <AddMultipeCardsModal
            onCloseModal={() => {
              setIsMultipleCardsModalVisible(false);
            }}
          />
        </PageDS>
      ) : (
        <>
          <AddCardModal
            visible={isModalVisible}
            onCloseModal={() => {
              setIsModalVisible(false);
            }}
          />
          <PageDS
            title={t("cards.title")}
            cta={cta}
            cta2={cta2}
            isEmptyTable={listDisplayed.length === 0}
            toaster={<ToastContainer />}
            searchBar={searchBarProps}
          >
            <TableDS<keyof CardsListDisplayedType, CardsListDisplayedType>
              data={listDisplayed}
              headers={headers}
              render={render}
              onClickRow={sidePanelAction}
              emptyContent={
                <EmptyTableComponent
                  pageName="cards"
                  cta={cta}
                  cta2={cta2}
                  isSearchResult={searchBarProps.values.length > 0}
                />
              }
            />
            <CardSidePanel />
          </PageDS>
        </>
      )}
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;
