import LeftArrowSolid from "../assets/leftArrowSolid.svg?react";
import { AddressForm } from "./AddressForm";
import { StyledBackgroundButton } from "./Modal";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";
import { Input } from "./input/Input";
import { ChangeEvent } from "react";

interface AddressInput {
  company: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  country: string;
  identificationNumber: string;
}

interface Props {
  labelCompany: string;
  labelIdentificationNumber: string;
  labelAddress: string;
  addressInput: AddressInput;
  onChange: (e: AddressInput) => void;
  onClickNext: () => void;
  onClickBack: () => void;
}

export const AddressSignInForm = (props: Props) => {
  const { t } = useTranslation();
  const areAddressInputsFilled =
    props.addressInput.addressLine1 &&
    props.addressInput.city &&
    props.addressInput.postalCode &&
    props.addressInput.company &&
    props.addressInput.identificationNumber;
  return (
    <>
      <Input
        label={props.labelCompany} // Add a label for the company name input
        name="companyName"
        type="text"
        value={props.addressInput.company}
        placeholder={t("signIn.address.placeholder")}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const newAddressInput = {
            ...props.addressInput,
            company: e.target.value,
          };
          props.onChange(newAddressInput);
        }}
      />
      <Input
        label={props.labelIdentificationNumber}
        name="identificationNumber"
        type="text"
        value={props.addressInput.identificationNumber}
        placeholder={t("signIn.address.identificationNumber")}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const newAddressInput = {
            ...props.addressInput,
            identificationNumber: e.target.value,
          };
          props.onChange(newAddressInput);
        }}
      />
      <AddressForm
        label={props.labelAddress}
        addressForm={{
          addressLine1: props.addressInput.addressLine1,
          addressLine2: props.addressInput.addressLine2,
          city: props.addressInput.city,
          postalCode: props.addressInput.postalCode,
          country: props.addressInput.country,
        }}
        onChange={(key, value) => {
          const newAddressInput = { ...props.addressInput, [key]: value };
          props.onChange(newAddressInput);
        }}
      />
      <StyledBackgroundButton>
        <Button
          title={t(`signIn.address.cta.continue`) || ""}
          type="button"
          disabled={!areAddressInputsFilled}
          onClick={() => {
            if (areAddressInputsFilled) props.onClickNext();
          }}
        ></Button>
      </StyledBackgroundButton>
      <StyledBackgroundButton>
        <Button
          title={t(`signIn.address.cta.back`) || ""}
          LeftElement={<LeftArrowSolid />}
          withoutBackground={true}
          onClick={props.onClickBack}
          underline
          noColorHover
        ></Button>
      </StyledBackgroundButton>
    </>
  );
};
