import { CategoryInfoType } from "../libDefaultDatas";
import {
  AllowedCategoryType,
  AmountLimit,
  AmountType,
  CategoryError,
} from "../rulesAPI";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import {
  CheckBox,
  EuroSymbolOutlined,
  NumericInputDS,
  Spacer,
  StyledFlex,
  Switch,
  TextCapitalized,
  TooltipDS,
} from "@qivia/ui";
import { StyledBodyLeft } from "./styledBodyLeft";
import { StyledBodyRight } from "./styledBodyRight";
import { StyledNumericInput } from "./styledNumericInput";
import styled from "styled-components";
import { RuleTemplate } from "./ruleTemplate";
import { StyledColumn } from "./styledColumn";
import { SelectInputAmountType } from "./selectInputAmountType";

export const SpecificAmounts = (props: {
  title: string;
  categoryInfo: CategoryInfoType;
  updateCategory: (value: AllowedCategoryType) => void;
  updateCategories: () => void;
  currentCategory: AllowedCategoryType | undefined;
  displayError: boolean;
  index: number;
  setSpecificsAmountsError: (value: CategoryError[]) => void;
  specificsAmountsError: CategoryError[];
}) => {
  const {
    setSpecificsAmountsError,
    currentCategory,
    specificsAmountsError,
    updateCategory,
    updateCategories,
  } = props;

  const { t } = useTranslation();
  const [isSwitchSelected, setIsSwitchSelected] = useState<boolean>(
    !!props.currentCategory,
  );

  const [hasSpecificAmount, setHasSpecificAmount] = useState<boolean>(
    !!(props.currentCategory && props.currentCategory.amountLimit !== ""),
  );

  const updateCategoryError = useCallback(
    (limit: AmountLimit, hasSpecificAmountProps: boolean) => {
      if (!currentCategory) return;
      const newValue = specificsAmountsError.map((element) => {
        if (element.category === currentCategory.category) {
          return {
            ...element,
            error:
              element.category === currentCategory?.category &&
              hasSpecificAmountProps &&
              limit === ("" as AmountLimit),
          };
        } else {
          return element;
        }
      });
      setSpecificsAmountsError(newValue);
    },
    [currentCategory, specificsAmountsError, setSpecificsAmountsError],
  );

  const updateAmountLimit = useCallback(
    (limit: AmountLimit) => {
      if (!currentCategory) return;
      const newValueState = {
        ...currentCategory,
        amountLimit: limit,
      };
      updateCategory(newValueState);
    },
    [currentCategory, updateCategory],
  );

  const updateAmountType = useCallback(
    (typeLimit: AmountType) => {
      if (!currentCategory) return;
      const newValueState = {
        ...currentCategory,
        typeLimit: typeLimit,
      };
      updateCategory(newValueState);
    },
    [currentCategory, updateCategory],
  );

  const updateSpecificAmountState = useCallback(() => {
    if (!currentCategory) return;
    updateCategory({
      ...currentCategory,
      amountLimit: "",
      typeLimit: "DAILY",
    });
    setHasSpecificAmount(!hasSpecificAmount);
    updateCategoryError("", !hasSpecificAmount);
  }, [currentCategory, hasSpecificAmount, updateCategory, updateCategoryError]);

  const updateCategoryByCheckbox = useCallback(() => {
    setHasSpecificAmount(true);
    setIsSwitchSelected(true);
    updateCategories();
  }, [updateCategories]);

  const updateCategoryBySwitch = useCallback(() => {
    setIsSwitchSelected(!isSwitchSelected);
    if (isSwitchSelected) {
      setHasSpecificAmount(false);
      updateCategoryError("", false);
    }
    updateCategories();
  }, [isSwitchSelected, updateCategories, updateCategoryError]);

  const displaySpecificAmountError = useMemo(() => {
    return (
      (props.displayError &&
        currentCategory &&
        currentCategory.amountLimit === "" &&
        hasSpecificAmount) ||
      false
    );
  }, [props.displayError, currentCategory, hasSpecificAmount]);

  return (
    <RuleTemplate>
      <StyledColumn>
        <StyledFlex>
          <StyledBodyLeft>
            {props.categoryInfo.logo}
            <Spacer x={0.25} />
            <TextCapitalized>{t(props.title)}</TextCapitalized>
            <Spacer x={1} />
            {props.categoryInfo.isToolTip && (
              <TooltipDS
                place={"right"}
                content={t(`rules.modal.bottom.${props.categoryInfo.category}`)}
                key={props.index}
                id={`${props.categoryInfo.category}`}
              />
            )}
          </StyledBodyLeft>
          <Spacer x={1} />
          <StyledBodyRight>
            <Switch
              checked={isSwitchSelected}
              onClick={updateCategoryBySwitch}
              size={"S"}
              key={props.index}
              color={"GREEN"}
            />
          </StyledBodyRight>
        </StyledFlex>
        <Spacer y={1} />
        <StyledSpecificCategory>
          <StyledBodyLeft>
            <CheckBox
              state={
                isSwitchSelected && hasSpecificAmount ? "CHECKED" : "UNCHECKED"
              }
              onClick={() => {
                updateSpecificAmountState();
                if (!isSwitchSelected) {
                  updateCategoryByCheckbox();
                }
              }}
              text={t("rules.modal.expensesLimits.specific.body")}
              keyProp={props.index}
            />
          </StyledBodyLeft>
          <Spacer x={1} />
          {currentCategory && hasSpecificAmount && isSwitchSelected && (
            <StyledBodyRight>
              <StyledNumericInput>
                <NumericInputDS
                  adornent={<EuroSymbolOutlined />}
                  value={currentCategory.amountLimit}
                  placeholder={t("rules.modal.amountLimit.placeholder")}
                  update={(amount) => {
                    updateAmountLimit(amount);
                    updateCategoryError(amount, true);
                  }}
                  error={
                    displaySpecificAmountError
                      ? t("rules.modal.amountLimit.error")
                      : undefined
                  }
                  key={props.index}
                />
              </StyledNumericInput>
              <Spacer x={1} />
              <SelectInputAmountType
                typeLimit={currentCategory.typeLimit}
                onChange={updateAmountType}
              />
            </StyledBodyRight>
          )}
        </StyledSpecificCategory>
      </StyledColumn>
    </RuleTemplate>
  );
};

const StyledSpecificCategory = styled.div`
  display: flex;
  height: 2.5rem;
`;
