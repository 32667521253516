import {
  ButtonDS,
  Spacer,
  ArrowForwardOutlined,
  Badge,
  TextCapitalized,
} from "@qivia/ui";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { useTranslation } from "react-i18next";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  driversAccessRequestsListAsync,
  selectCurrentDriverAccessRequest,
  selectDriverAccessRequestsList,
} from "./driversSlice";
import { useEffect } from "react";
import { selectCompany } from "../homeSlice";

export const DriverAccessRequestSummary = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const driverAccessRequestsList = useAppSelector(
    selectDriverAccessRequestsList,
  );

  const currentDriverAccessRequest = useAppSelector(
    selectCurrentDriverAccessRequest,
  );
  const company = useAppSelector(selectCompany);

  const numberOfDrivers = driverAccessRequestsList.length;

  useEffect(() => {
    if (company) {
      void dispatch(driversAccessRequestsListAsync(company.uuid));
    }
  }, [dispatch, company]);

  return (
    <>
      {currentDriverAccessRequest && (
        <>
          <Spacer y={2} />
          <StyledContainer>
            <Spacer y={1.5} />
            <StyledSummary>
              <Spacer x={1.5} />
              <StyledContent>
                <StyledColumn>
                  <Badge
                    text={t("drivers.accessRequest.badge")}
                    backgroundColor={
                      colors["colors/system/warning/warning_veryLight"]
                    }
                    textColor={colors["colors/system/warning/warning_dark"]}
                  />
                  <Spacer y={1} />
                  <StyledTitle>
                    {numberOfDrivers === 1 && (
                      <TextCapitalized>
                        {t("drivers.accessRequest.title.singular", {
                          numberOfDrivers,
                        })}
                      </TextCapitalized>
                    )}
                    {numberOfDrivers > 1 && (
                      <TextCapitalized>
                        {t("drivers.accessRequest.title.plural", {
                          numberOfDrivers,
                        })}
                      </TextCapitalized>
                    )}
                  </StyledTitle>
                  <Spacer y={1} />
                  <StyledSubtitle>
                    <TextCapitalized>
                      {t("drivers.accessRequest.subtitle")}
                    </TextCapitalized>
                  </StyledSubtitle>
                </StyledColumn>
                <Spacer x={1} />
                <StyledButtonColumn>
                  <ButtonDS
                    text={t("drivers.accessRequest.cta")}
                    format={"fill"}
                    buttonType={"primary"}
                    rightIcon={<ArrowForwardOutlined />}
                    onClick={() =>
                      navigate(
                        `/home/drivers/access_request/${currentDriverAccessRequest.uuid}`,
                      )
                    }
                  />
                </StyledButtonColumn>
              </StyledContent>
              <Spacer x={1.5} />
            </StyledSummary>
            <Spacer y={1.5} />
          </StyledContainer>
        </>
      )}
    </>
  );
};

const StyledContainer = styled.div<{ $height?: number }>`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  min-height: 10rem;
  overflow-x: auto;
`;

const StyledSummary = styled.div`
  display: flex;
  height: 100%;
`;

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 30.375rem;
  min-width: 10rem;
  overflow: auto;
`;

const StyledButtonColumn = styled.div`
  display: flex;
  align-items: flex-end;
  min-width: 11rem;
`;

const StyledTitle = styled.div`
  ${typographies["Header/H2"]};
`;

const StyledSubtitle = styled.div`
  ${typographies["Body/S"]};
`;
