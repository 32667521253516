import { AmountType } from "../rulesAPI";
import { useTranslation } from "react-i18next";
import { SelectDS } from "@qivia/ui";

export const SelectInputAmountType = (props: {
  typeLimit: AmountType;
  onChange: (typeLimit: AmountType) => void;
}) => {
  const { t } = useTranslation();
  const options = [
    {
      value: "DAILY" as AmountType,
      label: t("rules.modal.amountLimit.daily"),
    },
    {
      value: "WEEKLY" as AmountType,
      label: t("rules.modal.amountLimit.weekly"),
    },
    {
      value: "MONTHLY" as AmountType,
      label: t("rules.modal.amountLimit.monthly"),
    },
  ];

  return (
    <SelectDS
      label=""
      value={{
        value: props.typeLimit,
        label: t(`rules.modal.amountLimit.${props.typeLimit.toLowerCase()}`),
      }}
      options={options}
      onChange={(selectedTypeLimit) =>
        props.onChange(selectedTypeLimit ? selectedTypeLimit.value : "DAILY")
      }
      withoutSpacerBottom
    />
  );
};
