import styled from "styled-components";
import {
  Section,
  Spacer,
  triggerToast,
  Modal,
  Avatar,
  ButtonDS,
  InputDS,
} from "@qivia/ui";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectManager } from "./../homeSlice";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  UsersSlice,
  selectUsersPasswordStatus,
  selectUsersStatus,
  updateUsersAsync,
  updateUsersPasswordAsync,
} from "./usersSlice";
import { UsersDatasAPI, UsersPasswordsAPI } from "./usersAPI";
import { useParams } from "react-router-dom";

type FormPassword = {
  password: string;
  newPassword: string;
  confirmedPassword: string;
};

const UpdatePasswordModal = (props: {
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: FormPassword) => void;
  isNewPasswordMatching: boolean;
  isCurrentPasswordCorrect: boolean;
  passwordStatus: string;
}) => {
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { handleSubmit, reset, formState, register } = useForm<FormPassword>();

  useEffect(() => {
    formState.isValid ? setButtonDisabled(false) : setButtonDisabled(true);
  }, [formState]);

  useEffect(() => {
    if (props.visible === false) reset();
  }, [reset, props.visible]);

  return (
    <Modal
      title={t("users.security.modal.title") || ""}
      visible={props.visible}
      onClose={() => {
        props.onClose();
      }}
    >
      <StyledForm
        onSubmit={(event) => void handleSubmit(props.onSubmit)(event)}
      >
        <InputDS
          label={t("users.security.modal.password.label") || ""}
          placeholder={t("users.security.modal.password.placeholder") || ""}
          {...register("password", {
            required: `${t("users.security.modal.error.input")}`,
          })}
          error={
            props.isCurrentPasswordCorrect
              ? formState.errors.password?.message
              : `${t("users.security.modal.password.error")}`
          }
          type="PASSWORD"
        />

        <InputDS
          label={t("users.security.modal.newPassword.label") || ""}
          placeholder={t("users.security.modal.newPassword.placeholder") || ""}
          {...register("newPassword", {
            required: `${t("users.security.modal.error.input")}`,
          })}
          error={
            props.isNewPasswordMatching
              ? formState.errors.newPassword?.message
              : `${t("users.security.modal.newPassword.error")}`
          }
          type="PASSWORD"
        />

        <InputDS
          label={t("users.security.modal.confirmedPassword.label") || ""}
          placeholder={
            t("users.security.modal.confirmedPassword.placeholder") || ""
          }
          {...register("confirmedPassword", {
            required: `${t("users.security.modal.error.input")}`,
          })}
          error={
            props.isNewPasswordMatching
              ? formState.errors.confirmedPassword?.message
              : `${t("users.security.modal.newPassword.error")}`
          }
          type="PASSWORD"
        />
        <StyledButton>
          <ButtonDS
            text={t("users.security.modal.add") || ""}
            disabled={buttonDisabled}
            buttonType={"primary"}
            format={"fill"}
          />
        </StyledButton>
        <Spacer y={1.5} />
      </StyledForm>
    </Modal>
  );
};

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
};

export const AccountTab = () => {
  const { t } = useTranslation();
  const manager = useAppSelector(selectManager);
  const params = useParams();
  const updateUserStatus = useAppSelector(selectUsersStatus);
  const passwordStatus = useAppSelector(selectUsersPasswordStatus);
  const dispatch = useAppDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCurrentPasswordCorrect, setIsCurrentPasswordCorrect] =
    useState(true);
  const [isNewPasswordMatching, setIsNewPasswordMatching] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { handleSubmit, formState, register } = useForm<FormData>();

  useEffect(() => {
    formState.isValid ? setButtonDisabled(false) : setButtonDisabled(true);
  }, [formState]);

  const onSubmitPersonalData: SubmitHandler<FormData> = useCallback(
    (data) => {
      if (!buttonDisabled) {
        const personalInfoData: UsersDatasAPI = {};
        if (data.firstName) personalInfoData.firstName = data.firstName;
        if (data.lastName) personalInfoData.lastName = data.lastName;
        if (data.email) personalInfoData.email = data.email;
        void dispatch(updateUsersAsync(personalInfoData));
      }
    },
    [buttonDisabled, dispatch],
  );

  useEffect(() => {
    if (updateUserStatus === "success") {
      triggerToast(t("users.personalInfo.success") || "", "valid");
    } else if (updateUserStatus === "failed")
      triggerToast(t("users.personalInfo.failed") || "", "error");
    dispatch(UsersSlice.actions.resetUpdateUserStatus());
  }, [dispatch, updateUserStatus, t]);

  useEffect(() => {
    if (passwordStatus === "success") {
      triggerToast(
        t("users.security.modal.newPassword.success") || "",
        "valid",
      );
      setIsCurrentPasswordCorrect(true);
      setIsModalVisible(false);
    } else if (passwordStatus === "failed") setIsCurrentPasswordCorrect(false);
    dispatch(UsersSlice.actions.resetUpdatePasswordStatus());
  }, [dispatch, passwordStatus, t]);

  const onSubmitPassword: SubmitHandler<FormPassword> = useCallback(
    (data) => {
      if (data.newPassword === data.confirmedPassword) {
        setIsNewPasswordMatching(true);
        const payload: UsersPasswordsAPI = {
          oldPassword: data.password,
          newPassword: data.newPassword,
        };
        void dispatch(updateUsersPasswordAsync(payload));
      } else return setIsNewPasswordMatching(false);
    },
    [dispatch],
  );

  if (params.tab !== "account") {
    return;
  }

  return (
    <>
      <Section title={t("users.personalInfo.title") || ""}>
        <Avatar firstName={manager?.firstName} size="L" color="grey" />
        <Spacer y={1.5} />
        <StyledForm
          onSubmit={(event) => void handleSubmit(onSubmitPersonalData)(event)}
        >
          <InputDS
            defaultValue={manager?.firstName}
            {...register("firstName")}
          />
          <InputDS defaultValue={manager?.lastName} {...register("lastName")} />
          <InputDS defaultValue={manager?.email} {...register("email")} />
          <div>
            <ButtonDS
              text={t("users.personalInfo.cta") || ""}
              format={"hug"}
              buttonType={"primary"}
              disabled={buttonDisabled}
            />
          </div>
        </StyledForm>
      </Section>
      <Spacer y={3.5} />
      <Section title={t("users.security.title") || ""}>
        <div>
          <ButtonDS
            text={t("users.security.cta") || ""}
            onClick={() => setIsModalVisible(true)}
            format={"hug"}
            buttonType={"primary"}
          />
        </div>
        <UpdatePasswordModal
          visible={isModalVisible}
          onClose={() => {
            setIsModalVisible(false);
          }}
          onSubmit={onSubmitPassword}
          isNewPasswordMatching={isNewPasswordMatching}
          isCurrentPasswordCorrect={isCurrentPasswordCorrect}
          passwordStatus={passwordStatus}
        />
      </Section>
    </>
  );
};

const StyledForm = styled.form`
  width: 100%;
`;

const StyledButton = styled.div`
  display: flex;
  border: solid 0.25rem transparent;
  justify-content: flex-end;
`;
