import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Spacer } from "../Spacer";
import { useState } from "react";
import { SidePanel } from "../../../components/SidePanel";
import { ButtonDS } from "../Button";
import { colors } from "../../../styles/figmaColors";
import { typographies } from "../../../styles/figmaTypographies";
import { TextCapitalized } from "../text/TextCapitalized";
import { SearchBarDS, SearchBarDSProps } from "../SearchBar";
import { SupportDS } from "../Support";
import { StepHeader, StepHeaderProps } from "../StepHeader";
import { ClearOutlined } from "../../materialUi/materialUi";

interface PageProps<T extends object> {
  title: string | JSX.Element;
  titleElement?: {
    closeButtonAction?: () => void;
    button?: JSX.Element;
  };
  cta?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  cta2?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  isEmptyTable?: boolean;
  children?: string | JSX.Element | JSX.Element[];
  toaster?: JSX.Element;
  searchBar?: SearchBarDSProps<T>;
  sidePanel?: {
    isSidePanelDisplayed: boolean;
    onCloseSidePanel: () => void;
    sidePanelChildren: string | JSX.Element | JSX.Element[];
    sidePanelStickyBottom: string | JSX.Element | JSX.Element[];
  };
  stepHeader?: StepHeaderProps;
  topElement?: string | JSX.Element;
  withoutSupport?: boolean;
}

export const PageDS = <T extends object>(props: PageProps<T>) => {
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false);
  const setSearchValues = (values: T[]) => {
    props.searchBar?.setFilterValues(values);
    setIsSearchResult(values.length === 0);
  };

  return (
    <StyledFrame>
      <Spacer x={2.5} />
      <StyledColumn>
        {props.toaster}
        <Spacer y={2.5} />
        <StyledTitle>
          <StyledAllWidth>
            <TextCapitalized>{props.title}</TextCapitalized>
          </StyledAllWidth>
          <Spacer x={1.5} />
          {props.titleElement?.button && (
            <>
              <StyledHeaderButton>
                {props.titleElement?.button}
              </StyledHeaderButton>
              <Spacer x={1} />
            </>
          )}
          {props.titleElement?.closeButtonAction && (
            <ButtonDS
              format="hug"
              buttonType="secondary"
              singleIcon={{ icon: <ClearOutlined />, size: "L" }}
              onClick={props.titleElement?.closeButtonAction}
            />
          )}
        </StyledTitle>
        {props.titleElement?.closeButtonAction && (
          <>
            <Spacer y={2.5} />
            <StyledLine />
            <Spacer y={2.5} />
          </>
        )}
        {props.stepHeader && <StepHeader {...props.stepHeader} />}
        {props.topElement}
        <Header
          children={props.children}
          cta={props.cta}
          cta2={props.cta2}
          searchBar={props.searchBar}
          setSearchValues={setSearchValues}
          isSearchResult={isSearchResult}
          isEmptyTable={props.isEmptyTable}
        />
        <Spacer y={0.5} />
        <StyledContent>
          {props.children}
          {props.sidePanel && (
            <SidePanel
              visible={props.sidePanel.isSidePanelDisplayed}
              onClose={props.sidePanel.onCloseSidePanel}
              stickyBottom={props.sidePanel.sidePanelStickyBottom}
            >
              {props.sidePanel.sidePanelChildren}
            </SidePanel>
          )}
        </StyledContent>
      </StyledColumn>
      <Spacer x={2.5} />
      {!props.withoutSupport && (
        <StyledSupport>
          <SupportDS />
        </StyledSupport>
      )}
    </StyledFrame>
  );
};

const Header = <T extends object>(props: {
  children?: string | JSX.Element | JSX.Element[];
  cta?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  cta2?: {
    title: string;
    action: () => void;
    width?: number;
    isDisabled?: boolean;
    leftIcon?: JSX.Element | null;
  };
  searchBar?: SearchBarDSProps<T>;
  setSearchValues: (value: T[]) => void;
  isSearchResult: boolean;
  isEmptyTable?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <StyledHeader>
      {props.searchBar && (
        <div>
          <Spacer y={1.5} />
          <SearchBarDS
            setFilterValues={props.setSearchValues}
            placeholder={t("home.searchbar")}
            values={props.searchBar.values}
            keysToTranslate={props.searchBar.keysToTranslate}
            findKeysToTranslate={props.searchBar.findKeysToTranslate}
            keysToIgnore={props.searchBar.keysToIgnore}
          />
          <Spacer y={1} />
        </div>
      )}
      <Spacer x={1} />
      {!props.isSearchResult && !props.isEmptyTable && (
        <StyledFlex>
          {props.cta2 && (
            <>
              <ButtonDS
                onClick={!props.cta2.isDisabled ? props.cta2.action : undefined}
                text={props.cta2.title}
                format={"hug"}
                buttonType={"secondary"}
                leftIcon={props.cta2.leftIcon}
                disabled={props.cta2.isDisabled}
              />
              <Spacer x={0.75} />
            </>
          )}
          {props.cta && (
            <ButtonDS
              onClick={!props.cta.isDisabled ? props.cta.action : undefined}
              text={props.cta.title}
              format={"hug"}
              buttonType={"primary"}
              leftIcon={props.cta.leftIcon}
              disabled={props.cta.isDisabled}
            />
          )}
        </StyledFlex>
      )}
    </StyledHeader>
  );
};

const StyledHeaderButton = styled.div`
  min-width: fit-content;
`;

const StyledFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  ${typographies["Header/H1"]};
  colors: ${colors["colors/text/black"]};
  align-items: center;
  justify-content: space-between;
  min-height: 3rem;
  width: 100%;
`;
const StyledAllWidth = styled.div`
  width: 100%;
`;

const StyledSupport = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledFrame = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border-radius: 0.75rem 0 0 0.75rem;
  overflow: auto;
`;

const StyledContent = styled.div`
  overflow: auto;
  width: 100%;
  height: fit-content;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledLine = styled.div`
  display: flex;
  border-top: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
  width: 100%;
`;
