import { TagOrBadge } from "./TagOrBadge";

export interface BadgeProps {
  text: string;
  backgroundColor: string;
  textColor: string;
}

export const Badge = (props: BadgeProps) => {
  return (
    <TagOrBadge
      type={"BADGE"}
      textColor={props.textColor}
      text={props.text}
      backgroundColor={props.backgroundColor}
    />
  );
};
