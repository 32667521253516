import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  Avatar,
  ButtonDS,
  InputDS,
  NotInterestedOutlined,
  SidePanelDS,
  Spacer,
  Switch,
  Tag,
  TextCapitalized,
} from "@qivia/ui";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useTranslation } from "react-i18next";
import {
  cancelPasswordCreationRequestAsync,
  lastCreatedPasswordRequestAsync,
  relaunchPasswordCreationRequestAsync,
  selectLastCreatedPasswordRequest,
  selectLastCreatedPasswordRequestStatus,
  selectManagersList,
} from "./usersSlice";
import { ManagerDataDisplayRow } from "./usersAPI";
import { statusColor } from "./lib";
import { DeactivateUserModal } from "./deactivateUserModal";

export const UserManagementSidePanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const managersList = useAppSelector(selectManagersList);
  const lastCreatedPasswordRequestResponse = useAppSelector(
    selectLastCreatedPasswordRequest,
  );
  const lastCreatedPasswordRequestStatus = useAppSelector(
    selectLastCreatedPasswordRequestStatus,
  );
  const manager = managersList.find((m) => m.uuid === params.id);
  const [isDeactivateModalVisible, setIsDeactivateModalVisible] =
    useState(false);

  useEffect(() => {
    if (!manager) {
      navigate(`/home/users/management`);
    }
  }, [navigate, manager]);

  useEffect(() => {
    if (
      manager &&
      manager.uuid &&
      lastCreatedPasswordRequestStatus === "idle"
    ) {
      void dispatch(
        lastCreatedPasswordRequestAsync({ managerUuid: manager.uuid }),
      );
    }
  }, [dispatch, lastCreatedPasswordRequestStatus, manager]);

  useEffect(() => {
    if (params.id) {
      void dispatch(
        lastCreatedPasswordRequestAsync({ managerUuid: params.id }),
      );
    }
  }, [dispatch, manager, params.id]);

  const onSidePanelClosed = useCallback(() => {
    navigate("/home/users/management");
  }, [navigate]);

  if (!manager) {
    return;
  }

  return (
    <SidePanelDS.SidePanel visible={!!manager} onClose={onSidePanelClosed}>
      <SidePanelDS.Section
        verticalSpacing={2}
        horizontalSpacing={1.5}
        background={colors["colors/button/secondary/hover"]}
      >
        <DeactivateUserModal
          isVisible={isDeactivateModalVisible}
          onCloseModal={() => {
            setIsDeactivateModalVisible(false);
          }}
          manager={manager}
        />
        <StyledPanelContainer>
          <Tag
            text={t(`users.management.status.${manager.status}`)}
            backgroundColor={statusColor[manager.status].backgroundColor}
            textColor={statusColor[manager.status].textColor}
          />
          <Spacer y={1.5} />
          <Avatar
            size={"XL"}
            color={"mint"}
            firstName={manager["firstName"]}
            lastName={manager["lastName"]}
          />
          <Spacer y={1.5} />
          <StyledName>{manager.name}</StyledName>

          {manager.status === "ACTIVE" && params.id !== manager.uuid && (
            <>
              <Spacer y={2.5} />
              <ButtonDS
                leftIcon={<NotInterestedOutlined />}
                text={t("users.management.sidePanel.deactivate")}
                format={"fill"}
                buttonType={"secondary"}
                onClick={() =>
                  setIsDeactivateModalVisible(!isDeactivateModalVisible)
                }
                iconColor={"errorNormal"}
              />
            </>
          )}
          {manager.status === "PENDING" && (
            <>
              <Spacer y={2.5} />
              <StyledRow>
                <ButtonDS
                  text={t("users.management.sidePanel.relaunch")}
                  format={"hug"}
                  buttonType={"primary"}
                  onClick={() =>
                    manager.uuid &&
                    void dispatch(
                      relaunchPasswordCreationRequestAsync({
                        managerUuid: manager.uuid,
                      }),
                    )
                  }
                />
                {!lastCreatedPasswordRequestResponse.lastCreatedPasswordRequest
                  ?.canceledAt && (
                  <>
                    <Spacer x={0.5} />
                    <ButtonDS
                      text={t("users.management.sidePanel.cancel")}
                      format={"hug"}
                      buttonType={"secondary"}
                      onClick={() =>
                        manager.uuid &&
                        void dispatch(
                          cancelPasswordCreationRequestAsync({
                            managerUuid: manager.uuid,
                          }),
                        )
                      }
                    />
                  </>
                )}
              </StyledRow>
            </>
          )}
        </StyledPanelContainer>
      </SidePanelDS.Section>
      <SidePanelDS.Section horizontalSpacing={1.5} verticalSpacing={2}>
        <SidePanelInformation manager={manager} />
      </SidePanelDS.Section>
      <SidePanelDS.Section horizontalSpacing={1.5} verticalSpacing={2}>
        <SidePanelRole manager={manager} />
      </SidePanelDS.Section>
    </SidePanelDS.SidePanel>
  );
};

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledName = styled.div`
  ${typographies["Header/H1"]};
  color: ${colors["colors/text/darkGrey"]};
`;

const SidePanelInformation = (props: { manager: ManagerDataDisplayRow }) => {
  const { t } = useTranslation();

  const managerInformations = ["firstName", "lastName", "email"];

  return (
    <>
      <TextCapitalized>
        <StyledLabel>
          {t(`users.management.sidePanel.informations.title`)}
        </StyledLabel>
      </TextCapitalized>
      {managerInformations.map((label) => {
        const informationDisplay =
          props.manager[label as keyof ManagerDataDisplayRow];

        return (
          <>
            <Spacer y={2} />
            <StyledInformations key={label}>
              <Spacer x={0.25} />
              <TextCapitalized>
                {t(`users.management.sidePanel.informations.${label}`)}
              </TextCapitalized>
            </StyledInformations>
            <Spacer y={0.25} />
            <InputDS
              value={informationDisplay}
              withoutSpacerBottom
              isDisabled={true}
            />
          </>
        );
      })}
    </>
  );
};

const SidePanelRole = (props: { manager: ManagerDataDisplayRow }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextCapitalized>
        <StyledLabel>{t(`users.management.sidePanel.role.title`)}</StyledLabel>
      </TextCapitalized>
      <Spacer y={2} />
      <SidePanelDS.SectionRow
        label={
          <StyledSubTitle>
            <Spacer x={0.25} />
            <TextCapitalized>
              {t(`users.management.sidePanel.role.${props.manager.role}.title`)}
            </TextCapitalized>
            <Spacer y={0.25} />
            <StyledRoleText>
              <TextCapitalized>
                {t(
                  `users.management.sidePanel.role.${props.manager.role}.text`,
                )}
              </TextCapitalized>
            </StyledRoleText>
          </StyledSubTitle>
        }
        value={
          <Switch
            checked={true}
            size="S"
            disabled={true}
            onClick={() => {}}
            color={"GREEN"}
          />
        }
      />
    </>
  );
};

const StyledInformations = styled.div`
  display: flex;
  ${typographies["Body/L"]};
  color: ${colors["colors/text/black"]};
`;

const StyledLabel = styled.div`
  ${typographies["Body/L"]}
  color: ${colors["colors/text/darkGrey"]};
`;

const StyledSubTitle = styled.div`
  ${typographies["Body/L"]}
`;

const StyledRoleText = styled.div`
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/darkGrey"]};
  max-width: 70%;
`;

const StyledRow = styled.div`
  display: flex;
`;
