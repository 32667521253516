import styled from "styled-components";
import { colors } from "../../../styles/figmaColors";
import { typographies } from "../../../styles/figmaTypographies";
import { Spacer } from "./../Spacer";
import { TextCapitalized } from "./../text/TextCapitalized";

export interface MenuOptionsProps {
  options: Option[];
  bottomButton?: JSX.Element;
  width?: string;
}

interface Option {
  label: string | JSX.Element;
  action: () => void;
  color?: string;
  icon?: JSX.Element;
}

export const MenuOptions = (props: MenuOptionsProps) => {
  return (
    <StyledContainer $width={props.width}>
      <Spacer y={0.5} />
      <StyledOverflow>
        {props.options.map((option, index) => {
          return (
            <StyledRow key={"row" + index}>
              <Spacer x={0.5} />
              <StyledOption onClick={option.action} $color={option.color}>
                <Spacer y={0.75} />
                <StyledRow>
                  {option.icon}
                  {option.icon && <Spacer x={0.25} />}
                  <TextCapitalized>{option.label}</TextCapitalized>
                </StyledRow>
                <Spacer y={0.75} />
              </StyledOption>
              <Spacer x={0.5625} />
            </StyledRow>
          );
        })}
      </StyledOverflow>
      {props.bottomButton && (
        <>
          <Spacer y={0.5} />
          <StyledLine />
          <Spacer y={1} />
          <StyledRow>
            <Spacer x={1} />
            {props.bottomButton}
            <Spacer x={1.0625} />
          </StyledRow>
          <Spacer y={0.5} />
        </>
      )}
      <Spacer y={0.5} />
    </StyledContainer>
  );
};

interface StyledContainerArgs {
  $color?: string;
  $width?: string;
}

const StyledOverflow = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  max-height: 27rem;
`;

const StyledLine = styled.div`
  display: flex;
  border-top: 0.0625rem solid ${colors["colors/borders/cells/cells"]};
`;

const StyledContainer = styled.div<StyledContainerArgs>`
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  border-radius: 0.5rem;
  width: ${({ $width }) => ($width ? $width : "14.1875")}rem;
  box-shadow: 0.125rem 0.0625rem 0.25rem 0rem rgba(122, 122, 122, 0.08);
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledOption = styled.div<StyledContainerArgs>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ${typographies["Body/M"]};
  color: ${({ $color }) => $color && colors["colors/text/darkGrey"]};
  border-radius: 0.5rem;

  &:hover {
    cursor: pointer;
    background-color: ${colors["colors/surfaces/background/background_level1"]};
  }
  & svg {
    width: 1rem;
    height: 1rem;
    fill: ${colors["colors/text/darkGrey"]};
  }
`;
