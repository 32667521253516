/// regex :
export const hasPhoneFormatWithoutAreaCode = (phoneNumber: string) => {
  const regex = /^[0-9]{9}$/;
  return regex.test(phoneNumber);
};

export const hasPhoneFormatWithAreaCode = (phoneNumber: string) => {
  const regex = /^\+\d{2}\d{9}$/;
  return regex.test(phoneNumber);
};

export const hasMobilePhoneFormatWithoutAreaCode = (phoneNumber: string) => {
  const phone = /^[6-7-9]\d{8}$/;
  return phone.test(phoneNumber);
};

export const hasMobilePhoneFormatWithIndex = (phoneNumber: string) => {
  const phone = /^0[6-7-9]\d{8}$/;
  return phone.test(phoneNumber);
};

export const hasMobilePhoneFormatWithAreaCode = (phoneNumber: string) => {
  const regex = /^\+\d{2}[6-7-9]\d{8}$/;
  return regex.test(phoneNumber);
};

export const hasMobilePhoneFormatWithSpaces = (phoneNumber: string) => {
  const regex = /^0[6-7-9](?:\s*\d){8}$/;
  return regex.test(phoneNumber);
};

export const hasMobilePhoneFormat = (phone: string) => {
  return (
    hasMobilePhoneFormatWithoutAreaCode(phone) ||
    hasMobilePhoneFormatWithAreaCode(phone) ||
    hasMobilePhoneFormatWithIndex(phone)
  );
};

export const hasPasswordFormat = (password: string) => {
  const regex = /^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return regex.test(password);
};

export const has8Char = (password: string) => {
  return password.length >= 8;
};

export const has1SpecialCharacter = (password: string) => {
  const regex = /[#?!@$%^&*-]/;
  return regex.test(password);
};

export const has1Number = (password: string) => {
  const regex = /[0-9]/;
  return regex.test(password);
};

export const hasEmailFormat = (email: string) => {
  const regex = /^[\w.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const hasBirthDateFormat = (birthDate: string) => {
  const regexBirthDate =
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
  return regexBirthDate.test(birthDate);
};

export const hasRegistrationNumberFormat = (registrationNumber: string) => {
  const regexWithoutDashes = /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$/;
  const regexWithDashes = /^[a-zA-Z]{2}[-][0-9]{3}[-][a-zA-Z]{2}$/;
  return (
    regexWithoutDashes.test(registrationNumber) ||
    regexWithDashes.test(registrationNumber)
  );
};

export const hasIdentifierFormat = (identifier: string) => {
  const regexIdentifier = /^QIV_[a-zA-Z0-9]{6}$/;
  return regexIdentifier.test(identifier);
};

/// formatter:

export const addPhoneAreaCode = (phone: string) => {
  const phoneFormatted = "+33" + phone;
  return phoneFormatted;
};

export const phoneNumberFormatter = (phone: string) => {
  if (hasMobilePhoneFormatWithAreaCode(phone)) {
    phone = phone.substring(3);
  } else if (hasMobilePhoneFormatWithIndex(phone)) {
    phone = phone.substring(1);
  }
  return phone;
};

export const formatMobilePhoneWithoutSpaces = (phoneNumber: string) => {
  return addPhoneAreaCode(phoneNumber.replaceAll(" ", "").substring(1));
};

export const registrationNumberFormatter = (registrationNumber: string) => {
  const regex = /^[a-zA-Z]{2}[0-9]{3}[a-zA-Z]{2}$/;
  if (registrationNumber.match(regex)) {
    registrationNumber =
      registrationNumber.slice(0, 2) +
      "-" +
      registrationNumber.slice(2, 5) +
      "-" +
      registrationNumber.slice(5, 7);
  }
  return registrationNumber.toUpperCase();
};

export const dateFormatterDay = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterLongMonthYear = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    month: "long",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterMonthYear = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    month: "short",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonth = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    month: "short",
    day: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthLong = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    month: "long",
    day: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayShortMonthYear = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthYear = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthYear2Digits = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthYearLong2Digits = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return formatter.format(date);
};

export const dateFormatterSimpleDate = (date: Date) =>
  formatDate(date, {
    year: "numeric",
    day: "2-digit",
    month: "2-digit",
  });

const formatDate = (
  date: Date,
  options: {
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "long" | "short";
    day?: "numeric" | "2-digit";
  },
) => new Intl.DateTimeFormat("fr", options).format(date);

export const dateFormatterHourMinute = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return formatter.format(date);
};
export const dateFormatterDayMonthYearHourMinute = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayLongMonthYearHourMinuteSecond = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return formatter.format(date);
};

export const dateFormatterDayMonthYearHourMinute2Digits = (date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });
  return formatter.format(date);
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const uppercaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const dateFormatterDayMonthYearAndHourMinute2Digits = (date: Date) => {
  const formatter =
    dateFormatterDayMonthYear2Digits(date) +
    " à " +
    dateFormatterHourMinute(date).replaceAll(":", "h");
  return formatter;
};

export const formattedZipFolderFileName = (prefix: string, date: Date) => {
  const formatter = new Intl.DateTimeFormat("fr", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedDateTemp = formatter.format(date);
  const formattedDate = formattedDateTemp.split("/").join("_");
  return `${prefix}_${formattedDate}.zip`;
};
