import { colors } from "../../styles/figmaColors";
import { ElementInitialCircle } from "./ElementInitialCircle";

export type CritAir = "0" | "1" | "2" | "3" | "4" | "5";

const critAirDict: {
  [key in CritAir]: { backgroundColor: string };
} = {
  "0": {
    backgroundColor: "#20A056",
  },
  "1": {
    backgroundColor: "#835092",
  },
  "2": {
    backgroundColor: "#EEC51D",
  },
  "3": {
    backgroundColor: "#E59035",
  },
  "4": {
    backgroundColor: "#6C383C",
  },
  "5": {
    backgroundColor: "#595651",
  },
};

type CritAirProps = {
  critAir: CritAir;
};

export const CritAir = (props: CritAirProps) => {
  const { critAir } = props;
  return (
    <ElementInitialCircle
      text={critAir}
      size={"S"}
      color={colors["colors/text/white"]}
      backgroundColor={critAirDict[critAir].backgroundColor}
    />
  );
};
