import {
  Option,
  triggerToast,
  DialogModalDS,
  DirectionsCarOutlined,
  ButtonDS,
  SelectDS,
  InputDS,
  TooltipDS,
  registrationNumberFormatter,
  hasRegistrationNumberFormat,
  Spacer,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectErrorMessage,
  selectListAllVehiclesInDb,
  selectVehiclesCreatedStatus,
  vehicleAsync,
} from "./vehiclesSlice";
import { VehicleType, VehicleCreationAPI } from "./vehiclesAPI";
import styled from "styled-components";
import { selectCompany } from "../homeSlice";

export const AddVehicleModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onCloseModal } = props;
  const company = useAppSelector(selectCompany);
  const createVehicleStatus = useAppSelector(selectVehiclesCreatedStatus);
  const vehicleCreationErrorMessage = useAppSelector(selectErrorMessage);
  const vehiclesListInDb = useAppSelector(selectListAllVehiclesInDb);

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [registrationNumber, setRegistrationNumber] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  const [vehicleType, setVehicleType] = useState<VehicleType | null>(null);

  const isOutIfFleetVehicle: boolean = useMemo(
    () => vehicleType === "OUT_OF_FLEET",
    [vehicleType],
  );

  const hasDuplicateReference: boolean = useMemo(() => {
    if (reference === "") return false;
    return !!vehiclesListInDb.find(
      (vehicle) => vehicle.reference.toUpperCase() === reference.toUpperCase(),
    );
  }, [reference, vehiclesListInDb]);

  const hasDuplicateRegistrationNumber: boolean = useMemo(() => {
    return !!vehiclesListInDb.find(
      (vehicle) =>
        (hasRegistrationNumberFormat(vehicle.registrationNumber) &&
          registrationNumberFormatter(vehicle.registrationNumber)) ===
        registrationNumberFormatter(registrationNumber),
    );
  }, [registrationNumber, vehiclesListInDb]);

  const isValidForm: boolean = useMemo(
    () =>
      !!(registrationNumber || reference) &&
      (hasRegistrationNumberFormat(registrationNumber) ||
        !registrationNumber) &&
      !hasDuplicateReference &&
      !hasDuplicateRegistrationNumber &&
      vehicleType !== null &&
      reference.length <= 28,
    [
      hasDuplicateReference,
      hasDuplicateRegistrationNumber,
      reference,
      registrationNumber,
      vehicleType,
    ],
  );

  const optionsVehicleType = useMemo(
    () =>
      [
        { value: "SHARED", label: t("vehicles.modal.type.shared") },
        {
          value: "PERSONAL",
          label: t("vehicles.modal.type.personal"),
        },
        { value: "OUT_OF_FLEET", label: t("vehicles.modal.type.out_of_fleet") },
      ] as Option<VehicleType>[],
    [t],
  );

  const valueVehicleType = useMemo(
    () => optionsVehicleType.find((option) => option.value === vehicleType),
    [optionsVehicleType, vehicleType],
  );

  const resetValue = useCallback(() => {
    setVehicleType(null);
    setRegistrationNumber("");
    setDisplayError(false);
    setReference("");
  }, [setVehicleType, setRegistrationNumber, setDisplayError]);

  const onSubmit = useCallback(() => {
    if (!vehicleType || !isValidForm || !company) return;
    const vehicleData: VehicleCreationAPI = {
      registrationNumber: registrationNumber
        ? registrationNumberFormatter(registrationNumber)
        : null,
      vehicleType,
      reference: reference?.toUpperCase() ?? null,
      companyUuid: company.uuid,
    };
    void dispatch(vehicleAsync(vehicleData));
    onCloseModal();
  }, [
    vehicleType,
    isValidForm,
    registrationNumber,
    reference,
    dispatch,
    onCloseModal,
    company,
  ]);

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    isValidForm && onSubmit();
  }, [isValidForm, onSubmit]);

  const referenceError: string | undefined = useMemo(() => {
    return displayError
      ? !isOutIfFleetVehicle && registrationNumber === "" && reference === ""
        ? t("vehicles.modal.error.emptyInputs")
        : isOutIfFleetVehicle && reference === ""
          ? t("vehicles.modal.error.emptyInput")
          : reference.length > 28
            ? t("vehicles.modal.error.reference.length")
            : hasRegistrationNumberFormat(reference)
              ? t("vehicles.modal.error.reference.incorrectFormat")
              : hasDuplicateReference
                ? t("vehicles.modal.error.reference.alreadyExists")
                : undefined
      : undefined;
  }, [
    displayError,
    hasDuplicateReference,
    isOutIfFleetVehicle,
    reference,
    registrationNumber,
    t,
  ]);

  const registrationNumberError: string | undefined = useMemo(() => {
    return displayError
      ? !isOutIfFleetVehicle && registrationNumber === "" && reference === ""
        ? t("vehicles.modal.error.emptyInputs")
        : registrationNumber && !hasRegistrationNumberFormat(registrationNumber)
          ? t("vehicles.modal.error.registrationNumber.incorrectFormat")
          : hasDuplicateRegistrationNumber
            ? t("vehicles.modal.error.registrationNumber.alreadyExists")
            : undefined
      : undefined;
  }, [
    displayError,
    hasDuplicateRegistrationNumber,
    isOutIfFleetVehicle,
    reference,
    registrationNumber,
    t,
  ]);

  useEffect(() => {
    if (isOutIfFleetVehicle) {
      setRegistrationNumber("");
    }
  }, [isOutIfFleetVehicle]);

  useEffect(() => {
    if (props.visible === false) {
      resetValue();
    }
  }, [props.visible, resetValue]);

  useEffect(() => {
    if (createVehicleStatus === "success" && !vehicleCreationErrorMessage) {
      triggerToast(t("vehicles.modal.success") || "", "valid");
    } else if (
      createVehicleStatus === "success" &&
      vehicleCreationErrorMessage
    ) {
      triggerToast(
        t(`vehicles.modal.registrationNumber.${vehicleCreationErrorMessage}`),
        "error",
      );
    } else if (createVehicleStatus === "failed") {
      triggerToast(t("vehicles.modal.failure") || "", "error");
    }
  }, [createVehicleStatus, t, vehicleCreationErrorMessage]);

  return (
    <DialogModalDS
      visible={props.visible}
      title={t("vehicles.modal.title") || ""}
      subTitle={t("vehicles.modal.subTitle") || ""}
      iconTitle={<DirectionsCarOutlined />}
      onClose={props.onCloseModal}
      bottomButton={{
        leftButton: (
          <ButtonDS
            text={t("vehicles.modal.cancel")}
            format={"fill"}
            buttonType={"secondary"}
            onClick={() => {
              props.onCloseModal();
            }}
          />
        ),
        rightButton: (
          <ButtonDS
            text={t("vehicles.modal.add")}
            format={"fill"}
            buttonType={"primary"}
            onClick={clickToSubmit}
            disabled={!isValidForm}
          />
        ),
      }}
    >
      <StyledModalContent>
        <SelectDS
          label={t("vehicles.modal.type") || ""}
          options={optionsVehicleType}
          placeholder={t("vehicles.modal.type.placeholder") || ""}
          onChange={(e) => setVehicleType(e ? e.value : null)}
          value={valueVehicleType}
          error={
            displayError && !vehicleType
              ? t("vehicles.modal.error.selectInput")
              : undefined
          }
          isMandatory
          allWidth
        />
        <InputDS
          label={t("vehicles.modal.registrationNumber") || ""}
          placeholder={
            (!isOutIfFleetVehicle &&
              t("vehicles.modal.registrationNumber.placeholder")) ||
            ""
          }
          error={registrationNumberError}
          value={registrationNumber}
          onChange={(e) => setRegistrationNumber(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
          isDisabled={isOutIfFleetVehicle}
          isPlaceholderUppercase
        />
        <InputDS
          label={t("vehicles.modal.reference") || ""}
          placeholder={t("vehicles.modal.reference.placeholder") || ""}
          error={referenceError}
          value={reference}
          onChange={(e) => setReference(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
          withoutSpacerBottom
          tooltip={
            <TooltipDS
              id={"AddVehicleModalTooltip"}
              place={"right"}
              title={t("vehicles.modal.tooltip.title")}
              content={t("vehicles.modal.tooltip.content")}
            />
          }
          type="ADORNENTS"
          adornent={reference.length.toString() + "/28"}
        />
        <Spacer y={0.625} />
      </StyledModalContent>
    </DialogModalDS>
  );
};

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
