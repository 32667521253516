import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CompanyCreationApi } from "./companiesApi";
import { QueryStatus, authAxios } from "../../../utils";
import { RootState } from "../../../redux/store";
import { loadAllCompaniesAsync, loadCompanyAsync } from "../homeSlice";

export interface RegisterState {
  companyCreationStatus: QueryStatus;
}

const initialState: RegisterState = {
  companyCreationStatus: "idle",
};

export const companyAsync = createAsyncThunk(
  "companies/creation",
  async (payload: CompanyCreationApi, thunkApi) => {
    const axios = authAxios();
    const response = await axios.post<{ uuid: string }>(`/company`, payload);
    void thunkApi.dispatch(loadCompanyAsync({ uuid: response.data.uuid }));
    void thunkApi.dispatch(
      loadAllCompaniesAsync({ companyGroupUuid: payload.companyGroupUuid }),
    );
  },
);

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    resetCompanyCreationStatus: (state) => {
      state.companyCreationStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(companyAsync.pending, (state) => {
        state.companyCreationStatus = "processing";
      })
      .addCase(companyAsync.fulfilled, (state) => {
        state.companyCreationStatus = "success";
      })
      .addCase(companyAsync.rejected, (state) => {
        state.companyCreationStatus = "failed";
      });
  },
});

export const selectCompanyCreationStatus = (state: RootState) =>
  state.companies.companyCreationStatus;

export default companiesSlice.reducer;
