import { FunctionComponent, useCallback, useEffect } from "react";
import {
  Avatar,
  LockOutlined,
  SidePanelDS,
  Spacer,
  Switch,
  Tag,
  TextCapitalized,
} from "@qivia/ui";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { selectDriversList, updateDriverStatusAsync } from "./driversSlice";
import { useTranslation } from "react-i18next";

export const DriversSidePanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const driversList = useAppSelector(selectDriversList);
  const driver = driversList.find((d) => d.uuid === params.id);

  const updateDriverStatus = useCallback(
    (blockDriver: boolean, driverUuid: string) => {
      void dispatch(
        updateDriverStatusAsync({
          uuid: driverUuid,
          status: blockDriver ? "block" : "unblock",
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (!driver) {
      navigate(`/home/drivers`);
    }
  }, [navigate, driver]);

  const onSidePanelClosed = useCallback(() => {
    navigate("/home/drivers");
  }, [navigate]);

  if (!driver) {
    return;
  }

  const initials = driver.name.split(" ").map((row) => row.charAt(0));
  const isDriverBlocked = driver.status === "BLOCKED";

  return (
    <SidePanelDS.SidePanel visible={!!driver} onClose={onSidePanelClosed}>
      <SidePanelDS.Section
        verticalSpacing={2}
        horizontalSpacing={1.5}
        background={colors["colors/button/secondary/hover"]}
      >
        <StyledPanelContainer>
          {driver.status === "ACTIVE" ? (
            <Tag
              text={t(`drivers.status.${driver.status}`)}
              backgroundColor={
                colors["colors/system/success/success_ultraLight"]
              }
              textColor={colors["colors/system/success/success_normal"]}
            />
          ) : (
            <Tag
              text={t(`drivers.status.${driver.status}`)}
              backgroundColor={
                colors["colors/surfaces/background/background_level2"]
              }
              textColor={colors["colors/text/black"]}
            />
          )}
          <Spacer y={1.5} />
          <Avatar
            size={"XL"}
            color={"mint"}
            firstName={initials[0]}
            lastName={initials[initials.length - 1]}
          />
          <Spacer y={1.5} />
          <StyledName>{driver.name}</StyledName>
          <Spacer y={1.5} />
          {driver.status !== "DELETE" && (
            <SidePanelDS.ActionBlock>
              <SidePanelDS.SectionedActionStripe
                left={
                  <StyledRowCenter>
                    <LockOutlined />
                    <Spacer x={0.25} />
                    <TextCapitalized>
                      {t("cards.sidePanel.lock")}
                    </TextCapitalized>
                  </StyledRowCenter>
                }
                right={
                  <Switch
                    onClick={() =>
                      updateDriverStatus(!isDriverBlocked, driver.uuid)
                    }
                    checked={isDriverBlocked || false}
                    size={"S"}
                  />
                }
              />
            </SidePanelDS.ActionBlock>
          )}
        </StyledPanelContainer>
      </SidePanelDS.Section>
    </SidePanelDS.SidePanel>
  );
};

const StyledRowCenter = styled.div`
  ${typographies["Body/S"]}
  display: flex;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledName = styled.div`
  ${typographies["Header/H1"]};
  color: ${colors["colors/text/darkGrey"]};
`;
