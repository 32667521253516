import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios } from "../../../utils";
import {
  DriversAPI,
  DriversListType,
  updateDriverStatusType,
  PendingDriverAccessRequest,
  UpdateDriverAccessRequest,
} from "./driversAPI";
import { RootState } from "../../../redux/store";
import { QueryStatus } from "../../../utils";
import { capitalize } from "@qivia/ui/src/format";

export interface driversState {
  driversListStatus: QueryStatus;
  driversList: DriversListType[];
  driverUpdatedStatus: QueryStatus;
  driverAccessRequestsListStatus: QueryStatus;
  driverAccessRequestsList: PendingDriverAccessRequest[];
  currentDriverAccessRequest: PendingDriverAccessRequest | null;
}

const initialState: driversState = {
  driversListStatus: "idle",
  driversList: [],
  driverUpdatedStatus: "idle",
  driverAccessRequestsListStatus: "idle",
  driverAccessRequestsList: [],
  currentDriverAccessRequest: null,
};

export const driversListAsync = createAsyncThunk(
  "driversList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<DriversAPI>>(
      `drivers/company/${companyUuid}`,
    );
    const driversList = response.data.map((payload) => ({
      ...payload,
      date: payload.createdAt,
      vehicleType: payload.vehicleStatus,
      name: capitalize(payload.firstName) + " " + capitalize(payload.lastName),
    }));
    return driversList;
  },
);

export const driversAccessRequestsListAsync = createAsyncThunk(
  "driversAccessRequestsList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<PendingDriverAccessRequest>>(
      `pending_access_requests/company/${companyUuid}`,
    );
    return response.data;
  },
);

export const updateDriverAccessRequestAsync = createAsyncThunk(
  "updateDriverAccessRequest/call",
  async (payload: UpdateDriverAccessRequest, thunkAPI) => {
    const axios = authAxios();
    await axios.put(
      `pending_access_request/${payload.uuid}/${payload.status}/company/${payload.companyUuid}`,
    );
    void thunkAPI.dispatch(driversAccessRequestsListAsync(payload.companyUuid));
  },
);

export const updateDriverStatusAsync = createAsyncThunk(
  "updateDriverStatus/call",
  async (payload: updateDriverStatusType) => {
    const axios = authAxios();
    await axios.put(`/driver/${payload.uuid}/${payload.status}`);
  },
);

export const driversSlice = createSlice({
  name: "vehicles",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetDriversListStatus(state) {
      state.driversListStatus = "idle";
    },
    resetDriversAccessRequestsListStatus(state) {
      state.driverAccessRequestsListStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(driversListAsync.pending, (state) => {
        state.driversListStatus = "processing";
      })
      .addCase(driversListAsync.fulfilled, (state, action) => {
        state.driversList = action.payload;
        state.driversListStatus = "success";
        state.driverUpdatedStatus = "idle";
      })
      .addCase(driversListAsync.rejected, (state) => {
        state.driversListStatus = "failed";
      })
      .addCase(updateDriverStatusAsync.pending, (state) => {
        state.driverUpdatedStatus = "processing";
      })
      .addCase(updateDriverStatusAsync.fulfilled, (state) => {
        state.driverUpdatedStatus = "success";
      })
      .addCase(updateDriverStatusAsync.rejected, (state) => {
        state.driverUpdatedStatus = "failed";
      })
      .addCase(driversAccessRequestsListAsync.pending, (state) => {
        state.driverAccessRequestsListStatus = "processing";
      })
      .addCase(driversAccessRequestsListAsync.fulfilled, (state, action) => {
        state.driverAccessRequestsList = action.payload;
        state.currentDriverAccessRequest = action.payload[0] ?? null;
        state.driverAccessRequestsListStatus = "success";
      })
      .addCase(driversAccessRequestsListAsync.rejected, (state) => {
        state.driverAccessRequestsListStatus = "failed";
      });
  },
});

export const selectDriversList = (state: RootState) =>
  state.drivers.driversList;

export const selectDriversListStatus = (state: RootState) =>
  state.drivers.driversListStatus;

export const selectDriverUpdatedStatus = (state: RootState) =>
  state.drivers.driverUpdatedStatus;

export const selectDriverAccessRequestsListStatus = (state: RootState) =>
  state.drivers.driverAccessRequestsListStatus;

export const selectDriverAccessRequestsList = (state: RootState) =>
  state.drivers.driverAccessRequestsList;

export const selectCurrentDriverAccessRequest = (state: RootState) =>
  state.drivers.currentDriverAccessRequest;

export default driversSlice.reducer;
