import { Assets, Card, OnboardingPage, Spacer } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BusinessType } from "../../stripe/stripeAPI";
import { ButtonBack, TitleForm } from "./formSignIn";

export interface BusinessTypePageProps {
  nextPage: (type: BusinessType) => void;
  previousPage: () => void;
}

export const BusinessTypePage = (props: BusinessTypePageProps) => {
  const { t } = useTranslation();
  return (
    <OnboardingPage
      title={<TitleForm text={"signIn.businessType.title1"} />}
      subtitle="signIn.businessType.subtitle"
      form={
        <div>
          {[
            { title: "company", type: "company" as BusinessType },
            { title: "nonProfit", type: "non_profit" as BusinessType },
          ].map((elem, index) => (
            <div key={index}>
              <StyledDiv>
                <Card
                  title={t(`signIn.businessType.cta.${elem.title}`)}
                  Element={<Assets.VectorSolid />}
                  onClick={() => props.nextPage(`${elem.type}`)}
                />
              </StyledDiv>
              <Spacer y={1} />
            </div>
          ))}
          <ButtonBack onClick={() => props.previousPage()} />
        </div>
      }
    />
  );
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;
