import * as XLSX from "xlsx";
import { hasRegistrationNumberFormat } from "./format";

export const isValidExcelColumnsNamingForMultipleVehicles = (
  workSheet: XLSX.WorkSheet,
) => {
  if (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    workSheet.A1?.h === "Immatriculation" &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    workSheet.B1?.h === "Référence" &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    workSheet.C1?.h === "Type de véhicule"
  )
    return true;
  return false;
};

export const cellsContentErrorsForMultipleVehicles = (
  workSheet: XLSX.WorkSheet,
  fileSize: number,
) => {
  const cellsErrors: Array<{
    cell: string;
    content: string;
    errorType: string;
  }> = [];

  if (!isValidExcelColumnsNamingForMultipleVehicles(workSheet)) {
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      workSheet.A1?.h !== "Immatriculation"
    ) {
      cellsErrors.push({
        cell: "A1",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        content: workSheet.A1?.h as string,
        errorType: "header",
      });
    }
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      workSheet.B1?.h !== "Référence"
    ) {
      cellsErrors.push({
        cell: "B1",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        content: workSheet.B1?.h as string,
        errorType: "header",
      });
    }
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      workSheet.C1?.h !== "Type de véhicule"
    ) {
      cellsErrors.push({
        cell: "C1",
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        content: workSheet.C1?.h as string,
        errorType: "header",
      });
    }
  }
  const registrationNumbers: string[] = [];
  const references: string[] = [];
  for (let i = 2; i <= fileSize + 1; i++) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const cellValueA = workSheet[`A${i}`]?.v as string;
    if (cellValueA && !hasRegistrationNumberFormat(cellValueA)) {
      cellsErrors.push({
        cell: `A${i}`,
        content: cellValueA,
        errorType: "registrationNumber",
      });
    } else {
      registrationNumbers.push(cellValueA);
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const cellValueB = workSheet[`B${i}`]?.v as string;
    if (cellValueB) {
      if (cellValueB.length > 28) {
        cellsErrors.push({
          cell: `B${i}`,
          content: cellValueB,
          errorType: "reference",
        });
      } else if (hasRegistrationNumberFormat(cellValueB)) {
        cellsErrors.push({
          cell: `B${i}`,
          content: cellValueB,
          errorType: "reference.registrationNumberFormat",
        });
      } else {
        references.push(cellValueB);
      }
    }
    if (!cellValueA && !cellValueB) {
      cellsErrors.push({
        cell: `A${i}`,
        content: cellValueB,
        errorType: "emptyName",
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const cellValueC = workSheet[`C${i}`]?.v as string;
    if (cellValueC) {
      if (
        cellValueC.toUpperCase() !== "MONO-CONDUCTEUR" &&
        cellValueC.toUpperCase() !== "MULTI-CONDUCTEURS"
      ) {
        cellsErrors.push({
          cell: `C${i}`,
          content: cellValueC,
          errorType: "vehicleType",
        });
      }
    } else {
      cellsErrors.push({
        cell: `C${i}`,
        content: cellValueC,
        errorType: "emptyCell",
      });
    }
  }
  const duplicateRegistrationNumbers = registrationNumbers.filter(
    (item, index) => registrationNumbers.indexOf(item) !== index && item,
  );
  if (duplicateRegistrationNumbers.length > 0) {
    cellsErrors.push({
      cell: `A`,
      content: [...new Set(duplicateRegistrationNumbers)].join(","),
      errorType: "duplicate.registrationNumbers",
    });
  }
  const duplicateReferences = references.filter(
    (item, index) => references.indexOf(item) !== index && item,
  );
  if (duplicateReferences.length > 0) {
    cellsErrors.push({
      cell: `B`,
      content: [...new Set(duplicateReferences)].join(","),
      errorType: "duplicate.references",
    });
  }

  return cellsErrors;
};
