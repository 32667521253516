import {
  addPhoneAreaCode,
  capitalize,
  hasPasswordFormat,
  LaunchPage,
  phoneNumberFormatter,
} from "@qivia/ui";
import { registerAsync, selectStatus } from "./registerSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Navigate } from "react-router-dom";
import { selectIsTokenDecoded, selectManager } from "../home/homeSlice";
import { useCallback, useEffect, useState } from "react";
import {
  selectStripeToken,
  stripeTokenCreationAsync,
} from "../stripe/stripeSlice";
import { BusinessType } from "../stripe/stripeAPI";
import { BusinessTypeForDB } from "./registerAPI";
import { BusinessTypePage } from "./registerPages/businessTypePage";
import { AddressInput, AddressPage } from "./registerPages/addressPage";
import {
  PersonalDataInput,
  PersonalDataPage,
} from "./registerPages/personalDataPage";
import {
  ContactDetailsInput,
  ContactDetailsPage,
} from "./registerPages/contactDetailsPage";
import { PasswordInput, PasswordPage } from "./registerPages/passwordPage";

export const Register = () => {
  const status = useAppSelector(selectStatus);
  const manager = useAppSelector(selectManager);
  const stripeToken = useAppSelector(selectStripeToken);
  const [submitForm, setSubmitForm] = useState(false);
  const [businessType, setBusinessType] = useState<BusinessType | undefined>(
    undefined,
  );
  const [isValidPersonalDataForm, setIsValidPersonalDataForm] = useState(false);
  const [isValidAddressForm, setIsValidAddressForm] = useState(false);
  const [isValidContactDetailsForm, setIsValidContactDetailsForm] =
    useState(false);
  const [isValidPasswordForm, setIsValidPasswordForm] = useState(false);
  const [route, setRoute] = useState<string>("businessType");
  const [isToaster, setIsToaster] = useState(false);
  const [personalDataInput, setPersonalDataInput] = useState<PersonalDataInput>(
    {
      firstName: "",
      lastName: "",
      job: "",
    },
  );

  const [addressInput, setAddressInput] = useState<AddressInput>({
    company: "",
    identificationNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postalCode: "",
    country: "FR",
  });

  const [contactDetailsInput, setContactDetailsInput] =
    useState<ContactDetailsInput>({
      email: "",
      phone: "",
    });
  const [passwordInput, setPasswordInput] = useState<PasswordInput>({
    password: "",
    passwordDoubleCheck: "",
  });

  const isTokenDecoded = useAppSelector(selectIsTokenDecoded);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !stripeToken &&
      isValidPersonalDataForm &&
      isValidAddressForm &&
      isValidContactDetailsForm &&
      isValidPasswordForm &&
      route === "submit" &&
      submitForm
    ) {
      const stripeTokenCreationData = {
        business_type: businessType as BusinessType,
        company: {
          name: addressInput.company,
          address: {
            line1: addressInput.addressLine1 + addressInput.addressLine2,
            city: addressInput.city,
            postal_code: addressInput.postalCode,
          },
          tax_id: addressInput.identificationNumber,
        },
        tos_shown_and_accepted: true,
      };
      void dispatch(stripeTokenCreationAsync(stripeTokenCreationData));
    }
  }, [
    dispatch,
    personalDataInput,
    contactDetailsInput,
    passwordInput,
    isValidPersonalDataForm,
    isValidContactDetailsForm,
    isValidPasswordForm,
    submitForm,
    addressInput,
    businessType,
    isValidAddressForm,
    route,
    stripeToken,
  ]);

  useEffect(() => {
    if (stripeToken !== "" && submitForm) {
      const registerData = {
        companyName: addressInput.company,
        address: { ...addressInput },
        firstName: capitalize(personalDataInput.firstName),
        lastName: capitalize(personalDataInput.lastName),
        ...contactDetailsInput,
        ...passwordInput,
        phone: addPhoneAreaCode(contactDetailsInput.phone),
        identificationNumber: addressInput.identificationNumber,
        token: stripeToken,
        businessType: businessType?.toUpperCase() as BusinessTypeForDB,
        job: personalDataInput.job,
      };
      void dispatch(registerAsync(registerData));
    }
  }, [
    stripeToken,
    addressInput,
    contactDetailsInput,
    dispatch,
    passwordInput,
    personalDataInput,
    submitForm,
    route,
    businessType,
  ]);

  useEffect(() => {
    Object.values(personalDataInput).some((input) => input === "")
      ? setIsValidPersonalDataForm(false)
      : setIsValidPersonalDataForm(true);
    Object.entries(addressInput).some(
      ([key, value]) => key !== "addressLine2" && value === "",
    )
      ? setIsValidAddressForm(false)
      : setIsValidAddressForm(true);
    Object.values(contactDetailsInput).some((input) => input === "")
      ? setIsValidContactDetailsForm(false)
      : setIsValidContactDetailsForm(true);
    Object.values(passwordInput).some((input) => {
      return input === "" || !hasPasswordFormat(input);
    }) || passwordInput.password !== passwordInput.passwordDoubleCheck
      ? setIsValidPasswordForm(false)
      : setIsValidPasswordForm(true);
  }, [
    personalDataInput,
    addressInput,
    contactDetailsInput,
    passwordInput,
    route,
  ]);

  useEffect(() => {
    if (route === "qiviaWebSite") {
      window.location.href = "https://www.qivia.fr/";
    }
  }, [route]);

  useEffect(() => {
    if (status === "failed" && route === "submit") {
      setRoute("password");
      setIsToaster(true);
    }
  }, [status, route]);

  const nextPage = useCallback((route: string) => {
    setRoute(route);
    if (route === "submit") setSubmitForm(true);
  }, []);

  const onClickBusinessType = useCallback(
    (type: BusinessType) => {
      setBusinessType(type);
      nextPage("address");
    },
    [nextPage],
  );

  const previousPage = useCallback((route: string) => {
    setSubmitForm(false);
    setRoute(route);
    setIsToaster(false);
  }, []);

  if (status === "success" || manager) return <Navigate to="/home" />;

  return (
    <>
      {isTokenDecoded && (
        <>
          {route === "businessType" ? (
            <BusinessTypePage
              nextPage={onClickBusinessType}
              previousPage={() => previousPage("qiviaWebSite")}
            />
          ) : route === "address" ? (
            <AddressPage
              nextPage={() => nextPage("personalData")}
              previousPage={() => previousPage("businessType")}
              businessType={businessType}
              addressInput={addressInput}
              onChange={(e) => setAddressInput(e)}
            />
          ) : route === "personalData" ? (
            <PersonalDataPage
              nextPage={() => nextPage("contactDetails")}
              previousPage={() => previousPage("address")}
              values={personalDataInput}
              isValidForm={isValidPersonalDataForm}
              onChange={(e) => setPersonalDataInput(e)}
            />
          ) : route === "contactDetails" ? (
            <ContactDetailsPage
              nextPage={() => nextPage("password")}
              previousPage={() => previousPage("personalData")}
              values={contactDetailsInput}
              isValidForm={isValidContactDetailsForm}
              onChange={(e) =>
                setContactDetailsInput({
                  phone: phoneNumberFormatter(e.phone),
                  email: e.email,
                })
              }
            />
          ) : route === "password" ? (
            <PasswordPage
              nextPage={() => nextPage("submit")}
              previousPage={() => previousPage("contactDetails")}
              values={passwordInput}
              isValidForm={isValidPasswordForm}
              isToaster={isToaster}
              onChange={(e) => setPasswordInput(e)}
            />
          ) : (
            <LaunchPage />
          )}
        </>
      )}
    </>
  );
};
