import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  billingsSlice,
  selectDeleteRecipientEmailStatus,
  selectUpdateCompanyStatus,
} from "./billingsSlice";
import { useEffect, useState } from "react";
import {
  Spacer,
  PageDS,
  triggerToast,
  ToastContainer,
  TabMenu,
} from "@qivia/ui";
import { loadCompanyAsync, selectCompany } from "./../homeSlice";
import { EmailsFrame, StatementTab } from "./../billings/statementsTab";
import { useNavigate, useParams } from "react-router-dom";
import { InvoiceTab } from "./invoicesTab";
import { AccountTab } from "./accountTab";
import { BankStatementTab } from "./bankStatementsTab";

export const Billings = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState<string>(
    params.tab ?? "account",
  );
  const company = useAppSelector(selectCompany);
  const updateCompanyStatus = useAppSelector(selectUpdateCompanyStatus);
  const deleteRecipientEmailStatus = useAppSelector(
    selectDeleteRecipientEmailStatus,
  );

  const tabs = [
    {
      name: "account",
      title: t("billings.tab.account"),
    },
    {
      name: "invoices",
      title: t("billings.tab.invoices"),
    },
    {
      name: "statements",
      title: t("billings.tab.statements"),
    },
    {
      name: "bankStatements",
      title: t("billings.tab.bankStatements"),
    },
  ];

  useEffect(() => {
    if (selectedTab) {
      navigate(`/home/billings/${selectedTab}`);
    }
  }, [navigate, selectedTab]);

  useEffect(() => {
    if (updateCompanyStatus === "success") {
      triggerToast(t("billings.emailsFrame.save.success") || "", "valid");
      if (company) {
        void dispatch(loadCompanyAsync({ uuid: company.uuid }));
        dispatch(billingsSlice.actions.resetUpdateCompanyStatus());
      }
    } else if (updateCompanyStatus === "failed") {
      triggerToast(t("billings.emailsFrame.save.error") || "", "error");
    }
  }, [company, dispatch, t, updateCompanyStatus]);

  useEffect(() => {
    if (deleteRecipientEmailStatus === "success") {
      triggerToast(t("billings.emailsFrame.delete.success") || "", "valid");
      if (company) {
        void dispatch(loadCompanyAsync({ uuid: company.uuid }));
        dispatch(billingsSlice.actions.resetDeleteRecipientEmailStatus());
      }
    } else if (deleteRecipientEmailStatus === "failed") {
      triggerToast(t("billings.emailsFrame.delete.error") || "", "error");
    }
  }, [company, deleteRecipientEmailStatus, dispatch, t]);

  if (!company) {
    return;
  }

  return (
    <PageDS
      title={t("billings.title")}
      toaster={<ToastContainer />}
      topElement={
        <>
          <Spacer y={1} />
          <TabMenu
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <Spacer y={0.5} />
          {selectedTab === "statements" && ( //pour l'instant, car plus tard on laffiche dans les 2 cas
            <>
              <EmailsFrame company={company} />
              <Spacer y={2} />
            </>
          )}
        </>
      }
    >
      <StatementTab />
      <InvoiceTab />
      <AccountTab />
      <BankStatementTab />
    </PageDS>
  );
};
