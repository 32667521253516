import axios from "axios";
import { axiosInstanceNoAuth } from "../../utils";

export interface LoginAPI {
  email: string;
  password: string;
}

export interface PasswordForgottenAPI {
  email: string;
}

export interface PasswordResetAPI {
  password: string;
  requestId: string;
}

export interface PasswordCreationAPI {
  password: string;
  managerId: string;
}

export type LastCreatedPasswordRequest = {
  lastCreatedPasswordRequest: {
    expirationDate: string;
    canceledAt: string | null;
    registeredAt: string | null;
  } | null;
};

export const loginCall = async (payload: LoginAPI) => {
  const BACKEND_URL = import.meta.env["VITE_BACKEND_URL"] as string;
  return await axios.post<{ token: string }>(
    `${BACKEND_URL}/office/login`,
    payload,
  );
};

export const loginPasswordForgottenCall = async (
  payload: PasswordForgottenAPI,
) => {
  const axios = axiosInstanceNoAuth();
  return await axios.post(`/login_password_forgotten`, payload);
};

export const loginPasswordResetCall = async (payload: PasswordResetAPI) => {
  const axios = axiosInstanceNoAuth();
  return await axios.post(`/login_password_reset`, payload);
};
