import { TagOrBadge } from "./TagOrBadge";

type TagProps = {
  backgroundColor?: string;
  textColor?: string;
  text: string;
  iconLeft?: JSX.Element;
  iconColor?: string;
  borderColor?: string;
  colorHover?: string;
};

export const Tag = (props: TagProps) => {
  return (
    <TagOrBadge
      type={"TAG"}
      text={props.text}
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
      iconLeft={props.iconLeft}
      iconColor={props.iconColor}
      borderColor={props.borderColor}
      colorHover={props.colorHover}
    />
  );
};
