import { Avatar, ButtonDS, DialogModalDS } from "@qivia/ui";
import { Spacer } from "@qivia/ui/src/designSystem/components/Spacer";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { useNavigate } from "react-router-dom";
import { selectCompany } from "../homeSlice";
import { deactivateManagerAsync } from "./usersSlice";
import { ManagerDataDisplayRow } from "./usersAPI";

export const DeactivateUserModal = (props: {
  isVisible: boolean;
  onCloseModal: () => void;
  manager: ManagerDataDisplayRow;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const company = useAppSelector(selectCompany);

  const deactivateManager = useCallback(() => {
    if (!company || !props.manager.uuid) return;
    void dispatch(
      deactivateManagerAsync({
        managerUuid: props.manager.uuid,
      }),
    );
    props.onCloseModal();
    navigate(`/home/users/management`);
  }, [dispatch, navigate, props, company]);

  return (
    <DialogModalDS
      visible={props.isVisible}
      onClose={() => {
        props.onCloseModal();
      }}
      title={t("users.management.deactivate.modal.title")}
    >
      <StyledModalContainer>
        <Spacer y={1} />
        <StyledName>
          <Avatar
            size={"M"}
            color={"mint"}
            firstName={props.manager["firstName"]}
            lastName={props.manager["lastName"]}
          />
          <Spacer x={0.5} />
          {props.manager.name}
        </StyledName>
        <Spacer y={3} />
        <StyledButtons>
          <ButtonDS
            text={t("users.management.deactivate.modal.button.cancel")}
            onClick={() => {
              props.onCloseModal();
            }}
            buttonType={"secondary"}
            format={"fill"}
          />
          <Spacer x={1.5} />
          <ButtonDS
            text={t("users.management.deactivate.modal.button.confirm")}
            onClick={deactivateManager}
            buttonType={"primary"}
            format={"fill"}
          />
        </StyledButtons>
      </StyledModalContainer>
    </DialogModalDS>
  );
};

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  ${typographies["Body/S"]}
  color: ${colors["colors/text/lightGrey"]};
`;

const StyledButtons = styled.div`
  display: flex;
  width: 100%;
`;

const StyledName = styled.div`
  display: flex;
  align-items: center;
  ${typographies["Body/XL"]};
  color: ${colors["colors/text/darkGrey"]};
`;
