import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { FormEvent } from "react";
import { Spacer } from "./Spacer";
import { filteredValuesToTranslate } from "../../utils";
import { capitalize } from "../../format";
import { SearchOutlined } from "../materialUi/materialUi";
import { typographies } from "../../styles/figmaTypographies";

export interface SearchBarDSProps<T extends object> {
  placeholder?: string;
  setFilterValues: (filteredValues: T[]) => void;
  values: T[];
  keysToTranslate?: Array<keyof T>;
  findKeysToTranslate?: (key: keyof T, value: string) => string;
  keysToIgnore?: Array<keyof T>;
}

export const SearchBarDS = <T extends object>(props: SearchBarDSProps<T>) => {
  const change = (e: FormEvent<HTMLInputElement>) => {
    const text = (e.target as HTMLInputElement).value;
    const filteredValues = filteredValuesToTranslate(
      props.values,
      text,
      props.keysToTranslate,
      props.findKeysToTranslate,
      props.keysToIgnore,
    );
    props.setFilterValues(text ? filteredValues : props.values);
  };
  return (
    <StyledSearchBarContainer>
      <Spacer y={0.75} />
      <StyledSearchBarRow>
        <Spacer x={1} />
        <StyledIcon>
          <SearchOutlined />
        </StyledIcon>
        <Spacer x={0.5} />
        <StyledSearchBar
          placeholder={props.placeholder && capitalize(props.placeholder)}
          onChange={change}
        />
        <Spacer x={1} />
      </StyledSearchBarRow>
      <Spacer y={0.75} />
    </StyledSearchBarContainer>
  );
};

const StyledSearchBarContainer = styled.div`
  display: flex;
  width: 19.8rem;
  height: 2.65rem;
  font-size: 1rem;
  background-color: ${colors["colors/button/secondary/default"]};
  border: 0.0625rem solid ${colors["colors/borders/button/primary"]};
  border-radius: 0.5rem;
  flex-direction: column;
`;

const StyledSearchBar = styled.input`
  ${typographies["Body/M"]};
  color: ${colors["colors/text/black"]};
  width: 100%;
  border: 0rem;
  &:focus {
    outline: none;
  }
`;

const StyledSearchBarRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 1.1rem;
  align-items: center;
`;

const StyledIcon = styled.div`
  display: flex;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
