import { RuleFormData } from "./rulesAPI";
import { AddRuleModal } from "./rulesModals";
import { defaultValuesRules } from "./libDefaultDatas";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { rulesAsync, selectRulesList } from "./rulesSlice";
import { useNavigate, useParams } from "react-router-dom";
import { selectCompany } from "../homeSlice";

export const RuleCreation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const rulesList = useAppSelector(selectRulesList);
  const company = useAppSelector(selectCompany);
  const routerParams = useParams();
  const origin = routerParams.origin;

  const onSubmit = useCallback(
    (data: RuleFormData) => {
      if (!company) {
        return;
      }
      void dispatch(rulesAsync({ ...data, companyUuid: company.uuid })).then(
        () => {
          navigate(
            origin === "dashboard"
              ? `/home`
              : `/home/rules/${data.name.replaceAll("/", "_")}`,
          );
        },
      );
    },
    [company, dispatch, navigate, origin],
  );

  const onModalClosed = useCallback(() => {
    navigate(origin === "dashboard" ? `/home` : "/home/rules");
  }, [navigate, origin]);

  return (
    <AddRuleModal
      onCloseModal={onModalClosed}
      onSubmit={onSubmit}
      values={defaultValuesRules()}
      rulesList={rulesList}
    />
  );
};
