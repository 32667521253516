import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Title, Spacer, Assets } from "@qivia/ui";

export const Thanks = () => {
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <StyledInputContainer>
        <Assets.QiviaLogoSquareShadow/>
        <Title
          text={t("thanks.title")}
          size="xlarge"
          withoutMarginBottom
          withoutMarginTop
        />
        <Spacer y={1.5} />
        <StyledText>{t("thanks.subtitle")}</StyledText>
      </StyledInputContainer>
    </StyledContainer>
  );
};

const StyledText = styled.div`
  text-align: center;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledInputContainer = styled.div`
  width: 31.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`;
