import {
  Assets,
  Button,
  InitialTitleProps,
  InputProps,
  Spacer,
  Title,
  colors,
} from "@qivia/ui";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const TitleForm = (props: InitialTitleProps) => {
  const { t } = useTranslation();
  return (
    <Title
      text={t(props.text) || ""}
      size={"large"}
      withoutMarginBottom={props.withoutMarginBottom}
      withoutMarginTop={props.withoutMarginTop}
    />
  );
};

export interface CtaProps {
  onClick: () => void;
}

export const ButtonBack = (props: CtaProps) => {
  const { t } = useTranslation();
  return (
    <StyledBackgroundButton>
      <Button
        title={t(`signIn.cta.back`) || ""}
        LeftElement={<Assets.LeftArrowSolid />}
        withoutBackground={true}
        onClick={() => props.onClick()}
        underline
        noColorHover
      />
    </StyledBackgroundButton>
  );
};

type FormSignInProps<Key extends string> = {
  fields: {
    [label in Key]: {
      error: (v: string) => string | null;
      isPassword: boolean;
      element: (props: InputProps) => JSX.Element;
    };
  };
  pageName: string;
  privatePolicy?: string;
  onClickNext: () => void;
  onClickBack: () => void;
  onChange: (vals: Record<Key, string>) => void;
  isValidForm: boolean;
  values: Record<Key, string>;
  extraContent?: JSX.Element;
};

export const FormSignIn = <Key extends string>(props: FormSignInProps<Key>) => {
  const keys = Object.keys(props.fields) as Key[];
  const fields = props.fields;
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<Key, string | null>>(
    {} as Record<Key, string | null>,
  );

  const [isFirstOnClickNextDone, setIsFirstOnClickNextDone] =
    useState<boolean>(false);

  const hasError = (key: Key) => {
    const errorText = fields[key].error(props.values[key]);
    return errorText !== null;
  };

  const validateFields = (values: Record<Key, string>) => {
    const newErrors: Record<Key, string | null> = {} as Record<Key, string>;
    keys.forEach((key) => {
      const errorText = fields[key].error(values[key]);
      newErrors[key] = errorText || null;
    });
    return newErrors;
  };

  const isNotOk =
    Object.values(errors).filter((e) => e !== null).length !== 0 ||
    keys.findIndex((key) => hasError(key)) >= 0 ||
    !props.isValidForm;

  return (
    <div>
      {keys.map((key: Key) =>
        fields[key].element({
          key: key,
          name: key,
          type: fields[key].isPassword ? "password" : "text",
          label: t(key) || "",
          placeholder:
            t(`signIn.${props.pageName}.input.${key}.placeholder`) || "",
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const errorText = fields[key].error(e.target.value);
            setErrors({ ...errors, [key]: errorText });
            const newValues = { ...props.values, [key]: e.target.value };
            props.onChange(newValues);
          },
          error: (isFirstOnClickNextDone && errors[key]) || undefined,
          value: props.values[key],
        }),
      )}
      {props.extraContent}
      <Spacer y={2} />
      <StyledBackgroundButton>
        <Button
          title={t(`signIn.${props.pageName}.cta.continue`) || ""}
          type="submit"
          disabled={isNotOk}
          onClick={() => {
            if (
              keys.includes("email" as Key) ||
              keys.includes("phone" as Key)
            ) {
              const newErrors = validateFields(props.values);
              setErrors(newErrors);
            }
            if (isNotOk) setIsFirstOnClickNextDone(true);
            else {
              setIsFirstOnClickNextDone(false);
              props.onClickNext();
            }
          }}
        ></Button>
      </StyledBackgroundButton>
      {props.privatePolicy && (
        <>
          <Spacer y={1} />
          <StyledPrivatePolicy>{props.privatePolicy}</StyledPrivatePolicy>
          <Spacer y={1.25} />
        </>
      )}
      <ButtonBack
        onClick={() => {
          setErrors({} as Record<Key, string>);
          props.onClickBack();
        }}
      />
    </div>
  );
};

const StyledPrivatePolicy = styled.div`
  font-weight: 400;
  text-align: justify;
  color: ${colors.black};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledBackgroundButton = styled.div`
  display: flex;
  border: solid 0.25rem transparent;
`;
