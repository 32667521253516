import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import registerReducer from "../routes/register/registerSlice";
import loginReducer from "../routes/login/loginSlice";
import homeReducer from "../routes/home/homeSlice";
import vehiclesReducer from "../routes/home/vehicles/vehiclesSlice";
import cardsReducer from "../routes/home/cards/cardsSlice";
import rulesReducer from "../routes/home/rules/rulesSlice";
import usersReducer from "../routes/home/users/usersSlice";
import driversReducer from "../routes/home/drivers/driversSlice";
import transactionsReducer from "../routes/home/transactions/transactionsSlice";
import billingsReducer from "../routes/home/billings/billingsSlice";
import stripeReducer from "../routes/stripe/stripeSlice";
import companiesSlice from "../routes/home/companies/companiesSlice";

export const store = configureStore({
  reducer: {
    register: registerReducer,
    login: loginReducer,
    home: homeReducer,
    vehicles: vehiclesReducer,
    cards: cardsReducer,
    rules: rulesReducer,
    users: usersReducer,
    drivers: driversReducer,
    transactions: transactionsReducer,
    billings: billingsReducer,
    stripe: stripeReducer,
    companies: companiesSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
