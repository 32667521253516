import { Spacer } from "@qivia/ui";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";

export const RuleTemplate = (props: { children: JSX.Element | string }) => {
  return (
    <StyledRuleTemplate>
      <Spacer y={1.5} />
      <StyledBody>
        <Spacer x={1.5} />
        {props.children}
        <Spacer x={1.5} />
      </StyledBody>
      <Spacer y={1.5} />
    </StyledRuleTemplate>
  );
};

const StyledRuleTemplate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 0.0625rem solid ${colors["colors/borders/cards/cards"]};
  width: 100%;
`;

const StyledBody = styled.div`
  color: ${colors["colors/text/black"]};
  ${typographies["Body/M"]}
  display: flex;
  width: 100%;
  align-items: center;
`;
