import styled from "styled-components";
import { colors, Spacer, Button, Assets, TextCapitalized } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = (props: { errorCode?: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledNotFoundPage>
      <Spacer x={11.5} />
      <StyledLeft>
        <StyledHeader>
          <StyledFlex>
            <Spacer y={2.5} />
            <Assets.QiviaLogo />
          </StyledFlex>
        </StyledHeader>
        <StyledContent>
          <StyledTitle>{t("notFoundPage.title")}</StyledTitle>
          <Spacer y={1} />
          <TextCapitalized>{t("notFoundPage.body.content")}</TextCapitalized>
          {props.errorCode && (
            <>
              <Spacer y={1} />
              <StyledErrorCode>
                {t("notFoundPage.body.errorCode")} {props.errorCode}
              </StyledErrorCode>
            </>
          )}
          <Spacer y={4} />
          <Button
            title={t("notFoundPage.button")}
            onClick={() => navigate("/home")}
            width={10.5}
          />
        </StyledContent>
        <Spacer y={10} />
      </StyledLeft>
      <StyledRight>
        <Assets.SearchLogo />
      </StyledRight>
    </StyledNotFoundPage>
  );
};
const StyledHeader = styled.div`
  height: 10rem;
  width: 50%;
`;

const StyledLeft = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  flex-direction: column;
`;

const StyledRight = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 27rem;
  height: 100%;
`;

const StyledTitle = styled.div`
  font-size: 6rem;
  font-weight: 700;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledErrorCode = styled.div`
  font-size: 1rem;
  font-weight: 500;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledNotFoundPage = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  background-color: ${colors.white};
  justify-content: center;
`;
