import { MutableRefObject } from "react";
import { Spacer, TextCapitalized } from "@qivia/ui";
import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";

export const CategorieRuleTemplate = (props: {
  title: string;
  children: JSX.Element | string;
  titleRef?: MutableRefObject<HTMLDivElement | null>;
}) => (
  <>
    <StyledTitle ref={props.titleRef}>
      <TextCapitalized>{props.title}</TextCapitalized>
    </StyledTitle>
    <Spacer y={1} />
    {props.children}
    <Spacer y={2} />
  </>
);

const StyledTitle = styled.div`
  color: ${colors["colors/text/black"]};
  ${typographies["Header/H3"]}
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
