import {
  ToastContainer,
  triggerToast,
  Spacer,
  LaunchPage,
  sortByDateTime,
  TableDS,
  Tag,
  PageDS,
  Avatar,
  EmptyTableComponent,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { unreachable } from "../../../utils";
import {
  driversListAsync,
  selectDriversList,
  selectDriversListStatus,
  driversSlice,
  selectDriverUpdatedStatus,
} from "./driversSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import {
  GroupsOutlined,
  PersonOutlined,
  NoTransferOutlined,
} from "@qivia/ui/src/designSystem/materialUi/materialUi";
import {
  DriversListDisplayedRow,
  DriversListDisplayedType,
  DriversListType,
} from "./driversAPI";
import { useNavigate, useParams } from "react-router-dom";
import { DriversSidePanel } from "./SidePanel";
import { DriverAccessRequestSummary } from "./driversAccessRequestSummary";
import { selectCompany } from "../homeSlice";

export const Drivers = () => {
  const { t } = useTranslation();
  const params = useParams<{ id?: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const driversListInit = useAppSelector(selectDriversList);
  const driversListStatus = useAppSelector(selectDriversListStatus);
  const driverUpdatedStatus = useAppSelector(selectDriverUpdatedStatus);
  const [rowHoverUuid, setRowHoverUuid] = useState<string | null>(null);
  const company = useAppSelector(selectCompany);

  const [listDisplayed, setListDisplayed] = useState<
    DriversListDisplayedType[] | null
  >(null);

  const [driver, setDriver] = useState<DriversListType | undefined>();
  const routerParams = useParams();

  const driversList: DriversListType[] = useMemo(() => {
    return sortByDateTime(driversListInit, "desc");
  }, [driversListInit]);

  const findKeysToTranslate = (key: keyof DriversListType, value: string) => {
    switch (key) {
      case "vehicleType":
        return `drivers.vehicle.${value}`;
      case "status":
        return `drivers.status.${value}`;
      default:
        return value;
    }
  };

  const sidePanelAction = useCallback(
    (props: Partial<DriversListType>) => {
      navigate(`/home/drivers/${props.uuid}`);
    },
    [navigate],
  );

  useEffect(() => {
    void dispatch(driversSlice.actions.resetDriversAccessRequestsListStatus());
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      const driverSelected = driversList.find((d) => d.uuid === params.id);
      if (driverSelected) {
        navigate(`/home/drivers/${driverSelected.uuid}`);
      }
    }
  }, [driversList, navigate, params.id]);

  const setRowHover = useCallback((props: Partial<DriversListType> | null) => {
    setRowHoverUuid(props?.uuid ?? null);
  }, []);

  useEffect(() => {
    if (company) {
      void dispatch(driversListAsync(company.uuid));
    }
  }, [dispatch, driverUpdatedStatus, company]);

  useEffect(() => {
    if (driversList && driversListStatus === "success") {
      const driversDateTimeSorted = sortByDateTime(driversList, "desc");
      setListDisplayed(driversDateTimeSorted);
      void dispatch(driversSlice.actions.resetDriversListStatus());
    }
  }, [dispatch, driversList, driversListStatus]);

  useEffect(() => {
    if (!driver) return;
    if (driverUpdatedStatus === "success") {
      triggerToast(
        t(`drivers.updatedStatus.${driver.status.toLowerCase()}.success`) || "",
        "valid",
      );
    } else if (driverUpdatedStatus === "failed") {
      triggerToast(t("drivers.updatedStatus.failure") || "", "error");
    }
  }, [driver, driverUpdatedStatus, t]);

  useEffect(() => {
    if (listDisplayed) {
      const driverFound = driversList.find(
        (driver) => driver.uuid === routerParams.id,
      );
      setDriver(driverFound);
      if (!driverFound && routerParams.id) {
        triggerToast(t("drivers.notFound.error") || "", "error");
        return;
      }
    }
  }, [driversList, listDisplayed, routerParams, t]);

  const searchBarProps = {
    values: driversList,
    setFilterValues: setListDisplayed,
    keysToTranslate: ["vehicleType" as const, "status" as const],
    findKeysToTranslate,
    keysToIgnore: ["uuid" as const, "date" as const],
  };

  // const menuOptions = [
  //   {
  //     action: sidePanelAction,
  //     label: `${t("drivers.menuOptions.details")}`,
  //     icon: <RemoveRedEyeOutlined />,
  //   },
  //   {
  //     action: (props: Partial<DriversListType>) => {
  //       props.status !== "BLOCKED"
  //         ? props.uuid && updateDriverStatus(true, props.uuid)
  //         : triggerToast(
  //             t("drivers.updatedStatus.alreadyBlocked") || "",
  //             "error",
  //           );
  //       return;
  //     },
  //     label: `${t("drivers.menuOptions.blocked")}`,
  //     icon: <LockOutlined />,
  //   },
  // ];

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  const render =
    (row: DriversListDisplayedRow) => (key: keyof DriversListDisplayedType) => {
      switch (key) {
        case "name": {
          const initials = row[key].split(" ").map((row) => row.charAt(0));
          return (
            <StyledCircle>
              <Avatar
                color={"mint"}
                size={"S"}
                firstName={initials[0]}
                lastName={initials[initials.length - 1]}
                isHover={rowHoverUuid === row["uuid"]}
              />
              <Spacer x={0.75} />
              {row[key]}
            </StyledCircle>
          );
        }
        case "vehicleType":
          return !row[key] ? (
            ""
          ) : (
            <Tag
              text={t(`drivers.vehicle.${row[key]}`)}
              backgroundColor={colors["colors/button/secondary/hover"]}
              iconLeft={
                row[key] === "PERSONAL" ? (
                  <PersonOutlined />
                ) : row[key] === "OUT_OF_FLEET" ? (
                  <NoTransferOutlined />
                ) : (
                  <GroupsOutlined />
                )
              }
              colorHover={
                rowHoverUuid === row["uuid"]
                  ? colors["colors/surfaces/background/background_level0"]
                  : undefined
              }
            />
          );
        case "status":
          return row[key] === "ACTIVE" ? (
            <Tag
              text={t(`drivers.status.${row[key]}`)}
              backgroundColor={
                colors["colors/system/success/success_ultraLight"]
              }
              textColor={colors["colors/system/success/success_normal"]}
            />
          ) : (
            <Tag
              text={t(`drivers.status.${row[key]}`)}
              backgroundColor={
                colors["colors/surfaces/background/background_level2"]
              }
              textColor={colors["colors/text/black"]}
            />
          );
        case "registrationNumber":
          return !row[key] ? "-" : row[key];
        case "vehicleReference":
          return row[key].toLocaleUpperCase();
      }
      unreachable(key);
    };

  const headers = {
    name: {
      text: t("drivers.page.column.name"),
    },
    vehicleType: {
      text: t("drivers.page.column.vehicleType"),
    },
    registrationNumber: {
      text: t("drivers.page.column.registrationNumber"),
    },
    vehicleReference: {
      text: t("drivers.page.column.vehicleReference"),
    },
    status: {
      text: t("drivers.page.column.status"),
    },
  };

  return (
    <PageDS
      title={t("drivers.title")}
      isEmptyTable={listDisplayed.length === 0}
      toaster={<ToastContainer />}
      searchBar={searchBarProps}
      topElement={<DriverAccessRequestSummary />}
    >
      <TableDS<keyof DriversListDisplayedType, DriversListDisplayedType>
        data={listDisplayed}
        headers={headers}
        render={render}
        onClickRow={sidePanelAction}
        // menuOptions={menuOptions}
        setRowHover={setRowHover}
        emptyContent={
          <EmptyTableComponent
            pageName={"drivers"}
            isSearchResult={searchBarProps.values.length > 0}
          />
        }
      />
      <DriversSidePanel />
    </PageDS>
  );
};

const StyledCircle = styled.div`
  display: flex;
  align-items: center;
`;
