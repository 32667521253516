import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  ButtonDS,
  DownloadOutlined,
  EmptyTableComponent,
  LaunchPage,
  SortingDirectionType,
  Spacer,
  TableDS,
  TextCapitalized,
  dateFormatterDayMonthYearLong2Digits,
  sortByDateTime,
  triggerToast,
} from "@qivia/ui";
import { PublicInvoices, PublicInvoicesDisplayed } from "./billingsAPI";
import {
  selectInvoicesList,
  selectInvoicesListStatus,
  invoicesListAsync,
  invoiceExportAsync,
  selectInvoiceExportLink,
  documentDownloadAsync,
  selectInvoiceExportStatus,
} from "./billingsSlice";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { selectCompany } from "../homeSlice";

export const InvoiceTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const company = useAppSelector(selectCompany);

  const [sortingDirection, setSortingDirection] =
    useState<SortingDirectionType>("desc");

  const [invoicesListSorted, setInvoicesListSorted] = useState<
    PublicInvoicesDisplayed[] | null
  >([]);
  const invoicesListStatus = useAppSelector(selectInvoicesListStatus);
  const invoicesListInit: PublicInvoices[] = useAppSelector(selectInvoicesList);
  const invoiceExportLink = useAppSelector(selectInvoiceExportLink);
  const invoicesExportStatus = useAppSelector(selectInvoiceExportStatus);

  const invoicesList: PublicInvoices[] = useMemo(() => {
    return sortByDateTime(invoicesListInit, "desc");
  }, [invoicesListInit]);

  useEffect(() => {
    if (company) {
      void dispatch(invoicesListAsync(company.uuid));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (invoicesExportStatus === "success") {
      triggerToast(t("billings.invoices.upload.success") || "", "valid");
    } else if (invoicesExportStatus === "failed") {
      triggerToast(t("billings.invoices.upload.failure") || "", "error");
    }
  }, [invoicesExportStatus, t]);

  useEffect(() => {
    if (invoicesList && invoicesListStatus === "success") {
      const invoicesListSorted = sortByDateTime(invoicesList, "desc");
      setInvoicesListSorted(invoicesListSorted);
    }
  }, [invoicesList, invoicesListStatus]);

  useEffect(() => {
    if (invoiceExportLink.presignedUrl && invoiceExportLink.fileName) {
      void dispatch(documentDownloadAsync(invoiceExportLink));
    }
  }, [invoiceExportLink, dispatch]);

  const sortByBillingsDate = useCallback(() => {
    if (!invoicesListSorted) return;
    const invoicesDateTimeSorted = sortByDateTime(
      invoicesListSorted,
      sortingDirection,
    );
    setInvoicesListSorted(invoicesDateTimeSorted);
    setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
  }, [invoicesListSorted, sortingDirection]);

  const headers = {
    totalAmount: {
      text: t("billings.invoices.table.column.totalAmount"),
    },
    fileName: {
      text: t("billings.invoices.table.column.fileName"),
    },
    date: {
      text: t("billings.invoices.table.column.date"),
      sortAction: () => sortByBillingsDate(),
    },
    dueDate: {
      text: t("billings.invoices.table.column.dueDate"),
    },
    bucketFileName: {
      text: t("billings.invoices.table.column.download"),
    },
  };

  if (params.tab !== "invoices") {
    return;
  }
  if (!invoicesListSorted) return <LaunchPage hasBorderRadius={true} />;

  const render =
    (row: PublicInvoicesDisplayed) => (key: keyof PublicInvoicesDisplayed) => {
      switch (key) {
        case "date":
        case "dueDate":
          return (
            <TextCapitalized>
              {dateFormatterDayMonthYearLong2Digits(new Date(row[key]))}
            </TextCapitalized>
          );
        case "totalAmount":
          return (
            <StyledAmount>
              {Number(row[key] / 100).toString() + " € TTC"}
              <Spacer x={0.75} />
            </StyledAmount>
          );
        case "bucketFileName":
          return (
            <ButtonDS
              sizeButton={"S"}
              format={"hug"}
              buttonType={"secondary"}
              singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
              onClick={() => {
                void dispatch(
                  invoiceExportAsync({
                    bucketFileName: row[key] ?? "",
                    fileName: row["fileName"],
                  }),
                );
              }}
            />
          );
        default:
          return row[key];
      }
    };

  return (
    <TableDS<keyof PublicInvoicesDisplayed, PublicInvoicesDisplayed>
      data={invoicesListSorted}
      headers={headers}
      render={render}
      emptyContent={<EmptyTableComponent pageName={"billings.invoices"} />}
    />
  );
};

const StyledAmount = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
