import styled from "styled-components";
import { Spacer, Assets, TextCapitalized } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";

type PageType = "expired" | "canceled" | "alreadyRegistered";

export const PasswordCreationErrorPage = (props: { pageType: PageType }) => {
  const { t } = useTranslation();

  return (
    <StyledNotFoundPage>
      <Spacer x={11.5} />
      <StyledColumn>
        <StyledHeader>
          <StyledFlex>
            <Spacer y={2.5} />
            <Assets.QiviaLogo />
          </StyledFlex>
        </StyledHeader>
        <Spacer y={8} />
        <StyledTitle>
          <TextCapitalized>
            {t("passwordCreationError.page.title")}
          </TextCapitalized>
        </StyledTitle>
        <Spacer y={6} />
        <TextCapitalized>
          {t(`passwordCreationError.page.content.${props.pageType}`)}
        </TextCapitalized>
        <Spacer y={1} />
        <StyledSubContent>
          <TextCapitalized>
            {t("passwordCreationError.page.subContent")}
          </TextCapitalized>
        </StyledSubContent>
        <Spacer y={14} />
      </StyledColumn>
    </StyledNotFoundPage>
  );
};
const StyledHeader = styled.div`
  height: 10rem;
  width: 50%;
`;

const StyledColumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 1.75rem;
  flex-direction: column;
`;

const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.div`
  font-size: 6rem;
  font-weight: 700;
`;

const StyledSubContent = styled.div`
  ${typographies["Body/S"]}
`;

const StyledNotFoundPage = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  justify-content: center;
`;
