import {
  triggerToast,
  ButtonDS,
  DownloadOutlined,
  TableDS,
  EmptyTableComponent,
  dateFormatterLongMonthYear,
  TextCapitalized,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { unreachable } from "../../../utils";
import {
  bankStatementExportAsync,
  bankStatementsListAsync,
  documentDownloadAsync,
  selectBankStatementExportLink,
  selectBankStatementExportStatus,
  selectBankStatementsList,
} from "./billingsSlice";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BankStatementsList, BankStatementsListDisplayed } from "./billingsAPI";
import { selectCompany } from "../homeSlice";

export const BankStatementTab = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const params = useParams();
  const company = useAppSelector(selectCompany);

  const bankStatementsList = useAppSelector(selectBankStatementsList);

  const bankStatementsExportStatus = useAppSelector(
    selectBankStatementExportStatus,
  );
  const bankStatementExportLink = useAppSelector(selectBankStatementExportLink);

  useEffect(() => {
    if (company) {
      void dispatch(bankStatementsListAsync(company.uuid));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (bankStatementsExportStatus === "success") {
      triggerToast(t("billings.bankStatement.upload.success") || "", "valid");
    } else if (bankStatementsExportStatus === "failed") {
      triggerToast(t("billings.bankStatement.upload.failure") || "", "error");
    }
  }, [bankStatementsExportStatus, t]);

  useEffect(() => {
    if (
      bankStatementExportLink.presignedUrl &&
      bankStatementExportLink.fileName
    ) {
      void dispatch(documentDownloadAsync(bankStatementExportLink));
    }
  }, [bankStatementExportLink, dispatch]);

  if (params.tab !== "bankStatements") {
    return;
  }

  const headersBankStatementsList = {
    date: {
      text: t("billings.bankStatement.column.date"),
    },
    closingBalance: {
      text: t("billings.bankStatement.column.closingBalance"),
    },
    bucketFileName: {
      text: t("billings.bankStatement.column.download"),
    },
  };

  const renderBankStatementsList =
    (row: BankStatementsList) => (key: keyof BankStatementsListDisplayed) => {
      switch (key) {
        case "closingBalance":
          return `${row[key] === null ? "-" : (row[key] / 100).toString() + " EUR"}`;
        case "bucketFileName":
          return (
            row[key] && (
              <ButtonDS
                sizeButton={"S"}
                format={"hug"}
                buttonType={"secondary"}
                singleIcon={{ icon: <DownloadOutlined />, size: "S" }}
                onClick={() => {
                  void dispatch(
                    bankStatementExportAsync({
                      bucketFileName: row[key] ?? "",
                      fileName: row["fileName"] ?? "",
                    }),
                  );
                }}
              />
            )
          );
        case "date":
          return (
            <TextCapitalized>
              {dateFormatterLongMonthYear(new Date(row[key]))}
            </TextCapitalized>
          );
      }
      unreachable(key);
    };

  return (
    <TableDS<keyof BankStatementsListDisplayed, BankStatementsListDisplayed>
      data={bankStatementsList}
      headers={headersBankStatementsList}
      render={renderBankStatementsList}
      emptyContent={<EmptyTableComponent pageName={"billings.bankStatement"} />}
    />
  );
};
