import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Title, Button, Spacer, Assets } from "@qivia/ui";
import { useNavigate } from "react-router-dom";
import { selectManager, welcomeManagerAsync } from "../home/homeSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";

export const Welcome = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const manager = useAppSelector(selectManager);

  useEffect(() => {
    if (manager?.hasBeenWelcomed) navigate("/home");
  }, [manager?.hasBeenWelcomed, navigate]);

  return (
    <StyledContainer>
      <StyledInputContainer>
        <Assets.QiviaLogoSquareShadow/>
        <Title
          text={t("welcome.title")}
          size="xlarge"
          withoutMarginBottom
          withoutMarginTop
        />
        <Spacer y={1.5} />
        <StyledText>{t("welcome.subtitle")}</StyledText>
        <Spacer y={2.375} />
        <Button
          title={t("welcome.cta")}
          onClick={() => {
            void dispatch(welcomeManagerAsync());
          }}
          width={12.5}
        />
      </StyledInputContainer>
    </StyledContainer>
  );
};

const StyledText = styled.div`
  text-align: center;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledInputContainer = styled.div`
  width: 31.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`;
