import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  ImportMassiveVehiclesResponseType,
  MultipleVehicleCreationAPI,
  VehicleCreationAPI,
  VehiclesAPI,
  VehiclesListType,
} from "./vehiclesAPI";
import { AxiosError } from "axios";
import { UUID } from "@qivia/core";

interface VehiclesUuidsResponseType {
  created?: string[];
  alreadyExisted?: string[];
  companyUuid: string;
  updated?: string[];
}

export interface VehiclesState {
  createVehicleStatus: QueryStatus;
  createMultipleVehiclesStatus: QueryStatus;
  listAllVehiclesInDbStatus: QueryStatus;
  listAllVehiclesInDb: VehiclesListType[];
  errorMessage: string | null;
  errorMessageMassivImport: string | null;
  deactivateVehicleStatus: QueryStatus;
}

const initialState: VehiclesState = {
  createVehicleStatus: "idle",
  createMultipleVehiclesStatus: "idle",
  listAllVehiclesInDbStatus: "idle",
  listAllVehiclesInDb: [],
  errorMessage: "",
  errorMessageMassivImport: "",
  deactivateVehicleStatus: "idle",
};

export const vehicleAsync = createAsyncThunk(
  "vehicles/call",
  async (payload: VehicleCreationAPI, thunkAPI) => {
    const axios = authAxios();
    const response = await axios.post<
      { errorMessage: string } | { uuid: UUID }
    >(`vehicle`, payload);
    if ("uuid" in response.data) {
      await thunkAPI.dispatch(
        listAllVehiclesInDbAsync({
          created: [response.data.uuid],
          companyUuid: payload.companyUuid,
        }),
      );
    }
    return response.data;
  },
);

export const importMassiveVehiclesAsync = createAsyncThunk(
  "vehicles/importMassiveVehicles",
  async (payload: MultipleVehicleCreationAPI, thunkAPI) => {
    const axios = authAxios();
    try {
      const response = await axios.post<
        { errorMessage: string } | ImportMassiveVehiclesResponseType
      >(`vehicles`, payload);
      if ("createdVehiclesUuids" in response.data) {
        await thunkAPI.dispatch(
          listAllVehiclesInDbAsync({
            created: response.data.createdVehiclesUuids,
            alreadyExisted: response.data.alreadyExistedVehiclesUuids,
            companyUuid: payload.companyUuid,
            updated: response.data.updatedVehiclesUuids,
          }),
        );
      }
      return response.data;
    } catch (e) {
      if (
        e instanceof AxiosError &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        e.response?.data?.errorMessage === "No new vehicle to create"
      ) {
        return {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          errorMessage: e.response?.data?.errorMessage as string,
        };
      } else {
        throw e;
      }
    }
  },
);

export const listAllVehiclesInDbAsync = createAsyncThunk(
  "vehiclesList/call",
  async (props: VehiclesUuidsResponseType) => {
    const axios = authAxios();
    const response = await axios.get<Array<VehiclesAPI>>(
      `vehicles/company/${props.companyUuid}`,
    );
    return response.data.map<VehiclesListType>((vehicle) => {
      const isNewVehicle = props.created?.includes(vehicle.uuid);
      const isAlreadyExisted = props.alreadyExisted?.includes(vehicle.uuid);
      const isUpdatedVehicle = props.updated?.includes(vehicle.uuid);
      return {
        ...vehicle,
        isNewVehicle,
        isAlreadyExisted,
        isUpdatedVehicle,
        date: vehicle.createdAt,
        model: vehicle.model ?? "",
        brand: vehicle.brand ?? "",
      };
    });
  },
);

export const deactivateVehicleAsync = createAsyncThunk(
  "deactivateVehicle/call",
  async (payload: { vehicleUuid: string; companyUuid: string }, thunkAPI) => {
    const axios = authAxios();
    await axios.post(`/vehicle/${payload.vehicleUuid}/deactivate`);
    await thunkAPI.dispatch(
      listAllVehiclesInDbAsync({ companyUuid: payload.companyUuid }),
    );
  },
);

export const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetListAllVehiclesInDbStatus(state) {
      state.listAllVehiclesInDbStatus = "idle";
    },
    setSelectedUuid: (state, action: PayloadAction<string | null>) => ({
      ...state,
      selectedVehicleUuid: action.payload,
    }),
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(vehicleAsync.pending, (state) => {
        state.createVehicleStatus = "processing";
      })
      .addCase(vehicleAsync.fulfilled, (state, action) => {
        state.createVehicleStatus = "success";
        if ("errorMessage" in action.payload)
          state.errorMessage = action.payload.errorMessage;
        else state.errorMessage = "";
      })
      .addCase(vehicleAsync.rejected, (state) => {
        state.createVehicleStatus = "failed";
      })
      .addCase(importMassiveVehiclesAsync.pending, (state) => {
        state.createMultipleVehiclesStatus = "processing";
      })
      .addCase(importMassiveVehiclesAsync.fulfilled, (state, action) => {
        state.createMultipleVehiclesStatus = "success";
        if ("errorMessage" in action.payload) {
          state.errorMessageMassivImport = action.payload.errorMessage;
        } else state.errorMessageMassivImport = "";
      })
      .addCase(importMassiveVehiclesAsync.rejected, (state) => {
        state.createMultipleVehiclesStatus = "failed";
      })
      .addCase(listAllVehiclesInDbAsync.pending, (state) => {
        state.listAllVehiclesInDbStatus = "processing";
      })
      .addCase(listAllVehiclesInDbAsync.fulfilled, (state, action) => {
        state.listAllVehiclesInDb = action.payload;
        state.listAllVehiclesInDbStatus = "success";
        state.createVehicleStatus = "idle";
        state.deactivateVehicleStatus = "idle";
      })
      .addCase(listAllVehiclesInDbAsync.rejected, (state) => {
        state.listAllVehiclesInDbStatus = "failed";
      })
      .addCase(deactivateVehicleAsync.pending, (state) => {
        state.deactivateVehicleStatus = "processing";
      })
      .addCase(deactivateVehicleAsync.fulfilled, (state) => {
        state.deactivateVehicleStatus = "success";
      })
      .addCase(deactivateVehicleAsync.rejected, (state) => {
        state.deactivateVehicleStatus = "failed";
      });
  },
});

export const selectVehiclesCreatedStatus = (state: RootState) =>
  state.vehicles.createVehicleStatus;
export const selectMultipleVehiclesCreatedStatus = (state: RootState) =>
  state.vehicles.createMultipleVehiclesStatus;
export const selectListAllVehiclesInDb = (state: RootState) =>
  state.vehicles.listAllVehiclesInDb;
export const selectListAllVehiclesInDbStatus = (state: RootState) =>
  state.vehicles.listAllVehiclesInDbStatus;
export const selectErrorMessage = (state: RootState) =>
  state.vehicles.errorMessage;
export const selectErrorMessageMassivImport = (state: RootState) =>
  state.vehicles.errorMessageMassivImport;
export const selectDeactivateVehicleStatus = (state: RootState) =>
  state.vehicles.deactivateVehicleStatus;

export default vehiclesSlice.reducer;
