import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ButtonDS, InputDS, Spacer, TextCapitalized, Title } from "@qivia/ui";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectManager } from "../home/homeSlice";
import { LoginAPI } from "./loginAPI";
import { loginAsync, selectIsLoginError } from "./loginSlice";
import { Assets } from "@qivia/ui";
import { useCallback, useMemo, useState } from "react";
import { colors } from "@qivia/ui/src/styles/figmaColors";

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [displayError, setDisplayError] = useState<boolean>(false);
  const isLoginError = useAppSelector(selectIsLoginError);
  const manager = useAppSelector(selectManager);

  const onSubmit = useCallback(() => {
    const loginData: LoginAPI = {
      email,
      password,
    };
    void dispatch(loginAsync(loginData));
  }, [dispatch, email, password]);

  const isValidLoginForm: boolean = useMemo(
    () => email !== "" && password !== "",
    [email, password],
  );

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    isValidLoginForm && onSubmit();
  }, [isValidLoginForm, onSubmit]);

  const managerIsActive = useMemo(
    () => manager && manager.status === "ACTIVE",
    [manager],
  );
  const managerIsDeactivated = useMemo(
    () => manager && manager.status === "DEACTIVATED",
    [manager],
  );

  if (managerIsActive) return <Navigate to="/home" />;
  return (
    <StyledContainer>
      <StyledLeft>
        <Spacer y={2.25} />
        <StyledColumn>
          <StyledLogoContainer>
            <Spacer x={2.5} />
            <Assets.QiviaLogo />
          </StyledLogoContainer>
        </StyledColumn>
        <StyledRow>
          <Spacer x={5} />
          <StyledForm>
            <Spacer y={2} />
            <StyledTitle>
              <Title text={t("login.title") || ""} size={"large"} />
            </StyledTitle>
            <Spacer y={1.5} />
            <InputDS
              id={"email"}
              label={t("login.form.label.email") || ""}
              placeholder={t("login.form.placeholder.email") || ""}
              error={
                (displayError &&
                  email === "" &&
                  t("login.form.error.empty.email")) ||
                (displayError &&
                  isLoginError &&
                  t("login.form.error.wrong.data")) ||
                (displayError && managerIsDeactivated && " ") ||
                undefined
              }
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
              isPlaceholderUppercase
            />
            <Spacer y={0.75} />
            <InputDS
              id={"password"}
              label={t("login.form.label.password") || ""}
              placeholder={t("login.form.placeholder.password") || ""}
              error={
                (displayError &&
                  password === "" &&
                  t("login.form.error.empty.password")) ||
                (displayError &&
                  isLoginError &&
                  t("login.form.error.wrong.data")) ||
                (displayError && managerIsDeactivated && " ") ||
                undefined
              }
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
              type="PASSWORD"
              withoutSpacerBottom
            />
            <Spacer y={1} />
            {displayError &&
              managerIsDeactivated &&
              isValidLoginForm &&
              !isLoginError && (
                <StyledErrorMessage>
                  <Spacer y={1} />
                  <TextCapitalized>
                    {t("login.inactiveManager.error")}
                  </TextCapitalized>
                </StyledErrorMessage>
              )}
            <Spacer y={2} />
            <StyledRow>
              <ButtonDS
                id={"loginBtn"}
                text={t("login.button.connexion") || ""}
                onClick={() => clickToSubmit()}
                format={"fill"}
                buttonType={"primary"}
                disabled={!isValidLoginForm}
              />
              <Spacer x={1} />
              <ButtonDS
                text={t("login.button.passwordForgotten") || ""}
                format={"fill"}
                buttonType={"secondary"}
                onClick={() => navigate("/password_forgotten")}
              />
            </StyledRow>
            <Spacer y={6.25} />
          </StyledForm>
          <Spacer x={5} />
        </StyledRow>
        <Spacer y={2} />
      </StyledLeft>
      <StyledRight>
        <Spacer x={5} />
        <Assets.QiviaBlackCard />
        <Spacer x={5} />
      </StyledRight>
    </StyledContainer>
  );
};

const StyledErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors["colors/system/error/error_normal"]};
`;

const StyledTitle = styled.div`
  border: solid 0.25rem transparent;
`;

const StyledColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 2.5rem;
  width: 100%;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  max-width: 12rem;
`;

const StyledLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500%;
  min-height: 100%;
  background-color: white;
`;

const StyledForm = styled.div`
  width: 28rem;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const StyledRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500%;
  min-height: 100%;
  background-color: ${colors["colors/surfaces/background/background_level1"]};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background-color: white;
`;
