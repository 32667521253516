import styled from "styled-components";
import { colors } from "../../styles/figmaColors";
import { Spacer } from "./Spacer";
import { ClearRound } from "../materialUi/materialUi";
import { useOutsideClick } from "../../hooks/useOutsideClick";

export interface ModalDSProps {
  children: string | JSX.Element | JSX.Element[];
  visible: boolean;
  onClose: () => void;
}
export const ModalDS = (props: ModalDSProps) => {
  const outsideSidePanel = useOutsideClick(() => {
    props.onClose && props.onClose();
  });

  return (
    <StyledBackground $visible={props.visible}>
      <StyledContainer ref={outsideSidePanel}>
        <Spacer x={1.5} />
        <StyledColumn>
          <Spacer y={1.5} />
          <StyledModalBar>
            <StyledAroundCloseIcon onClick={props.onClose}>
              <ClearRound />
            </StyledAroundCloseIcon>
          </StyledModalBar>
          <StyledFlex>{props.children}</StyledFlex>
          <Spacer y={1.5} />
        </StyledColumn>
        <Spacer x={1.5} />
      </StyledContainer>
    </StyledBackground>
  );
};

const StyledAroundCloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  &:hover {
    background-color: ${colors["colors/surfaces/background/background_level1"]};
  }
  & svg {
    width: 1.75rem;
    height: 1.75rem;
    fill: ${colors["colors/text/lightGrey"]};
  }
`;
const StyledModalBar = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 1.5rem;
`;
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 25.875rem;
  max-height: 80vh;
  max-width: 80vh;
  border-radius: 0.5rem;
  background-color: ${colors["colors/surfaces/background/background_level0"]};
  border: solid 0.063rem ${colors["colors/borders/cards/cards"]};
  overflow: auto;
  box-shadow:
    0px 2px 5px 0px rgba(143, 143, 143, 0.15),
    0px 0px 15px 0px rgba(143, 143, 143, 0.15);
`;

const StyledColumn = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`;

const StyledBackground = styled.div<{ $visible: boolean }>`
  z-index: 10;
  position: fixed;
  display: ${({ $visible }) => ($visible ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(0.47rem);
  box-shadow:
    0px 0px 15px rgba(143, 143, 143, 0.15),
    0px 2px 5px rgba(143, 143, 143, 0.15);
  overflow: auto;
  background: rgba(150, 150, 150, 0.33);
`;

const StyledFlex = styled.div`
  display: flex;
`;
