export type SortingDirectionType = "asc" | "desc";

type SortByDateType = {
  date: string;
};

export type SortByNumericType = {
  amount: number;
};

export type SortByAlphabeticalOrderType = {
  merchantName: string;
  registrationNumber: string;
  vehicle: string;
  rule: string;
  name: string;
  reference: string;
};

export const sortByDateTime = <T extends SortByDateType>(
  initialPayload: T[],
  sortingDirection: SortingDirectionType,
): T[] => {
  const payloadSorted = [...initialPayload];
  if (sortingDirection === "asc") {
    payloadSorted.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );
  } else {
    payloadSorted.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );
  }
  return payloadSorted;
};

export const sortByNumeric = <T extends Partial<SortByNumericType>>(
  initialPayload: T[],
  sortingDirection: SortingDirectionType,
  column: keyof SortByNumericType,
) => {
  const payloadSorted = [...initialPayload];

  if (sortingDirection === "asc") {
    payloadSorted.sort((a, b) => a[column]! - b[column]!);
  } else {
    payloadSorted.sort((a, b) => b[column]! - a[column]!);
  }
  return payloadSorted;
};

export const sortByAlphabeticalOrder = <
  T extends Partial<SortByAlphabeticalOrderType>,
>(
  initialPayload: T[],
  sortingDirection: SortingDirectionType,
  column: keyof SortByAlphabeticalOrderType,
) => {
  const payloadSorted = [...initialPayload];

  if (sortingDirection === "asc") {
    payloadSorted.sort((a, b) => {
      if (a[column] !== undefined && b[column] !== undefined) {
        return a[column]!.localeCompare(b[column]!);
      } else {
        return 0;
      }
    });
  } else {
    payloadSorted.sort((a, b) => {
      if (a[column] !== undefined && b[column] !== undefined) {
        return b[column]!.localeCompare(a[column]!);
      } else {
        return 0;
      }
    });
  }
  return payloadSorted;
};
