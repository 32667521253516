import { AddressSignInForm, OnboardingPage } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { BusinessType } from "../../stripe/stripeAPI";
import { TitleForm } from "./formSignIn";

export type AddressInput = {
  company: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  country: string;
  identificationNumber: string;
};

export interface AddressPageProps {
  nextPage: () => void;
  previousPage: () => void;
  businessType: BusinessType | undefined;
  addressInput: AddressInput;
  onChange: (value: AddressInput) => void;
}

export const AddressPage = (props: AddressPageProps) => {
  const { t } = useTranslation();
  return (
    <OnboardingPage
      title={
        <TitleForm
          text={
            props.businessType === "company"
              ? t("signIn.address.company.title1")
              : t("signIn.address.nonProfit.title1")
          }
        />
      }
      subtitle={
        props.businessType === "company"
          ? t("signIn.address.company.subtitle")
          : t("signIn.address.nonProfit.subtitle")
      }
      form={
        <AddressSignInForm
          labelCompany={
            props.businessType === "company" ? t("companyName") : t("nonProfit")
          }
          labelIdentificationNumber={t("identificationNumber")}
          labelAddress={t("address")}
          addressInput={props.addressInput}
          onChange={(e) => props.onChange(e)}
          onClickNext={props.nextPage}
          onClickBack={props.previousPage}
        />
      }
    />
  );
};
