import {
  ButtonDS,
  InputDS,
  LaunchPage,
  OnboardingPage,
  PasswordDoubleCheck,
  Spacer,
  Title,
  ToastContainer,
  hasPasswordFormat,
  triggerToast,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  lastCreatedPasswordRequestAsync,
  passwordCreationAsync,
  selectLastCreatedPasswordRequest,
  selectLastCreatedPasswordRequestStatus,
  selectPasswordCreationStatus,
} from "./loginSlice";
import { PasswordCreationErrorPage } from "../../components/PasswordCreationErrorPage";
import { NotFoundPage } from "../../components/NotFoundPage";

type PasswordInput = {
  password: string;
  passwordDoubleCheck: string;
};

export const PasswordCreation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const passwordCreationStatus = useAppSelector(selectPasswordCreationStatus);
  const lastCreatedPasswordRequestResponse = useAppSelector(
    selectLastCreatedPasswordRequest,
  );
  const lastCreatedPasswordRequestStatus = useAppSelector(
    selectLastCreatedPasswordRequestStatus,
  );
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [passwordInput, setPasswordInput] = useState<PasswordInput>({
    password: "",
    passwordDoubleCheck: "",
  });
  const managerId = params.id;

  useEffect(() => {
    if (managerId && lastCreatedPasswordRequestStatus === "idle") {
      void dispatch(
        lastCreatedPasswordRequestAsync({ managerUuid: managerId }),
      );
    }
  }, [dispatch, lastCreatedPasswordRequestStatus, managerId]);

  useEffect(() => {
    if (passwordCreationStatus === "success") {
      setTimeout(() => {
        navigate("/login");
      }, 500);
    } else if (passwordCreationStatus === "failed") {
      triggerToast(t("passwordCreation.error") || "", "error");
    }
  }, [passwordCreationStatus, navigate, t]);

  const submit = useCallback(async () => {
    if (managerId) {
      await dispatch(
        passwordCreationAsync({ password: passwordInput.password, managerId }),
      );
    }
  }, [managerId, dispatch, passwordInput.password]);

  const isSamePassword = useCallback(
    (password: string) => {
      if (password === "" || passwordInput.passwordDoubleCheck === "")
        return false;
      return password === passwordInput.passwordDoubleCheck;
    },
    [passwordInput.passwordDoubleCheck],
  );

  const hasValidFormat = useCallback(() => {
    return (
      hasPasswordFormat(passwordInput.password) &&
      isSamePassword(passwordInput.password)
    );
  }, [isSamePassword, passwordInput.password]);

  if (!managerId) {
    return;
  }
  if (lastCreatedPasswordRequestStatus == "failed") {
    return <NotFoundPage />;
  }
  const request = lastCreatedPasswordRequestResponse.lastCreatedPasswordRequest;
  if (request && request.registeredAt) {
    return <PasswordCreationErrorPage pageType="alreadyRegistered" />;
  } else if (request && request.canceledAt) {
    return <PasswordCreationErrorPage pageType="canceled" />;
  } else if (request && new Date(request.expirationDate) < new Date()) {
    return <PasswordCreationErrorPage pageType="expired" />;
  }

  return (
    <>
      {passwordCreationStatus === "success" || !request ? (
        <LaunchPage />
      ) : (
        <OnboardingPage
          title={
            <Title text={t("passwordCreation.title")} size={"large"}></Title>
          }
          form={
            <div>
              <InputDS
                label={t("passwordCreation.input.title")}
                type="PASSWORD"
                value={passwordInput.password}
                placeholder={t("passwordCreation.input.placeholder")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPasswordInput({
                    ...passwordInput,
                    password: e.target.value,
                  });
                }}
                error={
                  displayError && !hasValidFormat()
                    ? t("passwordCreation.input.error")
                    : undefined
                }
              />
              <InputDS
                label={t("passwordCreation.input.secondTitle")}
                type="PASSWORD"
                value={passwordInput.passwordDoubleCheck}
                placeholder={t("passwordCreation.input.placeholder")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPasswordInput({
                    ...passwordInput,
                    passwordDoubleCheck: e.target.value,
                  });
                }}
                error={
                  displayError && !hasValidFormat()
                    ? t("passwordCreation.input.error")
                    : undefined
                }
              />
              <PasswordDoubleCheck values={passwordInput} />
              <Spacer y={1} />
              <ButtonDS
                format={"fill"}
                buttonType={"primary"}
                text={t(`passwordCreation.button.submit`) || ""}
                onClick={() => {
                  if (hasValidFormat()) {
                    void submit();
                  }
                  setDisplayError(true);
                }}
                disabled={!hasValidFormat()}
              />
            </div>
          }
          toaster={<ToastContainer />}
        />
      )}
    </>
  );
};
