import { FormEvent } from "react";
import { InputDS, InputDSProps } from "./Input";

interface NumericInputProps extends InputDSProps {
  value: number | "";
  update: (e: number | "") => void;
  floatNumber?: boolean;
  maxDigits?: number; //10 by default
}

export const NumericInputDS = (props: NumericInputProps) => {
  const updateValue = (e: FormEvent<HTMLInputElement>) => {
    const regexNumber = props.maxDigits
      ? new RegExp("^([0-9]){0," + props.maxDigits.toString() + "}$")
      : /^([0-9]){0,10}$/;
    const regexFloatNumber = props.maxDigits
      ? new RegExp(
          "^([0-9]{0," + props.maxDigits.toString() + "})([.][0-9]{0,3})?$",
        )
      : /^([0-9]{0,10})([.][0-9]{0,3})?$/;

    const number = (e.target as HTMLInputElement).value;

    if (!number || number === "") {
      return props.update("");
    }
    if (!props.floatNumber && number.match(regexNumber)) {
      return props.update(parseInt(number));
    }
    if (props.floatNumber && number.match(regexFloatNumber)) {
      return props.update(parseFloat(number));
    }
    return props.update(props.value);
  };
  return (
    <InputDS
      onChange={updateValue}
      pattern="[0-9]*"
      type={"NUMBER"}
      {...props}
      onKeyDown={(e) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
      }
    />
  );
};
