import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  CardsAPI,
  CardsListApiType,
  CardsListType,
  MultipleCardsOrderType,
  UpdateStatusType,
  UpdateCardStatusType,
} from "./cardsAPI";

export interface CardsState {
  createCardStatus: QueryStatus;
  cardsListStatus: QueryStatus;
  cardsList: CardsListType[];
  cardUpdatedStatus: QueryStatus;
  orderMultipleCardsStatus: QueryStatus;
  companyAddress: string;
  getAddressStatus: QueryStatus;
  newCardsListOrdered: MultipleCardsOrderType;
  ruleUpdatedStatus: QueryStatus;
  ruleUpdated: UpdateStatusType | null;
}

const initialState: CardsState = {
  createCardStatus: "idle",
  cardsListStatus: "idle",
  cardsList: [],
  cardUpdatedStatus: "idle",
  orderMultipleCardsStatus: "idle",
  companyAddress: "",
  getAddressStatus: "idle",
  newCardsListOrdered: {
    vehicles: [],
    rule: "",
  },
  ruleUpdatedStatus: "idle",
  ruleUpdated: null,
};

export const cardsAsync = createAsyncThunk(
  "cards/call",
  async (payload: CardsAPI) => {
    const axios = authAxios();
    await axios.post(`card`, payload);
  },
);

export const cardsListAsync = createAsyncThunk(
  "cardsList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<CardsListApiType>>(
      `cards/company/${companyUuid}`,
    );
    const cardsList = response.data.map((payload) => ({
      ...payload,
      date: payload.createdAt,
    }));
    return cardsList;
  },
);

export const updateCardStatusAsync = createAsyncThunk(
  "updateCardStatus/call",
  async (payload: UpdateCardStatusType) => {
    const axios = authAxios();
    await axios.post(`/card/${payload.cardUuid}/update_card_status`, {
      status: payload.status,
    });
    return { status: payload.status };
  },
);

export const updateVehicleRuleAsync = createAsyncThunk(
  "updateVehicleRule/call",
  async (payload: { vehicleUuid: string; ruleUuid: string }) => {
    const axios = authAxios();
    await axios.post(`/vehicle/${payload.vehicleUuid}/update_rule`, {
      rule: payload.ruleUuid,
    });
  },
);

export const orderMultipleCardsAsync = createAsyncThunk(
  "orderMultipleCards/call",
  async (payload: MultipleCardsOrderType) => {
    const axios = authAxios();
    await axios.post(`/cards/bulk_order`, payload);
    return payload;
  },
);

export const addressAsync = createAsyncThunk(
  "address/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<{ address: string }>(
      `address/company/${companyUuid}`,
    );
    return response.data.address;
  },
);

export const cardsSlice = createSlice({
  name: "cards",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetCardsListStatus(state) {
      state.cardsListStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(cardsAsync.pending, (state) => {
        state.createCardStatus = "processing";
      })
      .addCase(cardsAsync.fulfilled, (state) => {
        state.createCardStatus = "success";
      })
      .addCase(cardsAsync.rejected, (state) => {
        state.createCardStatus = "failed";
      })
      .addCase(cardsListAsync.pending, (state) => {
        state.cardsListStatus = "processing";
      })
      .addCase(cardsListAsync.fulfilled, (state, action) => {
        state.cardsList = action.payload;
        state.createCardStatus = "idle";
        state.cardUpdatedStatus = "idle";
        state.orderMultipleCardsStatus = "idle";
        state.cardsListStatus = "success";
        state.ruleUpdatedStatus = "idle";
      })
      .addCase(cardsListAsync.rejected, (state) => {
        state.cardsListStatus = "failed";
      })
      .addCase(updateCardStatusAsync.pending, (state) => {
        state.cardUpdatedStatus = "processing";
      })
      .addCase(updateCardStatusAsync.fulfilled, (state, action) => {
        state.cardUpdatedStatus = "success";
        state.ruleUpdated = action.payload.status;
      })
      .addCase(updateCardStatusAsync.rejected, (state) => {
        state.cardUpdatedStatus = "failed";
      })
      .addCase(orderMultipleCardsAsync.pending, (state) => {
        state.orderMultipleCardsStatus = "processing";
      })
      .addCase(orderMultipleCardsAsync.fulfilled, (state, action) => {
        state.orderMultipleCardsStatus = "success";
        state.newCardsListOrdered = action.payload;
      })
      .addCase(orderMultipleCardsAsync.rejected, (state) => {
        state.orderMultipleCardsStatus = "failed";
      })
      .addCase(addressAsync.pending, (state) => {
        state.getAddressStatus = "processing";
      })
      .addCase(addressAsync.fulfilled, (state, action) => {
        state.companyAddress = action.payload;
        state.getAddressStatus = "success";
      })
      .addCase(addressAsync.rejected, (state) => {
        state.getAddressStatus = "failed";
      })
      .addCase(updateVehicleRuleAsync.pending, (state) => {
        state.ruleUpdatedStatus = "processing";
      })
      .addCase(updateVehicleRuleAsync.fulfilled, (state) => {
        state.ruleUpdatedStatus = "success";
      })
      .addCase(updateVehicleRuleAsync.rejected, (state) => {
        state.ruleUpdatedStatus = "failed";
      });
  },
});

export const selectCardCreationStatus = (state: RootState) =>
  state.cards.createCardStatus;

export const selectCardsList = (state: RootState) => state.cards.cardsList;

export const selectCardsListStatus = (state: RootState) =>
  state.cards.cardsListStatus;

export const selectCardUpdatedStatus = (state: RootState) =>
  state.cards.cardUpdatedStatus;

export const selectOrderMultipleCardsStatus = (state: RootState) =>
  state.cards.orderMultipleCardsStatus;

export const selectCompanyAddress = (state: RootState) =>
  state.cards.companyAddress;

export const selectNewCardsList = (state: RootState) =>
  state.cards.newCardsListOrdered;

export const selectRuleUpdatedStatus = (state: RootState) =>
  state.cards.ruleUpdatedStatus;

export const selectRuleUpdated = (state: RootState) => state.cards.ruleUpdated;

export default cardsSlice.reducer;
