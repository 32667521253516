import { selectCompany } from "./../homeSlice";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Spacer, Assets, Button, colors, TextCapitalized } from "@qivia/ui";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectStripeOnboardingUrl,
  stripeOnboardingUrlAsync,
} from "../../stripe/stripeSlice";
import { stripe } from "@qivia/core";

export const DashboardOnboardCta = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const stripeOnboardingUrl = useAppSelector(selectStripeOnboardingUrl);
  const company = useAppSelector(selectCompany);

  useEffect(() => {
    const accountLinkPayload = {
      accountId: company?.providerId,
      refreshUrl: `${import.meta.env["VITE_BASE_URL"] as string}/home`,
      returnUrl: `${import.meta.env["VITE_BASE_URL"] as string}/home`,
      type: "account_onboarding",
    } as stripe.AccountLinkType;
    void dispatch(stripeOnboardingUrlAsync(accountLinkPayload));
  }, [dispatch, company]);

  const onClick = () => {
    if (stripeOnboardingUrl) {
      window.location.href = stripeOnboardingUrl;
    }
  };

  return (
    <StyledOnboardCta>
      <Spacer y={1} />
      <StyledContainer>
        <Spacer x={1} />
        <Assets.AlertBlack />
        <Spacer x={2} />
        <StyledColumn>
          <StyledOnboardCtaTitle>
            <TextCapitalized>
              {t("dashboard.onboardingCta.title")}
            </TextCapitalized>
          </StyledOnboardCtaTitle>
          <Spacer y={0.5} />
          <StyledOnboardCtaSubitle>
            <TextCapitalized>
              {t("dashboard.onboardingCta.subtitle")}
            </TextCapitalized>
          </StyledOnboardCtaSubitle>
        </StyledColumn>
        <Spacer x={2} />
        <StyledButton>
          <Button
            title={t("dashboard.onboardingCta.cta")}
            borderRadius={1.75}
            fontWeight={500}
            height={2.25}
            width={13}
            onClick={() => onClick()}
          />
        </StyledButton>
        <Spacer x={2} />
      </StyledContainer>
      <Spacer y={1} />
    </StyledOnboardCta>
  );
};
const StyledButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledOnboardCtaSubitle = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  color: ${colors.black};
`;

const StyledOnboardCtaTitle = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 1.125rem;
  color: ${colors.black};
`;

const StyledOnboardCta = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${colors.lightRed};
  border-radius: 0.5rem;
  border: 1px solid ${colors.ligthGrey};
  width: inherit;
  max-width: 70rem;
`;
