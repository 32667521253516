import {
  selectVehiclesCreatedStatus,
  selectListAllVehiclesInDb,
  listAllVehiclesInDbAsync,
} from "./../vehicles/vehiclesSlice";
import { driversListAsync, selectDriversList } from "./../drivers/driversSlice";
import {
  rulesListAsync,
  selectRuleStatus,
  selectRulesList,
} from "./../rules/rulesSlice";
import {
  cardsListAsync,
  selectCardCreationStatus,
  selectCardsList,
} from "./../cards/cardsSlice";
import { selectCompany, selectManager } from "./../homeSlice";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  Spacer,
  Assets,
  TooltipComponent,
  Button,
  colors,
  TextCapitalized,
} from "@qivia/ui";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { AddVehicleModal } from "./../vehicles/addVehicleModal";
import { AddCardModal } from "../cards/addCardModal";
import { VehiclesListType } from "../vehicles/vehiclesAPI";

interface DashboardSummaryCardProps {
  label: string;
  icon: JSX.Element;
  onClick?: () => void;
  buttonWidth?: number;
  count: number;
  disabled?: boolean;
}

export const DashboardSummaryCard = (props: DashboardSummaryCardProps) => {
  const { label, icon, buttonWidth, onClick, count, disabled } = props;
  const { t } = useTranslation();
  const isAddCard = label === "cards";
  const isAddDriver = label === "drivers";
  const singularDriver = label === "drivers" && count <= 1;

  return (
    <StyledCard>
      <Spacer y={1} />
      <StyledCountContent>
        <Spacer x={1} />
        {(count > 0 || isAddDriver) && (
          <StyledCount>
            {count}
            <Spacer y={1} />
          </StyledCount>
        )}
      </StyledCountContent>
      <StyledCardTitle>
        <Spacer x={1} />
        <TextCapitalized>
          {t(`dashboard.onboarding.${singularDriver ? "driver" : label}`)}
        </TextCapitalized>
        <Spacer x={0.5} />
        {icon}
        {isAddCard && disabled && (
          <StyledQuestionIcon>
            <Assets.GreenQuestionmark data-tooltip-id="CardTooltip" />
            <TooltipComponent
              id={"CardTooltip"}
              place={"bottom"}
              title={t(`dashboard.onboarding.${label}.tooltip.title`) || ""}
              content={t(`dashboard.onboarding.${label}.tooltip.content`) || ""}
            />
          </StyledQuestionIcon>
        )}
        <Spacer x={1} />
      </StyledCardTitle>
      <Spacer y={1} />
      <StyledCardContent>
        <Spacer x={1} />
        <StyledContent>
          {count === 0 && !isAddDriver && (
            <>
              <StyledCardContentText>
                {t(`dashboard.onboarding.${label}.subtitle`)}
              </StyledCardContentText>
              <Spacer y={1} />
            </>
          )}
          {buttonWidth && onClick && (
            <Button
              title={t(`dashboard.onboarding.${label}.cta`)}
              LeftElement={isAddDriver ? null : <Assets.PlusSignWhite />}
              borderRadius={1.75}
              fontWeight={500}
              height={2.25}
              width={buttonWidth}
              onClick={() => {
                if (!disabled) onClick();
              }}
              disabled={disabled}
            />
          )}
        </StyledContent>
      </StyledCardContent>
      <Spacer y={1} />
    </StyledCard>
  );
};

export interface DashboardSummaryProps {
  isCardCtaActive: boolean;
  ruleCreation: () => void;
}

export const DashboardSummary = (props: DashboardSummaryProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const manager = useAppSelector(selectManager);
  const [isVehicleModalVisible, setIsVehicleModalVisible] = useState(false);
  const [isCardModalVisible, setIsCardModalVisible] = useState(false);
  const company = useAppSelector(selectCompany);

  const { ruleCreation } = props;

  const onClickVehicle = useCallback(() => {
    setIsVehicleModalVisible(true);
  }, []);
  const onClickRule = useCallback(() => {
    ruleCreation();
  }, [ruleCreation]);
  const onClickCard = useCallback(() => {
    setIsCardModalVisible(true);
  }, []);

  const createdVehicleStatus = useAppSelector(selectVehiclesCreatedStatus);
  const allVehiclesInDb = useAppSelector(selectListAllVehiclesInDb);

  useEffect(() => {
    if (company) {
      void dispatch(listAllVehiclesInDbAsync({ companyUuid: company.uuid }));
    }
  }, [dispatch, company, createdVehicleStatus]);

  const vehiclesActiveList: VehiclesListType[] = useMemo(() => {
    return allVehiclesInDb.filter(
      (vehicle) => vehicle.status !== "DEACTIVATED",
    );
  }, [allVehiclesInDb]);

  const driversList = useAppSelector(selectDriversList);
  useEffect(() => {
    if (company) {
      void dispatch(driversListAsync(company.uuid));
    }
  }, [dispatch, company]);

  const createRuleStatus = useAppSelector(selectRuleStatus);
  const rulesList = useAppSelector(selectRulesList);
  useEffect(() => {
    if (company) {
      void dispatch(rulesListAsync(company.uuid));
    }
  }, [dispatch, createRuleStatus, company]);

  const cardCreationStatus = useAppSelector(selectCardCreationStatus);
  const cardsList = useAppSelector(selectCardsList);
  useEffect(() => {
    if (company) {
      void dispatch(cardsListAsync(company.uuid));
    }
  }, [dispatch, cardCreationStatus, company]);

  const isWelcomeManagerTitle =
    vehiclesActiveList.length > 0 &&
    driversList.length > 0 &&
    rulesList.length > 0 &&
    cardsList.length > 0;

  const accountIsDisabled =
    company?.iban === "" ||
    company?.bic === "" ||
    company?.iban === null ||
    company?.bic === null;

  return (
    <StyledSummary>
      <Spacer y={2.5} />
      <AddVehicleModal
        visible={isVehicleModalVisible}
        onCloseModal={() => {
          setIsVehicleModalVisible(false);
        }}
      />
      <AddCardModal
        visible={isCardModalVisible}
        onCloseModal={() => {
          setIsCardModalVisible(false);
        }}
      />
      {isWelcomeManagerTitle ? (
        <StyledRow>
          <Spacer x={2.5} />
          <StyledSummaryTitle>
            <TextCapitalized>
              {t("dashboard.onboarding.welcome") +
                " " +
                `${manager?.firstName ?? ""}`}
            </TextCapitalized>
          </StyledSummaryTitle>
          <Spacer x={2.5} />
        </StyledRow>
      ) : (
        <>
          <StyledRow>
            <Spacer x={2.5} />
            <StyledSummaryTitle>
              <TextCapitalized>
                {t("dashboard.onboarding.title")}
              </TextCapitalized>
            </StyledSummaryTitle>
            <Spacer x={2.5} />
          </StyledRow>
          <Spacer y={1.5} />
          <StyledRow>
            <Spacer x={2.5} />
            <StyledSummarySubitle>
              <TextCapitalized>
                {t("dashboard.onboarding.subtitle")}
              </TextCapitalized>
            </StyledSummarySubitle>
            <Spacer x={2.5} />
          </StyledRow>
        </>
      )}
      <Spacer y={1.5} />
      <StyledRow>
        <Spacer x={2.5} />
        <StyledCardsContainer>
          <DashboardSummaryCard
            label={"vehicles"}
            icon={<Assets.CarEmoji />}
            onClick={onClickVehicle}
            buttonWidth={11}
            count={vehiclesActiveList.length}
          />
          <DashboardSummaryCard
            label={"drivers"}
            icon={<Assets.DriverEmoji />}
            onClick={() => navigate("drivers")}
            buttonWidth={11}
            count={driversList.length}
          />
          <DashboardSummaryCard
            label={"rules"}
            icon={<Assets.RuleEmoji />}
            onClick={onClickRule}
            buttonWidth={10}
            count={rulesList.length}
          />
          <DashboardSummaryCard
            label={"cards"}
            icon={<Assets.CardEmoji />}
            onClick={onClickCard}
            buttonWidth={10}
            count={cardsList.length}
            disabled={accountIsDisabled || !props.isCardCtaActive}
          />
        </StyledCardsContainer>
        <Spacer x={1.5} />
      </StyledRow>
      <Spacer y={2.5} />
    </StyledSummary>
  );
};

const StyledCount = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 2rem;
`;
const StyledCountContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledQuestionIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const StyledCardContentText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 135%;
  width: 198px;
  height: 38px;
  color: ${colors.black};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledCardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const StyledCardTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #333333;
`;

const StyledCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 14.8rem;
  height: 9.8rem;
  background: #edf0ef;
  border-radius: 1rem;
`;

const StyledCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSummarySubitle = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 1rem;
  color: ${colors.black};
`;

const StyledSummaryTitle = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 1.125rem;
  color: ${colors.black};
`;

const StyledSummary = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${colors.background};
  box-shadow: 0rem 0.25rem 0.5rem rgba(83, 86, 84, 0.05);
  border-radius: 0.5rem;
  width: inherit;
  max-width: 70rem;
`;
