import { CategoryInfoType } from "./libDefaultDatas";

export type DaysOfWeekType =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";

export const categories = [
  "FUEL_AUTOMATON",
  "SERVICE_STATION",
  "WASH",
  "TOLL",
  "PARKING",
  "ELECTRIC",
] as const;

export type CategoryType = (typeof categories)[number];

export type AmountLimit = number | "";

export type AmountType = "WEEKLY" | "MONTHLY" | "DAILY";

export interface UpdateRulesAPI extends RuleFormData {
  uuid: string;
}

export interface RulesListApi {
  uuid: string;
  name: string;
  vehicleCount: number;
}

export interface AllowedPeriodType {
  startTime: string;
  endTime: string;
  dayOfWeek: string;
}

export type GeographicControlType = AllowableCountryType[];

export interface NotificationType {
  areSupportingDocumentsMandatory: boolean;
}

export type AllowedCategoryType = {
  category: CategoryType;
  amountLimit: AmountLimit;
  typeLimit: AmountType;
};

export type AllowedCategoryToDBType = {
  category: CategoryType;
  amountLimit: number | null;
  typeLimit: AmountType | null;
};

export type CategoryError = {
  category: CategoryType;
  error: boolean;
};

export interface ExpensesLimitType {
  amountLimit: AmountLimit;
  typeLimit: AmountType;
}

export const allowableCountries = ["FR", "BE"] as const;
export type AllowableCountryType = (typeof allowableCountries)[number];

export interface RuleFormData {
  uuid?: string;
  name: string;
  onlyFrance: boolean;
  allowedCountries: AllowableCountryType[];
  nationalHolidays: boolean;
  amountLimit: AmountLimit;
  typeLimit: AmountType;
  allowedPeriods: AllowedPeriodType[];
  categories: AllowedCategoryToDBType[];
  areOtherMerchantsAuthorized: boolean;
  unauthorizedMerchantGroups: MerchantGroups[];
  areSupportingDocumentsMandatory: boolean;
}

export interface RuleDataCreation extends RuleFormData {
  companyUuid: string;
}

export interface RulesListDisplayedType {
  name: string;
  vehicleCount: number;
}
export interface PublicRuleType {
  uuid: string;
  name: string;
  vehicleCount: number;
  date: string;
}

export interface MerchantGroupsType {
  areOtherMerchantsAuthorized: boolean;
  unauthorizedMerchantGroups: MerchantGroups[];
}

export type MerchantGroups =
  | "SHELL"
  | "BP"
  | "ENI"
  | "TOTAL_ENERGIES"
  | "AVIA"
  | "ESSO"
  | "CASINO"
  | "CARREFOUR"
  | "INTERMARCHE"
  | "AUCHAN"
  | "SYSTEME_U"
  | "LECLERC";

export interface RuleDefaultData {
  defaultRuleName: string;
  defaultExpensesLimit: ExpensesLimitType;
  defaultGeographicControl: GeographicControlType;
  defaultNationalHolidays: boolean;
  defaultAllowedPeriods: AllowedPeriodType[];
  defaultAllowedCategories: AllowedCategoryType[];
  categoryInfoList: CategoryInfoType[];
  defaultMerchantGroups: MerchantGroupsType;
  defaultNotifications: NotificationType;
}
