import axios from "axios";
import { AddressInput } from "./registerPages/addressPage";

export type BusinessTypeForDB = "COMPANY" | "NON_PROFIT";

export interface RegisterAPI {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  companyName: string;
  identificationNumber: string;
  token: string;
  address: AddressInput;
  businessType: BusinessTypeForDB;
  job: string;
}

export const registerCall = async (payload: RegisterAPI) => {
  const BACKEND_URL = import.meta.env["VITE_BACKEND_URL"] as string;
  return await axios.post<{ token: string }>(
    `${BACKEND_URL}/office/register`,
    payload,
  );
};
