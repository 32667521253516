import { ActionBlock } from "./ActionBlock";
import { ActionStripe } from "./ActionStripe";
import { Section } from "./Section";
import { SectionedActionStripe } from "./SectionedActionStripe";
import { SectionRow } from "./SectionRow";
import { SidePanelDS as SidePanel } from "./SidePanelDS";

export const SidePanelDS = {
  ActionBlock,
  ActionStripe,
  Section,
  SectionedActionStripe,
  SectionRow,
  SidePanel,
};
