import {
  ButtonDS,
  InputDS,
  LaunchPage,
  OnboardingPage,
  PasswordDoubleCheck,
  Spacer,
  Title,
  ToastContainer,
  hasPasswordFormat,
  triggerToast,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { Assets } from "@qivia/ui";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { passwordResetAsync, selectPasswordPasswordStatus } from "./loginSlice";

type PasswordInput = {
  password: string;
  passwordDoubleCheck: string;
};

export const PasswordReset = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const passwordResetStatus = useAppSelector(selectPasswordPasswordStatus);
  const [queryParameters] = useSearchParams();
  const requestId = queryParameters.get("id");
  const [route, setRoute] = useState<string>("passwordForgotten");

  const [passwordInput, setPasswordInput] = useState<PasswordInput>({
    password: "",
    passwordDoubleCheck: "",
  });

  useEffect(() => {
    if (passwordResetStatus === "success") {
      triggerToast(t("passwordForgotten.resetPassword.success") || "", "valid");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else if (passwordResetStatus === "failed") {
      triggerToast(t("passwordForgotten.resetPassword.error") || "", "error");
    }
  }, [passwordResetStatus, navigate, t]);

  const resetPassword = useCallback(async () => {
    if (requestId) {
      await dispatch(
        passwordResetAsync({ password: passwordInput.password, requestId }),
      );
    }
  }, [dispatch, passwordInput.password, requestId]);

  const isSamePassword = useCallback(
    (password: string) => {
      if (password === "" || passwordInput.passwordDoubleCheck === "")
        return false;
      return password === passwordInput.passwordDoubleCheck;
    },
    [passwordInput.passwordDoubleCheck],
  );

  const hasValidFormat = useCallback(() => {
    return (
      hasPasswordFormat(passwordInput.password) &&
      isSamePassword(passwordInput.password)
    );
  }, [isSamePassword, passwordInput.password]);

  useEffect(() => {
    if (route === "login") {
      navigate("/login");
    }
  }, [route, navigate]);

  return (
    <>
      {route === "passwordForgotten" ? (
        <OnboardingPage
          title={
            <Title
              text={t("passwordForgotten.resetPassword.title")}
              size={"large"}
            ></Title>
          }
          form={
            <div>
              <InputDS
                label={t("passwordForgotten.resetPassword.input.title")}
                type="PASSWORD"
                value={passwordInput.password}
                placeholder={t(
                  "passwordForgotten.resetPassword.input.placeholder",
                )}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPasswordInput({
                    ...passwordInput,
                    password: e.target.value,
                  });
                }}
              />
              <InputDS
                label={t("passwordForgotten.resetPassword.input.secondTitle")}
                type="PASSWORD"
                value={passwordInput.passwordDoubleCheck}
                placeholder={t(
                  "passwordForgotten.resetPassword.input.placeholder",
                )}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPasswordInput({
                    ...passwordInput,
                    passwordDoubleCheck: e.target.value,
                  });
                }}
              />
              <PasswordDoubleCheck values={passwordInput} />
              <Spacer y={1} />
              <ButtonDS
                text={t(`passwordForgotten.resetPassword.cta.continue`) || ""}
                onClick={() => {
                  if (hasValidFormat()) {
                    void resetPassword();
                  }
                }}
                disabled={!hasValidFormat()}
                format={"fill"}
                buttonType={"primary"}
              />
              <ButtonDS
                format={"fill"}
                buttonType={"secondary"}
                text={t(`passwordForgotten.resetPassword.cta.back`) || ""}
                leftIcon={<Assets.LeftArrowSolid />}
                onClick={() => setRoute("login")}
              />
              <ToastContainer />
            </div>
          }
          toaster={<ToastContainer />}
        />
      ) : (
        <LaunchPage />
      )}
    </>
  );
};
