import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { StripeAPI } from "./stripeAPI";
import { loadStripe } from "@stripe/stripe-js";
import { stripe } from "@qivia/core";
import { QueryStatus, authAxios } from "../../utils";

export interface StripeState {
  status: QueryStatus;
  stripeToken: string;
  stripeOnboardingUrl: string;
}

const initialState: StripeState = {
  status: "idle",
  stripeToken: "",
  stripeOnboardingUrl: "",
};

export const stripeTokenCreationAsync = createAsyncThunk(
  "stripeTokenCreation",
  async (payload: StripeAPI) => {
    const stripe = await loadStripe(
      `${import.meta.env["VITE_STRIPE_PUBLIC_KEY"] as string} `,
      { apiVersion: "2022-11-15" },
    );
    if (stripe) {
      const response = await stripe.createToken("account", payload);
      return response;
    }
  },
);

export const stripeOnboardingUrlAsync = createAsyncThunk(
  "stripeOnboardingUrl",
  async (payload: stripe.AccountLinkType) => {
    const axios = authAxios();
    const response = await axios.post<{ linkUrl: string }>(
      `/stripe/onboarding_link`,
      payload,
    );
    return response.data.linkUrl;
  },
);

export const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetStripeToken: (state) => {
      state.stripeToken = "";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(stripeTokenCreationAsync.pending, (state) => {
        state.status = "processing";
      })
      .addCase(stripeTokenCreationAsync.fulfilled, (state, action) => {
        state.stripeToken = action.payload?.token?.id || "";
        state.status = "success";
      })
      .addCase(stripeTokenCreationAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(stripeOnboardingUrlAsync.pending, (state) => {
        state.status = "processing";
      })
      .addCase(stripeOnboardingUrlAsync.fulfilled, (state, action) => {
        state.stripeOnboardingUrl = action.payload || "";
        state.status = "success";
      })
      .addCase(stripeOnboardingUrlAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStatus = (state: RootState) => state.stripe.status;
export const selectStripeToken = (state: RootState) => state.stripe.stripeToken;
export const selectStripeOnboardingUrl = (state: RootState) =>
  state.stripe.stripeOnboardingUrl;

export default stripeSlice.reducer;
