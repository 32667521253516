import { Input, InputProps, OnboardingPage } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { FormSignIn, TitleForm } from "./formSignIn";

export type PersonalDataInput = {
    firstName: string;
    lastName: string;
    job: string;
};

const genInput = (props: InputProps) => (
    <Input {...props} hasSmallBottomSpacer />
);

export interface PersonalDataPageProps {
    nextPage: () => void;
    previousPage: () => void;
    onChange: (value: PersonalDataInput) => void;
    isValidForm: boolean;
    values: PersonalDataInput
}

export const PersonalDataPage = (props: PersonalDataPageProps) => {
    const { t } = useTranslation();
    return (
        <OnboardingPage
            title={<TitleForm text={"signIn.personalData.title1"} />}
            subtitle="signIn.personalData.subtitle"
            form={
                <FormSignIn<keyof PersonalDataInput>
                    fields={{
                        firstName: {
                            isPassword: false,
                            error: (value) => (!value ? t(`signIn.form.error.firstName`) : null),
                            element: genInput,
                        },
                        lastName: {
                            isPassword: false,
                            error: (value) => (!value ? t(`signIn.form.error.lastName`) : null),
                            element: genInput,
                        },
                        job: {
                            isPassword: false,
                            error: (value) =>
                                !value ? t(`signIn.form.error.companyName`) : null,
                            element: genInput,
                        },
                    }}
                    pageName="personalData"
                    onClickNext={props.nextPage}
                    onClickBack={props.previousPage}
                    onChange={(e) => props.onChange(e)}
                    isValidForm={props.isValidForm}
                    values={props.values}
                />
            }
        />
    )
};
