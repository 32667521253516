import {
  Input,
  InputProps,
  OnboardingPage,
  PhoneInput,
  hasEmailFormat,
  hasMobilePhoneFormat,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { FormSignIn, TitleForm } from "./formSignIn";

export type ContactDetailsInput = {
  email: string;
  phone: string;
};

export interface ContactDetailsPageProps {
  nextPage: () => void;
  previousPage: () => void;
  onChange: (value: ContactDetailsInput) => void;
  isValidForm: boolean;
  values: ContactDetailsInput;
}

const genInput = (props: InputProps) => (
  <Input {...props} hasSmallBottomSpacer />
);

const genPhoneInput = (props: InputProps) => <PhoneInput {...props} />;

export const ContactDetailsPage = (props: ContactDetailsPageProps) => {
  const { t } = useTranslation();
  return (
    <OnboardingPage
      title={<TitleForm text={"signIn.contactDetails.title1"} />}
      subtitle="signIn.contactDetails.subtitle"
      form={
        <FormSignIn<keyof ContactDetailsInput>
          fields={{
            email: {
              isPassword: false,
              error: (value) =>
                !value
                  ? t(`signIn.form.error.email`)
                  : !hasEmailFormat(value)
                  ? t(`signIn.form.error.email.incorrectFormat`)
                  : null,
              element: genInput,
            },
            phone: {
              isPassword: false,
              error: (value) =>
                !value
                  ? t(`signIn.form.error.phone`)
                  : !hasMobilePhoneFormat(value)
                  ? t(`signIn.form.error.phone.incorrectFormat`)
                  : null,
              element: genPhoneInput,
            },
          }}
          pageName="contactDetails"
          privatePolicy={t(`signIn.contactDetails.privacyPolicy`) || ""}
          onClickNext={props.nextPage}
          onClickBack={props.previousPage}
          onChange={(e) => props.onChange(e)}
          isValidForm={props.isValidForm}
          values={props.values}
        />
      }
    />
  );
};
