import {
  Input,
  InputProps,
  OnboardingPage,
  PasswordDoubleCheck,
  ToastContainer,
  triggerToast,
} from "@qivia/ui";
import { FormSignIn, TitleForm } from "./formSignIn";
import { ClipboardEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";

export type PasswordInput = {
  password: string;
  passwordDoubleCheck: string;
};

export interface PasswordPageProps {
  nextPage: () => void;
  previousPage: () => void;
  onChange: (value: PasswordInput) => void;
  isValidForm: boolean;
  values: PasswordInput;
  isToaster: boolean;
}

const genPasswordInput = (props: InputProps) => {
  const preventCopyPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };
  return (
    <Input
      {...props}
      hasSmallBottomSpacer
      onCopy={(e) => preventCopyPaste(e)}
      onCut={(e) => preventCopyPaste(e)}
    />
  );
};

export const PasswordPage = (props: PasswordPageProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.isToaster) return;

    triggerToast(t("signIn.form.failure"), "error");
  }, [props.isToaster, t]);

  return (
    <OnboardingPage
      title={<TitleForm text={"signIn.password.title1"} />}
      subtitle="signIn.password.subtitle"
      toaster={<ToastContainer />}
      form={
        <FormSignIn<keyof PasswordInput>
          fields={{
            password: {
              isPassword: true,
              error: () => null,
              element: genPasswordInput,
            },
            passwordDoubleCheck: {
              isPassword: true,
              error: () => null,
              element: genPasswordInput,
            },
          }}
          pageName="password"
          onClickNext={props.nextPage}
          onClickBack={props.previousPage}
          onChange={(e) => props.onChange(e)}
          isValidForm={props.isValidForm}
          values={props.values}
          extraContent={<PasswordDoubleCheck values={props.values} />}
        />
      }
    />
  );
};
