import { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { typographies } from "../../../styles/figmaTypographies";
import { Spacer } from "../Spacer";
import { TextCapitalized } from "../text/TextCapitalized";
import { colors } from "../../../styles/figmaColors";

export interface RowElementProps extends HTMLAttributes<HTMLButtonElement> {
  size: sizeRowElement;
  tooltip?: JSX.Element;
  onClickRow?: () => void;
  leftIcon: JSX.Element;
  text: string;
  rightElement: JSX.Element;
}

type sizeRowElement = "S" | "L";

const rowOptions = {
  S: {
    spacer: 0.75,
    sizeIcon: 1,
    radius: 0.5,
    heightRow: 3,
  },
  L: {
    spacer: 1.5,
    sizeIcon: 1.5,
    radius: 1.125,
    heightRow: 4.5,
  },
};

export const RowElement = (props: RowElementProps) => {
  const { text, leftIcon, onClickRow, size, rightElement, tooltip } = props;
  const typo = typographies["Body/M"];
  const options = rowOptions[size];

  return (
    <StyledRowElement
      typo={typo}
      $radius={options.radius}
      $heightRow={options.heightRow}
      onClick={onClickRow}
      $hasClickableRow={!!onClickRow}
    >
      <Spacer y={options.spacer} />
      <StyledContent>
        <Spacer x={options.spacer} />
        <StyledLeftIcon $sizeIcon={options.sizeIcon}>{leftIcon}</StyledLeftIcon>
        {size === "S" ? <Spacer x={0.25} /> : <Spacer x={1} />}
        <StyledText>
          <TextCapitalized>{text}</TextCapitalized>
          {tooltip && (
            <StyledToolTip>
              <Spacer x={0.25} /> {tooltip}
            </StyledToolTip>
          )}
        </StyledText>
        <Spacer x={options.spacer} />
        <div>{rightElement}</div>
        <Spacer x={options.spacer} />
      </StyledContent>
      <Spacer y={options.spacer} />
    </StyledRowElement>
  );
};

interface StyledContentArgs {
  typo: string;
  $radius: number;
  $heightRow: number;
  $hasClickableRow: boolean;
}

interface StyledIconArgs {
  $sizeIcon: number;
}

const StyledToolTip = styled.div`
  display: flex;
  & svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

const StyledText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledRowElement = styled.div<StyledContentArgs>`
  ${({ typo }) => css`
    ${typo}
  `};
  border-radius: ${({ $radius }) => $radius}rem;
  border: 0.0625rem solid ${colors["colors/borders/button/primary"]};
  box-shadow: 2px 4px 4px 0px rgba(122, 122, 122, 0.08);
  width: 100%;
  background: ${colors["colors/button/secondary/default"]};
  color: ${colors["colors/text/black"]};
  height: ${({ $heightRow }) => $heightRow}rem;
  display: flex;
  flex-direction: column;
  cursor: ${({ $hasClickableRow }) =>
    $hasClickableRow ? "pointer" : "default"};
`;

const StyledLeftIcon = styled.div<StyledIconArgs>`
  ${({ $sizeIcon }) =>
    `width: ${$sizeIcon}rem; height:${$sizeIcon}rem;
  & svg {
    width: ${$sizeIcon}rem;
    height: ${$sizeIcon}rem;
  }`}
`;

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
`;
