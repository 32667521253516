import {
  triggerToast,
  DialogModalDS,
  ButtonDS,
  InputDS,
  Spacer,
  PersonOutlined,
  SendOutlined,
  TextCapitalized,
  Switch,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import styled from "styled-components";
import {
  selectManagerCreationStatus,
  selectManagersList,
  userAsync,
} from "./usersSlice";
import { ManagerCreation } from "./usersAPI";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";

export const AddUserModal = (props: {
  visible: boolean;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { onCloseModal } = props;
  const managerCreationStatus = useAppSelector(selectManagerCreationStatus);
  const managers = useAppSelector(selectManagersList);

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [job, setJob] = useState<string>("");

  const hasDuplicateEmail: boolean = useMemo(() => {
    if (!email) return false;
    return !!managers.find(
      (manager) => manager.email?.toUpperCase() === email?.toUpperCase(),
    );
  }, [email, managers]);

  const isValidForm: boolean = useMemo(
    () => !!(firstName && lastName && email && !hasDuplicateEmail),
    [email, firstName, hasDuplicateEmail, lastName],
  );

  const resetValue = useCallback(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setJob("");
    setDisplayError(false);
  }, []);

  const onSubmit = useCallback(() => {
    if (!isValidForm) return;
    const vehicleData: ManagerCreation = {
      firstName,
      lastName,
      email,
      job,
    };
    void dispatch(userAsync(vehicleData));
    setDisplayError(false);
    onCloseModal();
  }, [dispatch, email, firstName, isValidForm, job, lastName, onCloseModal]);

  const clickToSubmit = useCallback(() => {
    setDisplayError(true);
    isValidForm && onSubmit();
  }, [isValidForm, onSubmit]);

  useEffect(() => {
    if (props.visible === false) {
      resetValue();
    }
  }, [props.visible, resetValue]);

  useEffect(() => {
    if (managerCreationStatus === "success") {
      triggerToast(t("users.modal.success") || "", "valid");
    } else if (managerCreationStatus === "failed") {
      triggerToast(t("users.modal.failure") || "", "error");
    }
  }, [managerCreationStatus, t]);

  return (
    <DialogModalDS
      visible={props.visible}
      title={t("users.modal.title") || ""}
      iconTitle={<PersonOutlined />}
      onClose={props.onCloseModal}
      bottomButton={{
        leftButton: (
          <ButtonDS
            text={t("users.modal.cancel")}
            format={"fill"}
            buttonType={"secondary"}
            onClick={() => {
              props.onCloseModal();
            }}
          />
        ),
        rightButton: (
          <ButtonDS
            leftIcon={<SendOutlined />}
            text={t("users.modal.send")}
            format={"fill"}
            buttonType={"primary"}
            onClick={clickToSubmit}
            disabled={!isValidForm}
          />
        ),
      }}
    >
      <StyledModalContent>
        <StyledFlex>
          <InputDS
            label={t("users.modal.firstName") || ""}
            placeholder={t("users.modal.firstName.placeholder")}
            error={
              displayError && !firstName
                ? t("users.modal.error.input")
                : undefined
            }
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
          />
          <InputDS
            label={t("users.modal.lastName") || ""}
            placeholder={t("users.modal.lastName.placeholder")}
            error={
              displayError && !lastName
                ? t("users.modal.error.input")
                : undefined
            }
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
          />
        </StyledFlex>
        <InputDS
          label={t("users.modal.email") || ""}
          placeholder={t("users.modal.email.placeholder")}
          error={
            displayError && !email
              ? t("users.modal.error.input")
              : hasDuplicateEmail
                ? t("users.modal.error.duplicateEmail")
                : undefined
          }
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
          isPlaceholderUppercase
        />
        <InputDS
          label={t("users.modal.job") || ""}
          placeholder={t("users.modal.job.placeholder")}
          error={
            displayError && !job ? t("users.modal.error.input") : undefined
          }
          value={job}
          onChange={(e) => setJob(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && clickToSubmit()}
        />
        <StyledRole>
          <Spacer x={0.25} />
          <TextCapitalized>{t(`users.modal.role`)}</TextCapitalized>
        </StyledRole>
        <Spacer y={0.75} />
        <StyledFlex>
          <StyledBodyLeft>
            <Spacer x={0.25} />
            <StyledSubTitle>
              <Spacer x={0.25} />
              <TextCapitalized>
                {t(`users.modal.role.ADMIN.title`)}
              </TextCapitalized>
              <Spacer y={0.25} />
              <StyledRoleText>
                <TextCapitalized>
                  {t(`users.modal.role.ADMIN.text`)}
                </TextCapitalized>
              </StyledRoleText>
            </StyledSubTitle>
          </StyledBodyLeft>
          <Spacer x={1} />
          <StyledBodyRight>
            <Switch
              checked={true}
              size="S"
              color={"GREEN"}
              disabled={true}
              onClick={() => {}}
            />
          </StyledBodyRight>
        </StyledFlex>
      </StyledModalContent>
    </DialogModalDS>
  );
};

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledRole = styled.div`
  display: flex;
  ${typographies["Body/M"]}
  width: 100%;
`;

const StyledFlex = styled.div`
  display: flex;
  width: 100%;
`;

const StyledBodyRight = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: fit-content;
  align-items: center;
`;

const StyledBodyLeft = styled.div`
  color: ${colors["colors/text/black"]};
  ${typographies["Body/L"]}
  display: flex;
  width: 100%;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;

const StyledSubTitle = styled.div`
  ${typographies["Body/L"]}
`;

const StyledRoleText = styled.div`
  ${typographies["Body/XXS"]}
  color: ${colors["colors/text/darkGrey"]};
  max-width: 70%;
`;
