import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import {
  LoginAPI,
  loginCall,
  loginPasswordForgottenCall,
  loginPasswordResetCall,
  PasswordCreationAPI,
  PasswordForgottenAPI,
  PasswordResetAPI,
  LastCreatedPasswordRequest,
} from "./loginAPI";
import { authAxios, QueryStatus } from "../../utils";

export interface loginState {
  status: QueryStatus;
  isLoginError: boolean;
  passwordResetStatus: QueryStatus;
  passwordCreationStatus: QueryStatus;
  lastCreatedPasswordRequest: LastCreatedPasswordRequest;
  lastCreatedPasswordRequestStatus: QueryStatus;
}

const initialState: loginState = {
  status: "idle",
  isLoginError: false,
  passwordResetStatus: "idle",
  passwordCreationStatus: "idle",
  lastCreatedPasswordRequest: { lastCreatedPasswordRequest: null },
  lastCreatedPasswordRequestStatus: "idle",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginAsync = createAsyncThunk(
  "login/call",
  async (payload: LoginAPI) => {
    const response = await loginCall(payload);
    return response.data.token;

    // The value we return becomes the `fulfilled` action payload
  },
);

export const loginPasswordForgottenAsync = createAsyncThunk(
  "login/passwordForgotten",
  async (payload: PasswordForgottenAPI) => {
    await loginPasswordForgottenCall(payload);
  },
);

export const passwordResetAsync = createAsyncThunk(
  "PasswordReset/call",
  async (payload: PasswordResetAPI) => {
    await loginPasswordResetCall(payload);
  },
);

export const passwordCreationAsync = createAsyncThunk(
  "passwordCreation/call",
  async (payload: PasswordCreationAPI) => {
    const axios = authAxios();
    await axios.post(`/login/manager/${payload.managerId}/create_password`, {
      password: payload.password,
    });
  },
);

export const lastCreatedPasswordRequestAsync = createAsyncThunk(
  "lastCreatedPasswordRequestAsync/call",
  async (payload: { managerUuid: string }) => {
    const axios = authAxios();
    const response = await axios.get<LastCreatedPasswordRequest>(
      `/login/manager/${payload.managerUuid}/last_created_password_request`,
    );
    return response.data;
  },
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    newToken: (state, action: PayloadAction<string>) => {
      localStorage.setItem("token", action.payload);
    },
    resetUpdatePasswordStatus(state) {
      state.passwordResetStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = "processing";
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        localStorage.setItem("token", action.payload);
        state.isLoginError = false;
        state.status = "success";
      })
      .addCase(loginAsync.rejected, (state) => {
        state.isLoginError = true;
        state.status = "failed";
      })
      .addCase(loginPasswordForgottenAsync.pending, (state) => {
        state.status = "processing";
      })
      .addCase(loginPasswordForgottenAsync.fulfilled, (state) => {
        state.status = "success";
      })
      .addCase(loginPasswordForgottenAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(passwordResetAsync.pending, (state) => {
        state.passwordResetStatus = "processing";
      })
      .addCase(passwordResetAsync.fulfilled, (state) => {
        state.passwordResetStatus = "success";
      })
      .addCase(passwordResetAsync.rejected, (state) => {
        state.passwordResetStatus = "failed";
      })
      .addCase(passwordCreationAsync.pending, (state) => {
        state.passwordCreationStatus = "processing";
      })
      .addCase(passwordCreationAsync.fulfilled, (state) => {
        state.passwordCreationStatus = "success";
      })
      .addCase(passwordCreationAsync.rejected, (state) => {
        state.passwordCreationStatus = "failed";
      })
      .addCase(lastCreatedPasswordRequestAsync.pending, (state) => {
        state.lastCreatedPasswordRequestStatus = "processing";
      })
      .addCase(lastCreatedPasswordRequestAsync.fulfilled, (state, action) => {
        state.lastCreatedPasswordRequestStatus = "success";
        state.lastCreatedPasswordRequest = action.payload;
      })
      .addCase(lastCreatedPasswordRequestAsync.rejected, (state) => {
        state.lastCreatedPasswordRequestStatus = "failed";
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStatus = (state: RootState) => state.login.status;
export const selectIsLoginError = (state: RootState) =>
  state.login.isLoginError;
export const selectPasswordPasswordStatus = (state: RootState) =>
  state.login.passwordResetStatus;
export const selectPasswordCreationStatus = (state: RootState) =>
  state.login.passwordCreationStatus;
export const selectLastCreatedPasswordRequestStatus = (state: RootState) =>
  state.users.lastCreatedPasswordRequestStatus;
export const selectLastCreatedPasswordRequest = (state: RootState) =>
  state.users.lastCreatedPasswordRequest;

export default loginSlice.reducer;
