import {
  ToastContainer,
  triggerToast,
  LaunchPage,
  sortByDateTime,
  TableDS,
  PageDS,
  Tag,
  TableTagVehicle,
  AddOutlined,
  UploadFileOutlined,
  Badge,
  MessageTooltip,
  EmptyTableComponent,
  TextCapitalized,
} from "@qivia/ui";
import { Trans, useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  selectErrorMessageMassivImport,
  selectListAllVehiclesInDb,
  selectMultipleVehiclesCreatedStatus,
  listAllVehiclesInDbAsync,
  vehiclesSlice,
  selectListAllVehiclesInDbStatus,
  selectDeactivateVehicleStatus,
} from "./vehiclesSlice";
import { unreachable } from "../../../utils";
import {
  VehiclesListType,
  VehiclesListDisplayedType,
  VehiclesListDisplayedRow,
} from "./vehiclesAPI";
import styled from "styled-components";
import Car from "@qivia/ui/src/designSystem/assets/Car.png";
import { Spacer } from "@qivia/ui/src/designSystem/components/Spacer";
import { VehicleSidePanel } from "./SidePanel";
import { useNavigate, useParams } from "react-router-dom";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { ImportVehiclesModal } from "./importVehiclesModal";
import { AddVehicleModal } from "./addVehicleModal";
import { selectCompany } from "../homeSlice";

export const Vehicles = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVehiclesModalVisible, setIsVehiclesModalVisible] = useState(false);
  const [messageTooltip, setMessageTooltip] = useState<JSX.Element | null>(
    null,
  );
  const [titleTooltip, setTitleTooltip] = useState<string>("");
  const allVehiclesInDb = useAppSelector(selectListAllVehiclesInDb);
  const deactivateVehicleStatus = useAppSelector(selectDeactivateVehicleStatus);
  const company = useAppSelector(selectCompany);

  const listAllVehiclesInDb: VehiclesListType[] = useMemo(() => {
    const vehicleFiltered = allVehiclesInDb.filter(
      (vehicle) => vehicle.status !== "DEACTIVATED",
    );
    return sortByDateTime(vehicleFiltered, "desc");
  }, [allVehiclesInDb]);

  const listAllVehiclesInDbStatus = useAppSelector(
    selectListAllVehiclesInDbStatus,
  );
  const createMultipleVehiclesStatus = useAppSelector(
    selectMultipleVehiclesCreatedStatus,
  );

  const [listDisplayed, setListDisplayed] = useState<
    VehiclesListDisplayedType[] | null
  >(null);

  const vehiclesMassiveImportErrorMessage = useAppSelector(
    selectErrorMessageMassivImport,
  );

  const findKeysToTranslate = (key: keyof VehiclesListType, value: string) => {
    if (key === "status") {
      return `vehicles.status.${value}`;
    } else return value;
  };

  const sidePanelAction = useCallback(
    (props: Partial<VehiclesListType>) => {
      navigate(`/home/vehicles/${props.uuid}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (params.id) {
      const vehicleSelected = listAllVehiclesInDb.find(
        (v) => v.uuid === params.id,
      );
      if (vehicleSelected) {
        navigate(`/home/vehicles/${vehicleSelected.uuid}`);
      }
    }
  }, [dispatch, listAllVehiclesInDb, navigate, params.id]);

  useEffect(() => {
    if (
      createMultipleVehiclesStatus === "success" &&
      vehiclesMassiveImportErrorMessage
    ) {
      triggerToast(
        t("vehicles.modal.addMultipleVehicles.sameListError"),
        "error",
      );
    } else if (createMultipleVehiclesStatus === "failed") {
      triggerToast(
        t("vehicles.modal.addMultipleVehicles.failure") || "",
        "error",
      );
    }
  }, [createMultipleVehiclesStatus, t, vehiclesMassiveImportErrorMessage]);

  useEffect(() => {
    if (deactivateVehicleStatus === "success") {
      triggerToast(t("vehicles.deactivated.success") || "", "valid");
    } else if (deactivateVehicleStatus === "failed") {
      triggerToast(t("vehicles.deactivated.failure") || "", "error");
    }
  }, [deactivateVehicleStatus, t]);

  useEffect(() => {
    if (company) {
      void dispatch(listAllVehiclesInDbAsync({ companyUuid: company.uuid }));
    }
  }, [dispatch, company]);

  useEffect(() => {
    if (listAllVehiclesInDb && listAllVehiclesInDbStatus === "success") {
      const newVehiclesList = listAllVehiclesInDb.filter((v) => v.isNewVehicle);
      const updatedVehiclesList = listAllVehiclesInDb.filter(
        (v) => v.isUpdatedVehicle,
      );
      const alreadyExistedVehiclesList = listAllVehiclesInDb.filter(
        (v) => v.isAlreadyExisted,
      );

      const stringifyAlreadyVehiclesList = alreadyExistedVehiclesList
        .map((vehicle) => {
          return [vehicle.registrationNumber, vehicle.reference]
            .filter((d) => d)
            .join(" / ");
        })
        .join(", ");

      const vehiclesImportLength =
        newVehiclesList.length +
        updatedVehiclesList.length +
        alreadyExistedVehiclesList.length;

      const toolTipMessageAllVehicles =
        vehiclesImportLength > 0
          ? t(
              `vehicles.status.messageTooltip.importAll.${
                vehiclesImportLength === 1 ? "singural" : "plural"
              }`,
              {
                number: vehiclesImportLength.toString(),
              },
            )
          : "";

      setTitleTooltip(toolTipMessageAllVehicles);

      const toolTipMessageCreated =
        newVehiclesList.length > 0
          ? t(
              `vehicles.status.messageTooltip.creation.${
                newVehiclesList.length === 1 ? "singural" : "plural"
              }`,
              {
                number: newVehiclesList.length.toString(),
              },
            )
          : "";

      const toolTipMessageUpdated =
        updatedVehiclesList.length > 0
          ? t(
              `vehicles.status.messageTooltip.update.${
                updatedVehiclesList.length === 1 ? "singural" : "plural"
              }`,
              {
                number: updatedVehiclesList.length.toString(),
              },
            )
          : "";

      const toolTipMessageAlreadyExists =
        alreadyExistedVehiclesList.length > 0 ? (
          <Trans
            i18nKey={t(
              `vehicles.status.messageTooltip.alreadyExists.${
                alreadyExistedVehiclesList.length === 1 ? "singural" : "plural"
              }`,
              {
                number: alreadyExistedVehiclesList.length.toString(),
                registrationNumbers: stringifyAlreadyVehiclesList,
              },
            )}
          />
        ) : (
          ""
        );

      setMessageTooltip(
        vehiclesImportLength > 0 ? (
          <StyledColumn>
            <div>
              <TextCapitalized>{toolTipMessageCreated}</TextCapitalized>
            </div>
            {toolTipMessageCreated && toolTipMessageUpdated && (
              <Spacer y={0.5} />
            )}
            <div>
              <TextCapitalized>{toolTipMessageUpdated}</TextCapitalized>
            </div>
            {toolTipMessageUpdated && toolTipMessageAlreadyExists && (
              <Spacer y={0.5} />
            )}
            <div>
              <TextCapitalized>{toolTipMessageAlreadyExists}</TextCapitalized>
            </div>
          </StyledColumn>
        ) : null,
      );

      const vehiclesDateTimeSorted = sortByDateTime(
        listAllVehiclesInDb,
        "desc",
      );
      setListDisplayed(vehiclesDateTimeSorted);
      dispatch(vehiclesSlice.actions.resetListAllVehiclesInDbStatus());
    }
  }, [dispatch, listAllVehiclesInDbStatus, listAllVehiclesInDb, t]);

  if (!listDisplayed) return <LaunchPage hasBorderRadius={true} />;

  const searchBarProps = {
    values: listAllVehiclesInDb,
    setFilterValues: setListDisplayed,
    keysToTranslate: ["status" as const],
    findKeysToTranslate,
    keysToIgnore: [
      "date" as const,
      "uuid" as const,
      "mileage" as const,
      "mileageDate" as const,
      "rule" as const,
      "ruleName" as const,
      "cardUuid" as const,
      "cardName" as const,
      "consumption" as const,
      "energy" as const,
      "vehicleType" as const,
      "deliveryDate" as const,
      "createdAt" as const,
      "isNewVehicle" as const,
      "isAlreadyExisted" as const,
      "isUpdatedVehicle" as const,
    ],
  };

  const cta = {
    title: t("vehicles.cta"),
    action: () => {
      setIsModalVisible(true);
    },
    leftIcon: <AddOutlined />,
  };

  const cta2 = {
    title: t("vehicles.cta2"),
    action: () => {
      setIsVehiclesModalVisible(true);
    },
    leftIcon: <UploadFileOutlined />,
  };

  const render =
    (row: VehiclesListDisplayedRow) =>
    (key: keyof VehiclesListDisplayedType) => {
      console.log({ row });
      switch (key) {
        case "registrationNumber": {
          const isNewVehicle = row["isNewVehicle"];
          const isUpdatedVehicle = row["isUpdatedVehicle"];
          return (
            <StyledRow>
              <div style={{ width: "2rem" }}>
                <img
                  src={Car}
                  style={{ width: "100%", filter: "grayscale(100%)" }}
                  alt="Car"
                />
              </div>
              <Spacer x={0.5} />
              {isNewVehicle || isUpdatedVehicle ? (
                <>
                  {row[key]}
                  <Spacer x={0.5} />
                  <Badge
                    text={t(
                      `vehicles.status.badge.${isNewVehicle ? "new" : "updated"}`,
                    )}
                    backgroundColor={
                      colors["colors/system/informative/informative_ultraLight"]
                    }
                    textColor={
                      colors["colors/system/informative/informative_normal"]
                    }
                  />
                </>
              ) : (
                row[key] ?? "-"
              )}
            </StyledRow>
          );
        }
        case "brand": {
          return (
            <StyledRow>
              <StyledBrand>{row[key]}</StyledBrand>
              {row[key] && <Spacer x={0.25} />}
              <StyledModel>{row["model"]}</StyledModel>
            </StyledRow>
          );
        }
        case "reference":
          return (
            row[key] && (
              <Tag
                textColor={colors["colors/text/black"]}
                backgroundColor={
                  colors["colors/surfaces/background/background_level0"]
                }
                text={row[key]}
                borderColor={colors["colors/borders/cells/cells"]}
              />
            )
          );
        case "status":
          return <TableTagVehicle status={row[key]} />;
      }
      unreachable(key);
    };

  const headers = {
    registrationNumber: {
      text: t("vehicles.page.column.registrationNumber"),
    },
    brand: {
      text: t("vehicles.page.column.model"),
    },
    reference: {
      text: t("vehicles.page.column.reference"),
    },
    status: {
      text: t("vehicles.page.column.status"),
    },
  };

  return (
    <>
      <AddVehicleModal
        visible={isModalVisible}
        onCloseModal={() => {
          setIsModalVisible(false);
        }}
      />

      {isVehiclesModalVisible ? (
        <ImportVehiclesModal
          onCloseModal={() => {
            setIsVehiclesModalVisible(false);
          }}
        />
      ) : (
        <PageDS
          title={t("vehicles.title")}
          cta={cta}
          cta2={cta2}
          isEmptyTable={listDisplayed.length === 0}
          toaster={<ToastContainer />}
          searchBar={searchBarProps}
          topElement={
            messageTooltip ? (
              <MessageTooltip
                title={titleTooltip}
                text={messageTooltip}
                type={"INFORMATIVE"}
              />
            ) : (
              ""
            )
          }
        >
          <TableDS<keyof VehiclesListDisplayedType, VehiclesListDisplayedType>
            data={listDisplayed}
            headers={headers}
            render={render}
            onClickRow={sidePanelAction}
            emptyContent={
              <EmptyTableComponent
                cta={cta}
                cta2={cta2}
                pageName={"vehicles"}
                isSearchResult={searchBarProps.values.length > 0}
              />
            }
          />
          <VehicleSidePanel />
        </PageDS>
      )}
    </>
  );
};

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBrand = styled.div`
  ${typographies["Body/M"]}
`;

const StyledModel = styled.div`
  ${typographies["Body/XS"]}
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
