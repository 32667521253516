import styled from "styled-components";
import { colors, Spacer, Button, Assets, TextCapitalized } from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const LogoutPage = (props: { expiredToken?: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledLogoutPage>
      <StyledLeft>
        <Spacer y={2.25} />
        <StyledHeader>
          <Spacer x={2.5} />
          <StyledFlex>
            <Assets.QiviaLogo />
          </StyledFlex>
        </StyledHeader>
        <StyledTextContainer>
          <StyledText>
            <StyledTitle>{t("logoutPage.title")}</StyledTitle>
            {props.expiredToken && (
              <>
                <Spacer y={1.5} />
                <StyledSize>
                  <TextCapitalized>
                    {t("logoutPage.body.expiredToken")}
                  </TextCapitalized>
                </StyledSize>
              </>
            )}
            <Spacer y={3} />
            <Button
              title={t("logoutPage.button")}
              onClick={() => navigate("/login")}
              width={14.5}
            />
          </StyledText>
        </StyledTextContainer>
      </StyledLeft>
      <StyledRight>
        <Assets.GreenLock />
      </StyledRight>
    </StyledLogoutPage>
  );
};

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledRight = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
`;

const StyledLeft = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const StyledText = styled.div`
  min-width: 27rem;
  max-width: 27rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  height: 100%;
`;

const StyledSize = styled.div`
  min-width: 23rem;
  max-width: 23rem;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledTitle = styled.div`
  font-size: 2rem;
  font-weight: 700;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledLogoutPage = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  background-color: ${colors.white};
`;
