import {
  LocalGasStationOutlined,
  LocalParkingOutlined,
  LocalCarWashOutlined,
  StorefrontOutlined,
  AddBusinessOutlined,
  ElectricBoltOutlined,
} from "@qivia/ui";
import {
  AllowedCategoryType,
  AllowedPeriodType,
  categories,
  CategoryType,
  ExpensesLimitType,
  MerchantGroupsType,
  RuleDefaultData,
} from "./rulesAPI";

export type CategoryInfoType = {
  category: CategoryType;
  isToolTip: boolean;
  logo: JSX.Element;
};
export const getCategoryName = (category: CategoryType | null) =>
  categoryInfoList.find((data) => data.category.toString() === category)
    ?.category ?? "UNAUTHORIZED";

export const allowedPeriodsDatas = [
  {
    dayOfWeek: "MONDAY",
    startTime: "00:00:00",
    endTime: "00:00:00",
  },
  {
    dayOfWeek: "TUESDAY",
    startTime: "00:00:00",
    endTime: "00:00:00",
  },
  {
    dayOfWeek: "WEDNESDAY",
    startTime: "00:00:00",
    endTime: "00:00:00",
  },
  {
    dayOfWeek: "THURSDAY",
    startTime: "00:00:00",
    endTime: "00:00:00",
  },
  {
    dayOfWeek: "FRIDAY",
    startTime: "00:00:00",
    endTime: "00:00:00",
  },
  {
    dayOfWeek: "SATURDAY",
    startTime: "00:00:00",
    endTime: "00:00:00",
  },
  {
    dayOfWeek: "SUNDAY",
    startTime: "00:00:00",
    endTime: "00:00:00",
  },
];

export const merchantGroupsData = [
  "SHELL",
  "BP",
  "ENI",
  "TOTAL_ENERGIES",
  "AVIA",
  "ESSO",
  "CASINO",
  "CARREFOUR",
  "INTERMARCHE",
  "AUCHAN",
  "SYSTEME_U",
  "LECLERC",
  "OTHER",
];

export const categoryInfoList: CategoryInfoType[] = [
  {
    category: "FUEL_AUTOMATON" as const,
    isToolTip: true,
    logo: <LocalGasStationOutlined />,
  },
  {
    category: "SERVICE_STATION" as const,
    isToolTip: true,
    logo: <AddBusinessOutlined />,
  },
  {
    category: "PARKING" as const,
    logo: <LocalParkingOutlined />,
    isToolTip: false,
  },
  { category: "TOLL" as const, logo: <StorefrontOutlined />, isToolTip: false },
  {
    category: "WASH" as const,
    logo: <LocalCarWashOutlined />,
    isToolTip: false,
  },
  {
    category: "ELECTRIC" as const,
    logo: <ElectricBoltOutlined />,
    isToolTip: false,
  },
];

export const defaultValuesRules = (): RuleDefaultData => {
  const defaultExpensesLimit: ExpensesLimitType = {
    amountLimit: "",
    typeLimit: "DAILY",
  };
  const defaultGeographicControl = ["FR" as const]
  const defaultNationalHolidays = false;

  const defaultAllowedPeriods = [] as AllowedPeriodType[];

  const defaultAllowedCategories: AllowedCategoryType[] = categories.map(
    (c) => ({ category: c, amountLimit: "", typeLimit: "DAILY" }),
  );

  const defaultRuleName = "";

  const defaultMerchantGroups: MerchantGroupsType = {
    areOtherMerchantsAuthorized: true,
    unauthorizedMerchantGroups: [],
  };
  const defaultNotifications = {
    areSupportingDocumentsMandatory: true,
  };

  return {
    defaultRuleName,
    defaultGeographicControl,
    defaultExpensesLimit,
    defaultNationalHolidays,
    defaultAllowedPeriods,
    categoryInfoList,
    defaultAllowedCategories,
    defaultMerchantGroups,
    defaultNotifications,
  };
};
