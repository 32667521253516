import styled from "styled-components";
import {
  ButtonDS,
  InputDS,
  LaunchPage,
  OnboardingPage,
  Spacer,
  TextCapitalized,
  Title,
  ToastContainer,
} from "@qivia/ui";
import { useTranslation } from "react-i18next";
import { Assets } from "@qivia/ui";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { loginPasswordForgottenAsync } from "./loginSlice";

type EmailInput = {
  email: string;
};

export interface CtaProps {
  type: "email" | "emailSent" | "newPassword";
  disabledNext?: boolean;
  onClickNext: () => void;
  onClickPrevious: () => void;
}

const ButtonsNextAndPrevious = (props: CtaProps) => {
  const { t } = useTranslation();
  return (
    <>
      <ButtonDS
        text={t(`passwordForgotten.${props.type}.cta.continue`) || ""}
        onClick={() => {
          if (props.disabledNext) props.onClickNext();
        }}
        disabled={!props.disabledNext}
        format={"fill"}
        buttonType={"primary"}
      />
      <ButtonDS
        format={"fill"}
        buttonType={"secondary"}
        text={t(`passwordForgotten.${props.type}.cta.back`) || ""}
        leftIcon={<Assets.LeftArrowSolid />}
        onClick={props.onClickPrevious}
      />
    </>
  );
};

export const PasswordForgotten = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [route, setRoute] = useState<string>("passwordForgotten");

  const [emailInput, setEmailInput] = useState<EmailInput>({
    email: "",
  });

  const resetPassword = useCallback(
    async (route: string) => {
      await dispatch(loginPasswordForgottenAsync({ email: emailInput.email }));
      setRoute(route);
    },
    [dispatch, emailInput.email],
  );
  const previousPage = useCallback((route: string) => {
    setRoute(route);
  }, []);

  useEffect(() => {
    if (route === "login") {
      navigate("/login");
    }
  }, [route, navigate]);

  return (
    <>
      {route === "passwordForgotten" ? (
        <OnboardingPage
          title={
            <Title
              text={t("passwordForgotten.email.title") || ""}
              size={"large"}
            ></Title>
          }
          form={
            <div>
              <InputDS
                label={t("passwordForgotten.email.input.title")}
                name="email"
                value={emailInput.email}
                placeholder={t("passwordForgotten.email.input.placeholder")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setEmailInput({ email: e.target.value });
                }}
              />
              <ButtonsNextAndPrevious
                type="email"
                onClickNext={() =>
                  void resetPassword("passwordForgottenResend")
                }
                onClickPrevious={() => previousPage("login")}
                disabledNext={emailInput.email !== ""}
              />
            </div>
          }
          toaster={<ToastContainer />}
        />
      ) : route === "passwordForgottenResend" ? (
        <OnboardingPage
          title={
            <Title
              text={t("passwordForgotten.emailSent.title") || ""}
              size={"large"}
            ></Title>
          }
          form={
            <div>
              <StyledEmailSentSubtitle>
                <TextCapitalized>
                  {t("passwordForgotten.emailSent.subtitle")}
                </TextCapitalized>
              </StyledEmailSentSubtitle>
              <Spacer y={1} />
              <TextCapitalized>
                {t("passwordForgotten.emailSent.content")}
              </TextCapitalized>
              <Spacer y={2} />
              <ButtonsNextAndPrevious
                type="emailSent"
                onClickNext={() =>
                  void resetPassword("passwordForgottenResend")
                }
                onClickPrevious={() => previousPage("passwordForgotten")}
                disabledNext={true}
              />
            </div>
          }
          toaster={<ToastContainer />}
        />
      ) : (
        <LaunchPage />
      )}
    </>
  );
};

const StyledEmailSentSubtitle = styled.div`
  font-weight: 600;
`;

export const StyledBackgroundButton = styled.div`
  display: flex;
  border: solid 0.25rem transparent;
`;
