import {
  allowableCountries,
  AllowableCountryType,
  GeographicControlType,
} from "../rulesAPI";
import { MutableRefObject, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RuleTemplate } from "./ruleTemplate";
import { StyledBodyLeft } from "./styledBodyLeft";
import { Spacer, Switch, TextCapitalized } from "@qivia/ui";
import { StyledBodyRight } from "./styledBodyRight";
import { CategorieRuleTemplate } from "./categorieRuleTemplate";
import styled from "styled-components";

export const GeographicControl = ({
  value,
  onChange,
  titleRef,
}: {
  value: GeographicControlType;
  onChange: (value: GeographicControlType) => void;
  titleRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  const { t } = useTranslation();

  const onClick = useCallback(
    (countryCode: AllowableCountryType) =>
      value.includes(countryCode)
        ? value.filter((cc) => cc != countryCode)
        : [...value, countryCode],

    [value],
  );

  return (
    <CategorieRuleTemplate
      title={t("rules.modal.geographicControl.title")}
      titleRef={titleRef}
    >
      <RuleTemplate>
        <StyledColumn>
          {allowableCountries.map((countryCode, index) => (
            <StyledColumn key={`geographical-control-${index}`}>
              <StyledFlex>
                <StyledBodyLeft>
                  <TextCapitalized>
                    {t(`rules.modal.${countryCode}`)}
                  </TextCapitalized>
                </StyledBodyLeft>
                <Spacer x={1} />
                <StyledBodyRight>
                  <Switch
                    checked={value.includes(countryCode)}
                    onClick={() => onChange(onClick(countryCode))}
                    size={"S"}
                    color={"GREEN"}
                    disabled={countryCode === "FR"}
                  />
                </StyledBodyRight>
              </StyledFlex>
              {index !== allowableCountries.length - 1 && <Spacer y={0.75} />}
            </StyledColumn>
          ))}
        </StyledColumn>
      </RuleTemplate>
    </CategorieRuleTemplate>
  );
};

export const StyledFlex = styled.div`
  display: flex;
  width: 100%;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
