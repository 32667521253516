import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authAxios, QueryStatus } from "../../../utils";
import { RootState } from "../../../redux/store";
import {
  BankStatementsList,
  ExportDocument,
  ExportStatementPayload,
  ExportZipFolderPayload,
  PublicInvoices,
  PublicInvoicesApi,
  PublicStatements,
  UpdateCompanyType,
} from "./billingsAPI";
import axios from "axios";
import { formattedZipFolderFileName } from "@qivia/ui";

export interface BillingsState {
  statementsListStatus: QueryStatus;
  statementsList: PublicStatements[];
  statementExportStatus: QueryStatus;
  statementExportLink: ExportDocument;
  statementDownloadStatus: QueryStatus;
  updateCompanyStatus: QueryStatus;
  deleteRecipientEmailStatus: QueryStatus;
  invoicesListStatus: QueryStatus;
  invoicesList: PublicInvoices[];
  invoiceExportStatus: QueryStatus;
  invoiceDownloadStatus: QueryStatus;
  invoiceExportLink: ExportDocument;
  bankDetailsExportStatus: QueryStatus;
  bankDetailsExportLink: ExportDocument;
  bankDetailsDownloadStatus: QueryStatus;
  bankStatementExportStatus: QueryStatus;
  bankStatementExportLink: ExportDocument;
  bankStatementsListStatus: QueryStatus;
  bankStatementsList: BankStatementsList[];
}

const initialState: BillingsState = {
  statementsListStatus: "idle",
  statementsList: [],
  statementExportStatus: "idle",
  statementExportLink: {
    presignedUrl: null,
    fileName: null,
  },
  statementDownloadStatus: "idle",
  updateCompanyStatus: "idle",
  deleteRecipientEmailStatus: "idle",
  invoicesListStatus: "idle",
  invoicesList: [],
  invoiceExportStatus: "idle",
  invoiceDownloadStatus: "idle",
  invoiceExportLink: {
    presignedUrl: null,
    fileName: null,
  },
  bankDetailsExportStatus: "idle",
  bankDetailsExportLink: {
    presignedUrl: null,
    fileName: null,
  },
  bankDetailsDownloadStatus: "idle",
  bankStatementExportStatus: "idle",
  bankStatementExportLink: {
    presignedUrl: null,
    fileName: null,
  },
  bankStatementsListStatus: "idle",
  bankStatementsList: [],
};

export const statementsListAsync = createAsyncThunk(
  "statementsList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<Array<PublicStatements>>(
      `/statements/company/${companyUuid}`,
    );
    return response.data.map((d) => {
      return {
        ...d,
        invoiceBucketFileName: d.bucketFileName
          ? `${d.bucketFileName.split(".xlsx")[0] + ".pdf"}`
          : "",
      };
    });
  },
);

export const invoicesListAsync = createAsyncThunk(
  "invoicesList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<PublicInvoicesApi[]>(
      `/invoices/company/${companyUuid}`,
    );
    return response.data
      .map((invoice) => {
        return {
          ...invoice,
          date: invoice.createdAt,
          fileName: invoice.name,
        };
      })
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
  },
);

export const statementExportAsync = createAsyncThunk(
  "statementExport/call",
  async (payload: ExportStatementPayload) => {
    const axios = authAxios();
    const response = await axios.get<{ presignedUrl: string }>(
      `/statement/${payload.bucketFileName}/export`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: payload.fileName,
    };
  },
);

export const bankStatementsListAsync = createAsyncThunk(
  "bankStatementsList/call",
  async (companyUuid: string) => {
    const axios = authAxios();
    const response = await axios.get<BankStatementsList[]>(
      `bank_statements/company/${companyUuid}`,
    );
    return response.data.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
    );
  },
);

export const supportingDocumentsZipFolderExportAsync = createAsyncThunk(
  "supportingDocumentsZipFolderExport/call",
  async (payload: ExportZipFolderPayload) => {
    const axios = authAxios();
    const response = await axios.post<{ presignedUrl: string }>(
      `/statement/zip_folder/${payload.zipFolderUuid}/link`,
    );
    const fileName = formattedZipFolderFileName(
      payload.companyName,
      new Date(payload.date),
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName,
    };
  },
);

export const invoiceExportAsync = createAsyncThunk(
  "invoiceExport/call",
  async (payload: { bucketFileName: string; fileName: string }) => {
    const axios = authAxios();
    const response = await axios.get<{ presignedUrl: string }>(
      `/invoice/${payload.bucketFileName}/export`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: payload.fileName,
    };
  },
);

export const bankDetailsExportAsync = createAsyncThunk(
  "bankDetailsExport/call",
  async (payload: { bucketFileName: string; fileName: string }) => {
    const axios = authAxios();
    const response = await axios.get<{ presignedUrl: string }>(
      `/company/bank_details/${payload.bucketFileName}/export`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: payload.fileName,
    };
  },
);

export const bankStatementExportAsync = createAsyncThunk(
  "bankStatementExport/call",
  async (payload: { bucketFileName: string; fileName: string }) => {
    const axios = authAxios();
    const response = await axios.get<{ presignedUrl: string }>(
      `/bank_statement/${payload.bucketFileName}/export`,
    );
    return {
      presignedUrl: response.data.presignedUrl,
      fileName: payload.fileName,
    };
  },
);

export const documentDownloadAsync = createAsyncThunk(
  "invoiceDownload/call",
  async (docExportLink: ExportDocument) => {
    if (docExportLink.presignedUrl && docExportLink.fileName) {
      const response = await axios({
        url: docExportLink.presignedUrl,
        method: "GET",
        responseType: "blob",
      });

      const a = document.createElement("a");
      a.download = docExportLink.fileName || "yourDocument.pdf";
      const url = window.URL.createObjectURL(new Blob([response.data]));
      a.href = url;
      a.click();
    }
  },
);

export const updateRecipientEmailAsync = createAsyncThunk(
  "companies/update_recipients_emails/call",
  async (payload: UpdateCompanyType) => {
    const axios = authAxios();
    await axios.post(`/companies/update_recipients_emails`, payload);
  },
);

export const deleteRecipientEmailAsync = createAsyncThunk(
  "companies/update_recipients_emails/delete",
  async (payload: UpdateCompanyType) => {
    const axios = authAxios();
    await axios.post(`/companies/update_recipients_emails`, payload);
  },
);

export const billingsSlice = createSlice({
  name: "billings",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetStatementsListStatus(state) {
      state.statementsListStatus = "idle";
    },
    resetStatementsExportStatus(state) {
      state.statementExportStatus = "idle";
    },
    resetBankDetailsExportStatus(state) {
      state.bankDetailsExportStatus = "idle";
    },
    resetUpdateCompanyStatus(state) {
      state.updateCompanyStatus = "idle";
    },
    resetDeleteRecipientEmailStatus(state) {
      state.deleteRecipientEmailStatus = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(statementsListAsync.pending, (state) => {
        state.statementsListStatus = "processing";
      })
      .addCase(statementsListAsync.fulfilled, (state, action) => {
        state.statementsList = action.payload;
        state.statementsListStatus = "success";
      })
      .addCase(statementsListAsync.rejected, (state) => {
        state.statementsListStatus = "failed";
      })
      .addCase(statementExportAsync.pending, (state) => {
        state.statementExportStatus = "processing";
      })
      .addCase(statementExportAsync.fulfilled, (state, action) => {
        state.statementExportLink = action.payload;
        state.statementExportStatus = "success";
      })
      .addCase(statementExportAsync.rejected, (state) => {
        state.statementExportStatus = "failed";
      })
      .addCase(supportingDocumentsZipFolderExportAsync.pending, (state) => {
        state.statementExportStatus = "processing";
      })
      .addCase(
        supportingDocumentsZipFolderExportAsync.fulfilled,
        (state, action) => {
          state.statementExportLink = action.payload;
          state.statementExportStatus = "success";
        },
      )
      .addCase(supportingDocumentsZipFolderExportAsync.rejected, (state) => {
        state.statementExportStatus = "failed";
      })
      .addCase(documentDownloadAsync.pending, (state) => {
        state.statementDownloadStatus = "processing";
      })
      .addCase(documentDownloadAsync.fulfilled, (state) => {
        state.statementExportLink.presignedUrl = null;
        state.statementExportLink.fileName = null;
        state.invoiceExportLink.presignedUrl = null;
        state.invoiceExportLink.fileName = null;
        state.bankDetailsExportLink.presignedUrl = null;
        state.bankDetailsExportLink.fileName = null;
        state.bankStatementExportLink.presignedUrl = null;
        state.bankStatementExportLink.fileName = null;
        state.statementDownloadStatus = "success";
        state.bankStatementExportStatus = "idle";
        state.invoiceExportStatus = "idle";
      })
      .addCase(documentDownloadAsync.rejected, (state) => {
        state.statementDownloadStatus = "failed";
      })
      .addCase(updateRecipientEmailAsync.pending, (state) => {
        state.updateCompanyStatus = "processing";
      })
      .addCase(updateRecipientEmailAsync.fulfilled, (state) => {
        state.updateCompanyStatus = "success";
      })
      .addCase(updateRecipientEmailAsync.rejected, (state) => {
        state.updateCompanyStatus = "failed";
      })
      .addCase(deleteRecipientEmailAsync.pending, (state) => {
        state.deleteRecipientEmailStatus = "processing";
      })
      .addCase(deleteRecipientEmailAsync.fulfilled, (state) => {
        state.deleteRecipientEmailStatus = "success";
      })
      .addCase(deleteRecipientEmailAsync.rejected, (state) => {
        state.deleteRecipientEmailStatus = "failed";
      })
      .addCase(invoicesListAsync.pending, (state) => {
        state.invoicesListStatus = "processing";
      })
      .addCase(invoicesListAsync.fulfilled, (state, action) => {
        state.invoicesList = action.payload;
        state.invoicesListStatus = "success";
      })
      .addCase(invoicesListAsync.rejected, (state) => {
        state.invoicesListStatus = "failed";
      })
      .addCase(invoiceExportAsync.pending, (state) => {
        state.invoiceExportStatus = "processing";
      })
      .addCase(invoiceExportAsync.fulfilled, (state, action) => {
        state.invoiceExportLink = action.payload;
        state.invoiceExportStatus = "success";
      })
      .addCase(invoiceExportAsync.rejected, (state) => {
        state.invoiceExportStatus = "failed";
      })
      .addCase(bankDetailsExportAsync.pending, (state) => {
        state.bankDetailsExportStatus = "processing";
      })
      .addCase(bankDetailsExportAsync.fulfilled, (state, action) => {
        state.bankDetailsExportLink = action.payload;
        state.bankDetailsExportStatus = "success";
      })
      .addCase(bankDetailsExportAsync.rejected, (state) => {
        state.bankDetailsExportStatus = "failed";
      })
      .addCase(bankStatementsListAsync.pending, (state) => {
        state.bankStatementsListStatus = "processing";
      })
      .addCase(bankStatementsListAsync.fulfilled, (state, action) => {
        state.bankStatementsList = action.payload;
        state.bankStatementsListStatus = "success";
      })
      .addCase(bankStatementsListAsync.rejected, (state) => {
        state.bankStatementsListStatus = "failed";
      })
      .addCase(bankStatementExportAsync.pending, (state) => {
        state.bankStatementExportStatus = "processing";
      })
      .addCase(bankStatementExportAsync.fulfilled, (state, action) => {
        state.bankStatementExportLink = action.payload;
        state.bankStatementExportStatus = "success";
      })
      .addCase(bankStatementExportAsync.rejected, (state) => {
        state.bankStatementExportStatus = "failed";
      });
  },
});

export const selectStatementsList = (state: RootState) =>
  state.billings.statementsList;
export const selectStatementsListStatus = (state: RootState) =>
  state.billings.statementsListStatus;
export const selectStatementExportStatus = (state: RootState) =>
  state.billings.statementExportStatus;
export const selectStatementExportLink = (state: RootState) =>
  state.billings.statementExportLink;
export const selectStatementDownloadStatus = (state: RootState) =>
  state.billings.statementDownloadStatus;
export const selectUpdateCompanyStatus = (state: RootState) =>
  state.billings.updateCompanyStatus;
export const selectDeleteRecipientEmailStatus = (state: RootState) =>
  state.billings.deleteRecipientEmailStatus;
export const selectInvoicesList = (state: RootState) =>
  state.billings.invoicesList;
export const selectInvoicesListStatus = (state: RootState) =>
  state.billings.invoicesListStatus;
export const selectInvoiceExportLink = (state: RootState) =>
  state.billings.invoiceExportLink;
export const selectInvoiceExportStatus = (state: RootState) =>
  state.billings.invoiceExportStatus;
export const selectBankDetailsExportStatus = (state: RootState) =>
  state.billings.bankDetailsExportStatus;
export const selectBankDetailsExportLink = (state: RootState) =>
  state.billings.bankDetailsExportLink;
export const selectBankStatementExportStatus = (state: RootState) =>
  state.billings.bankStatementExportStatus;
export const selectBankStatementExportLink = (state: RootState) =>
  state.billings.bankStatementExportLink;
export const selectBankStatementsList = (state: RootState) =>
  state.billings.bankStatementsList;
export const selectBankStatementsListStatus = (state: RootState) =>
  state.billings.bankStatementsListStatus;

export default billingsSlice.reducer;
