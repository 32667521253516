import styled from "styled-components";
import { colors } from "@qivia/ui/src/styles/figmaColors";
import { typographies } from "@qivia/ui/src/styles/figmaTypographies";

export const StyledBodyLeft = styled.div`
  color: ${colors["colors/text/black"]};
  ${typographies["Body/L"]}
  display: flex;
  width: 100%;
  align-items: center;
  & svg {
    width: 1rem;
    height: 1rem;
  }
`;
