import {
  LaunchPage,
} from "@qivia/ui";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  selectStripeOnboardingUrl,
  stripeOnboardingUrlAsync,
} from "../stripe/stripeSlice";
import { stripe } from "@qivia/core";
import { useEffect } from "react";
import { loadCompanyAsync, selectCompany } from "../home/homeSlice";
import { useNavigate } from "react-router-dom";

export const Verification = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const stripeOnboardingUrl = useAppSelector(selectStripeOnboardingUrl);
  const navigate = useNavigate();

  useEffect(() => {
    const getUpdatedCompany = () => {
      if (company) {
        void dispatch(loadCompanyAsync({ uuid: company.uuid }));
      }
    };
    const intervalPolling = setInterval(getUpdatedCompany, 10000); // long polling every 10 seconds
    return () => {
      clearInterval(intervalPolling);
      if (company && company.providerOnboardingStatus === "FINISHED") {
        navigate("/home");
      }
    };
  }, [company, dispatch, navigate, company?.providerOnboardingStatus]);

  useEffect(() => {
    if (stripeOnboardingUrl) {
      window.location.href = stripeOnboardingUrl;
    }
  }, [stripeOnboardingUrl]);


  useEffect(() => {
    const accountLinkPayload = {
      accountId: company?.providerId,
      refreshUrl: `${import.meta.env["VITE_BASE_URL"] as string}/home`,
      returnUrl: `${import.meta.env["VITE_BASE_URL"] as string}/home`,
      type: "account_onboarding",
    } as stripe.AccountLinkType;

    void dispatch(stripeOnboardingUrlAsync(accountLinkPayload));
  })

  return (
      <LaunchPage />
  );
};
